import React from 'react'
import AiDevelopment from '../Fullpages/AiDevelopment/AiDevelopment'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'
import WorkPortfolio from '../components/WorkPortfolio/WorkPortfolio'

export default function AI() {
  return (
    <>
    <Navbar/>
    <AiDevelopment/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
