import "./Navbar.css";
import reownlogics from '../../images/logo.png';
import reownlogo from '../../images/RLLOGOBLUE.jpg';
import digital from '../../images/digital-services.png';
import java from '../../images/java.png';
import ads from '../../images/advertising.png';
import adwords from '../../images/adwords.png';
import snapchat from '../../images/snapchat.png';
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';

function Navbar() {

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showNestedLinks, setShowNestedLinks] = useState(false);
  const [showNestedLinks2, setShowNestedLinks2] = useState(false);
  const [showNestedLinks3, setShowNestedLinks3] = useState(false);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  const handleMouseEnter2 = () => {
    setShowNestedLinks(true);
  };

  const handleMouseLeave2 = () => {
    setShowNestedLinks(false);
  };
  const handleMouseEnter3 = () => {
    setShowNestedLinks2(true);
  };

  const handleMouseLeave3 = () => {
    setShowNestedLinks2(false);
  };
  const handleMouseEnter4 = () => {
    setShowNestedLinks3(true);
  };  

  const handleMouseLeave4 = () => {
    setShowNestedLinks3(false);
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);

  useEffect(() => {
    console.log('isMenuOpen:', isMenuOpen);
  }, [isMenuOpen]);


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen1(!isDropdownOpen1);
  };
  const toggleDropdown1 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
  };

  return (
    <header>
      <nav class="navbar navbar-expand-lg navbar-light bg- fixed-menu-bar">
        <div class="Header-module--headerContainer--ab194">
          <Link to={`/`} aria-current="page" class="w-auto navbar-brand">
            <div data-gatsby-image-wrapper="" class="gatsby-image-wrapper" style={{ width: '170px', height: '90px' }}>
              {/* <img aria-hidden="true" data-placeholder-image="" style={{opacity: '0', transition: 'opacity 500ms linear 0s', objectFit: 'cover'}} decoding="async" src={reownlogicsImage} alt=""/> */}

              <picture />
              {/* <source type="image/webp" srcset="/static/ef4c1f0be0caee5a4596e5c2311d5e27/fc97f/InvoZone_Logo.webp 170w,
/static/ef4c1f0be0caee5a4596e5c2311d5e27/1d03c/InvoZone_Logo.webp 340w" sizes="170px"/> */}
              <img layout="fixed" decoding="async" width="170px" height="90px" data-main-image="" style={{ objectFit: 'cover', opacity: '1' }} sizes="170px" loading="lazy" src={reownlogo} alt="ReownLogics" />
              <picture />
            </div>
          </Link>
          <div className={`mobileHeader-module--hamburger--3358a ${isMenuOpen ? 'mobileHeader-module--active--a10ff' : ''}`}
            onClick={toggleMenu}>
            <div>
              <span>
              </span>
              <span>
              </span>
              <span>
              </span>
            </div>
          </div>
          <div className={`mobileHeader-module--nav_menu--d9117 ${isMenuOpen ? 'activeNav' : ''}`}>
            <div class="mobileHeader-module--menuContainer--2565f">
              <div class="dropdown">
                <button aria-haspopup="true" aria-expanded={isDropdownOpen1} id="dropdown-basic-Services" type="button" className={`mobileHeader-module--nav_link--2a0e7 ${isDropdownOpen1 ? 'activeDropdown' : ''}`} onClick={toggleDropdown}>Services
                  <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1kb3duIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEuNjQ2IDQuNjQ2YS41LjUgMCAwIDEgLjcwOCAwTDggMTAuMjkzbDUuNjQ2LTUuNjQ3YS41LjUgMCAwIDEgLjcwOC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDggMGwtNi02YS41LjUgMCAwIDEgMC0uNzA4eiIvPgo8L3N2Zz4=" className="img1" decoding="async" loading="lazy" alt="" />
                </button>
                {isDropdownOpen1 && (
                  <div class="container">
                    <div aria-labelledby="dropdown-basic-Services" class={`mobileHeader-module--menuDropdown--378b1 ${isDropdownOpen1 ? 'show' : ''}`} style={{ margin: '0px' }}>
                      <div class="row">
                        <div class="col-md-6">
                          <Link to={`/web-application-development-services/`} class="mobileHeader-module--sub_nav_link--eb1e4">
                            <img src="https://invozone-backend.s3.amazonaws.com/web_app_development_services_c457005326.svg" alt="Web App Development Services" width="30" height="30" decoding="async" loading="lazy" />
                            <span class="pl-3">Website Development</span>
                          </Link>
                          <hr class="mobileHeader-module--bar--1acc2" />
                        </div>
                        <div class="col-md-6">
                          <Link to={`/custom-software-development/`} class="mobileHeader-module--sub_nav_link--eb1e4">
                            <img src="https://invozone-backend.s3.amazonaws.com/custom_software_development_bf2d35b92b.svg" alt="Custom Software Development" width="30" height="30" decoding="async" loading="lazy" />
                            <span class="pl-3">Custom Software Development</span>
                          </Link>
                          <hr class="mobileHeader-module--bar--1acc2" />
                        </div>
                        <div class="col-md-6">
                          <Link to={`/mobile-app-development/`} class="mobileHeader-module--sub_nav_link--eb1e4">
                            <img src="https://invozone-backend.s3.amazonaws.com/mobile_app_development_services_28fa3c8b6b.svg" alt="Mobile App Development Services" width="30" height="30" decoding="async" loading="lazy" />
                            <span class="pl-3">Mobile App Development Services</span>
                          </Link>
                          <hr class="mobileHeader-module--bar--1acc2" />
                        </div>
                        <div class="col-md-6">
                          <Link to={`/software-quality-assurance/`} class="mobileHeader-module--sub_nav_link--eb1e4">
                            <img src="https://invozone-backend.s3.amazonaws.com/software_quality_assurance_6383cbfc49.svg" alt="Software Quality Assurance" width="30" height="30" decoding="async" loading="lazy" />
                            <span class="pl-3">Software Quality Assurance</span>
                          </Link>
                          <hr class="mobileHeader-module--bar--1acc2" />
                        </div>
                        <div class="col-md-6">
                          <Link to={`/software-product-development-services/`} class="mobileHeader-module--sub_nav_link--eb1e4">
                            <img src="https://invozone-backend.s3.amazonaws.com/product_development_services_8cc0324623.svg" alt="Product Development Services" width="30" height="30" decoding="async" loading="lazy" />
                            <span class="pl-3">Product Development Services</span>
                          </Link>
                          <hr class="mobileHeader-module--bar--1acc2" />
                        </div>
                        <div class="col-md-6">
                          <Link to={`/enterprise-application-development/`} class="mobileHeader-module--sub_nav_link--eb1e4">
                            <img src="https://invozone-backend.s3.amazonaws.com/enterprise_app_development_0c75a9abe7.svg" alt="Enterprise App Development" width="30" height="30" decoding="async" loading="lazy" />
                            <span class="pl-3">Enterprise App Development</span>
                          </Link>
                          <hr class="mobileHeader-module--bar--1acc2" />
                        </div>
                        <Link to={`/software-development-services/`} class="mobileHeader-module--serBtn--3c638" style={{ fontSize: '13px' }}>
                          <p class="mobileHeader-module--servicesBtn--93dac">View More Services
                            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzE0NjFfMTUwMTgpIj4KPHBhdGggZD0iTTE0LjE2NTQgNS44MzM1TDUuODMyMDMgMTQuMTY2OCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTYuNjY3OTcgNS44MzM1SDE0LjE2OFYxMy4zMzM1IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuMjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzE0NjFfMTUwMTgiPgo8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==" alt="arrow" />
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                <hr class="mobileHeader-module--bar2--7ff7b" />
              </div>
              <div class="dropdown">
                <button aria-haspopup="true" aria-expanded={isDropdownOpen2} id="dropdown-basic-Industries" type="button" className={`mobileHeader-module--nav_link--2a0e7 ${isDropdownOpen2 ? 'activeDropdown' : ''}`} onClick={toggleDropdown1}>Industries
                  <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1kb3duIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEuNjQ2IDQuNjQ2YS41LjUgMCAwIDEgLjcwOCAwTDggMTAuMjkzbDUuNjQ2LTUuNjQ3YS41LjUgMCAwIDEgLjcwOC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDggMGwtNi02YS41LjUgMCAwIDEgMC0uNzA4eiIvPgo8L3N2Zz4=" className="img1" decoding="async" loading="lazy" alt="" />
                </button>
                {isDropdownOpen2 && (
                  <div class="container">
                    <div aria-labelledby="dropdown-basic-Industries" class={`mobileHeader-module--menuDropdown--378b1 ${isDropdownOpen2 ? 'show' : ''}`} style={{ margin: '0px' }}>
                      <div class="row">
                        <div class="col-md-6">
                          <Link to={`/e-commerce/`} class="mobileHeader-module--sub_nav_link--eb1e4">
                            <img src="https://invozone-backend.s3.amazonaws.com/e_commerce_214f41533f.svg" alt="E-Commerce" width="30" height="30" decoding="async" loading="lazy" />
                            <span class="pl-3">E-Commerce</span>
                          </Link>
                          <hr class="mobileHeader-module--bar--1acc2" />
                        </div>
                        <div class="col-md-6">
                          <Link to={`/on-demand-services/`} class="mobileHeader-module--sub_nav_link--eb1e4">
                            <img src="https://invozone-backend.s3.amazonaws.com/on_demand_services_8e0ba443d0.svg" alt="On Demand Services" width="30" height="30" decoding="async" loading="lazy" />
                            <span class="pl-3">On Demand Services</span>
                          </Link>
                          <hr class="mobileHeader-module--bar--1acc2" />
                        </div>
                        <div class="col-md-6">
                          <Link to={`/fintech/`} class="mobileHeader-module--sub_nav_link--eb1e4">
                            <img src="https://invozone-backend.s3.amazonaws.com/fintech_f5ff28ca93.svg" alt="Fintech" width="30" height="30" decoding="async" loading="lazy" />
                            <span class="pl-3">Fintech</span>
                          </Link>
                          <hr class="mobileHeader-module--bar--1acc2" />
                        </div>
                        <div class="col-md-6">
                          <Link to={`/food-groceries/`} class="mobileHeader-module--sub_nav_link--eb1e4">
                            <img src="https://invozone-backend.s3.amazonaws.com/food_grocery_20172345c7.svg" alt="Food &amp; Grocery" width="30" height="30" decoding="async" loading="lazy" />
                            <span class="pl-3">Food &amp; Grocery</span>
                          </Link>
                          <hr class="mobileHeader-module--bar--1acc2" />
                        </div>
                        <div class="col-md-6">
                          <Link to={`/healthcare/`} class="mobileHeader-module--sub_nav_link--eb1e4">
                            <img src="https://invozone-backend.s3.amazonaws.com/healthcare_b16cb4addd.svg" alt="Healthcare" width="30" height="30" decoding="async" loading="lazy" />
                            <span class="pl-3">Healthcare</span>
                          </Link>
                          <hr class="mobileHeader-module--bar--1acc2" />
                        </div>
                        <div class="col-md-6">
                          <Link to={`/travel-tourism/`} class="mobileHeader-module--sub_nav_link--eb1e4">
                            <img src="https://invozone-backend.s3.amazonaws.com/travel_tourism_0c1087a31e.svg" alt="Travel &amp; Tourism" width="30" height="30" decoding="async" loading="lazy" />
                            <span class="pl-3">Travel &amp; Tourism</span>
                          </Link>
                          <hr class="mobileHeader-module--bar--1acc2" />
                        </div>
                        <div class="col-md-6">
                          <Link to={`/education/`} class="mobileHeader-module--sub_nav_link--eb1e4">
                            <img src="https://invozone-backend.s3.amazonaws.com/education_c249cfbf53.svg" alt="Education" width="30" height="30" decoding="async" loading="lazy" />
                            <span class="pl-3">Education</span>
                          </Link>
                          <hr class="mobileHeader-module--bar--1acc2" />
                        </div>
                        <Link to={`/industries/`} class="mobileHeader-module--serBtn--3c638" style={{ fontSize: '13px' }}>
                          <p class="mobileHeader-module--servicesBtn--93dac">View More Industries
                            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzE0NjFfMTUwMTgpIj4KPHBhdGggZD0iTTE0LjE2NTQgNS44MzM1TDUuODMyMDMgMTQuMTY2OCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTYuNjY3OTcgNS44MzM1SDE0LjE2OFYxMy4zMzM1IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuMjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzE0NjFfMTUwMTgiPgo8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==" alt="arrow" />
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                <hr class="mobileHeader-module--bar2--7ff7b" />
              </div>
              {/* <div class="dropdown">
                        <button aria-haspopup="true" aria-expanded="false" id="dropdown-basic-Hire Developers" type="button" class="mobileHeader-module--nav_link--2a0e7">Hire Developers
                            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1kb3duIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEuNjQ2IDQuNjQ2YS41LjUgMCAwIDEgLjcwOCAwTDggMTAuMjkzbDUuNjQ2LTUuNjQ3YS41LjUgMCAwIDEgLjcwOC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDggMGwtNi02YS41LjUgMCAwIDEgMC0uNzA4eiIvPgo8L3N2Zz4=" decoding="async" loading="lazy" alt="" />
                        </button>
                        <div class="container">
                        </div>
                        <hr class="mobileHeader-module--bar2--7ff7b" />
                    </div> */}
              <div class="dropdown">
                <Link to={`/careers`} aria-haspopup="true" aria-expanded="false" id="dropdown-basic-Content Hub" type="button" class="mobileHeader-module--nav_link--2a0e7">Careers
                  {/* <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1kb3duIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEuNjQ2IDQuNjQ2YS41LjUgMCAwIDEgLjcwOCAwTDggMTAuMjkzbDUuNjQ2LTUuNjQ3YS41LjUgMCAwIDEgLjcwOC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDggMGwtNi02YS41LjUgMCAwIDEgMC0uNzA4eiIvPgo8L3N2Zz4=" decoding="async" loading="lazy" alt="" /> */}
                </Link>
                <div class="container">
                </div>
                <hr class="mobileHeader-module--bar2--7ff7b" />
              </div>
              <div class="dropdown">
                <Link to={`/about-us`} aria-haspopup="true" aria-expanded="false" id="" type="button" class="mobileHeader-module--nav_link--2a0e7">About Us
                  {/* <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1kb3duIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEuNjQ2IDQuNjQ2YS41LjUgMCAwIDEgLjcwOCAwTDggMTAuMjkzbDUuNjQ2LTUuNjQ3YS41LjUgMCAwIDEgLjcwOC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDggMGwtNi02YS41LjUgMCAwIDEgMC0uNzA4eiIvPgo8L3N2Zz4=" decoding="async" loading="lazy" alt="" /> */}
                </Link>
                <div class="container">
                </div>
                <hr class="mobileHeader-module--bar2--7ff7b" />
              </div>
              {/* <a href="/pricing/">
                        <div class="mobileHeader-module--nav_link--2a0e7">Pricing</div>
                    </a> */}
              <div class="mobileHeader-module--btnContainer--b9bd0">
                <button>
                  <Link to={`/contact-us/`}>Contact us</Link>
                </button>
              </div>
            </div>
          </div>
          <div class="navbar-collapse collapse" id="main-navbar-nav"><div class="ml-auto navbar-nav">
            <div class="megaMenuColumnbox dropdown"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Link to={`/software-development-services`} aria-haspopup="true" aria-expanded="false" id="dropdown-autoclose-outside-0" data-rb-event-key="/software-development-services/" class="text-white dropdown-toggl nav-link">Services
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2FyZXQtZG93bi1maWxsIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik03LjI0NyAxMS4xNCAyLjQ1MSA1LjY1OEMxLjg4NSA1LjAxMyAyLjM0NSA0IDMuMjA0IDRoOS41OTJhMSAxIDAgMCAxIC43NTMgMS42NTlsLTQuNzk2IDUuNDhhMSAxIDAgMCAxLTEuNTA2IDB6Ii8+Cjwvc3ZnPg==" decoding="async" loading="lazy" alt="caret down" class="Header-module--alignHeaderDropIcon--dc9c2" />
              </Link>
              <div aria-labelledby="dropdown-autoclose-outside-0" class="dropdown-menu dropdown-menu-right" style={{ margin: '0px' }}>
                <div class="Header-module--dropdownMulticolMenu--1a58e Header-module--column3--78d0f row" style={{ maxHeight: '500px', overflowY: 'auto', position: 'fixed', marginRight: 'auto', marginTop: 'auto', margin: 'auto' }}>
                  <div className="Header-module--megaMenuColumn--3729c col-lg-4">
                    <div
                      className="dropdown"
                      onMouseEnter={handleMouseEnter2}
                      onMouseLeave={handleMouseLeave2}
                    >
                      <Link to={`/web-application-development-services/`} className="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                        <img
                          src="https://invozone-backend.s3.amazonaws.com/web_app_development_services_c457005326.svg"
                          alt="Web App Development Services"
                          width="32"
                          decoding="async"
                          loading="lazy"
                          style={{ float: 'left' }}
                        />
                        <div className="d-flex ml-2">Website Development</div>
                      </Link>
                      {showNestedLinks && (
                        <div className="nested-links">
                          <Link to={`/serviceswebsite`} className="dropdown-item"><img src={digital} alt="Healthcare" width="20" height="20" decoding="async" loading="lazy" />  Services Website Development</Link>
                          <Link to={`/brandwebsite`} className="dropdown-item"><img src={java} alt="Healthcare" width="20" height="20" decoding="async" loading="lazy" /> Brand Website Development</Link>
                        </div>
                      )}
                    </div>
                    <div className="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div className="Header-module--megaMenuColumn--3729c col-lg-4">
                    <div
                      className="dropdown"
                      onMouseEnter={handleMouseEnter3}
                      onMouseLeave={handleMouseLeave3}
                    >
                      <Link to={`/web-application-development-services/`} className="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                        <img
                          src="https://invozone-backend.s3.amazonaws.com/web_app_development_services_c457005326.svg"
                          alt="Web App Development Services"
                          width="32"
                          decoding="async"
                          loading="lazy"
                          style={{ float: 'left' }}
                        />
                        <div className="d-flex ml-2">Paid Marketing</div>
                      </Link>
                      {showNestedLinks2 && (
                        <div className="nested-links">
                          <Link to={`/metaads`} className="dropdown-item"><img src={ads} alt="Healthcare" width="20" height="20" decoding="async" loading="lazy" /> Meta Ads Services</Link>
                          <Link to={`/googleadwords`} className="dropdown-item"><img src={adwords} alt="Healthcare" width="20" height="20" decoding="async" loading="lazy" /> Google Adwords Services</Link>
                          <Link to={`/snapchatads`} className="dropdown-item"><img src={snapchat} alt="Healthcare" width="20" height="20" decoding="async" loading="lazy" /> Snapchat Ads Services</Link>
                          <Link to={`/tiktokads`} className="dropdown-item">Tiktok Ads Services</Link>
                          <Link to={`/emailmarketing`} className="dropdown-item">Email Marketing Services</Link>
                        </div>
                      )}
                    </div>
                    <div className="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div className="Header-module--megaMenuColumn--3729c col-lg-4">
                    <div
                      className="dropdown"
                      onMouseEnter={handleMouseEnter4}
                      onMouseLeave={handleMouseLeave4}
                    >
                      <Link to={`/web-application-development-services/`} className="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                        <img
                          src="https://invozone-backend.s3.amazonaws.com/web_app_development_services_c457005326.svg"
                          alt="Web App Development Services"
                          width="32"
                          decoding="async"
                          loading="lazy"
                          style={{ float: 'left' }}
                        />
                        <div className="d-flex ml-2">Visual Creation</div>
                      </Link>
                      {showNestedLinks3 && (
                        <div className="nested-links">
                          <Link to={`/ProductPhotography`} className="dropdown-item">Product Photography Services</Link>
                          <Link to={`/Videography`} className="dropdown-item">Videography Services</Link>
                          <Link to={`/LifestylePhotography`} className="dropdown-item">Lifestyle Photography Services</Link>
                        </div>
                      )}
                    </div>
                    <div className="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/custom-software-development/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/custom_software_development_bf2d35b92b.svg" alt="Custom Software Development" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Custom Software Development</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator">
                    </div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/mobile-app-development/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/mobile_app_development_services_28fa3c8b6b.svg" alt="Mobile App Development Services" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Mobile App Development Services</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator">
                    </div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/software-quality-assurance/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/software_quality_assurance_6383cbfc49.svg" alt="Software Quality Assurance" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Software Quality Assurance</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator">
                    </div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/software-product-development-services/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/product_development_services_8cc0324623.svg" alt="Product Development Services" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Product Development Services</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator">
                    </div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/enterprise-application-development/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/enterprise_app_development_0c75a9abe7.svg" alt="Enterprise App Development" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Enterprise App Development</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator">
                    </div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/SocialMedia/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/enterprise_app_development_0c75a9abe7.svg" alt="Enterprise App Development" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Social Media Management</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator">
                    </div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/SEO/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/enterprise_app_development_0c75a9abe7.svg" alt="Enterprise App Development" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">SEO Services</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator">
                    </div>
                  </div>
                  <div class="justify-content-center d-flex pt-2 pt-md-1 ml-auto col-lg-4">
                    <Link to={`/software-development-services/`} target="_blank" rel="noreferrer">
                      <Link to={`/software-development-services/`} class="btn_black_border font-weight-normal" style={{ fontSize: '13px' }}>View More Services<img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzE0NjFfMTUwMTgpIj4KPHBhdGggZD0iTTE0LjE2NTQgNS44MzM1TDUuODMyMDMgMTQuMTY2OCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTYuNjY3OTcgNS44MzM1SDE0LjE2OFYxMy4zMzM1IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuMjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzE0NjFfMTUwMTgiPgo8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==" />
                      </Link>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="megaMenuColumnbox dropdown"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >

              <Link to={`/industries`} aria-haspopup="true" aria-expanded="false" id="dropdown-autoclose-outside-1" data-rb-event-key="/industries/" class="text-white dropdown-toggl nav-link">Industries
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2FyZXQtZG93bi1maWxsIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik03LjI0NyAxMS4xNCAyLjQ1MSA1LjY1OEMxLjg4NSA1LjAxMyAyLjM0NSA0IDMuMjA0IDRoOS41OTJhMSAxIDAgMCAxIC43NTMgMS42NTlsLTQuNzk2IDUuNDhhMSAxIDAgMCAxLTEuNTA2IDB6Ii8+Cjwvc3ZnPg==" decoding="async" loading="lazy" alt="caret down" class="Header-module--alignHeaderDropIcon--dc9c2" />
              </Link>
              <div aria-labelledby="dropdown-autoclose-outside-1" class="dropdown-menu dropdown-menu-right" style={{ margin: '0px' }}>
                <div class="Header-module--dropdownMulticolMenu--1a58e Header-module--column3--78d0f row" style={{ maxHeight: '500px', overflowY: 'auto', position: 'fixed', marginRight: 'auto', marginTop: 'auto', margin: 'auto' }}>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/e-commerce/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/e_commerce_214f41533f.svg" alt="E-Commerce" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">E-Commerce</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/on-demand-services/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/on_demand_services_8e0ba443d0.svg" alt="On Demand Services" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">On Demand Services</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/fintech/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/fintech_f5ff28ca93.svg" alt="Fintech" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Fintech</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/food-groceries/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/food_grocery_20172345c7.svg" alt="Food & Grocery" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Food & Grocery</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/healthcare/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/healthcare_b16cb4addd.svg" alt="Healthcare" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Healthcare</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/travel-tourism/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/travel_tourism_0c1087a31e.svg" alt="Travel & Tourism" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Travel & Tourism</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/education/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/education_c249cfbf53.svg" alt="Education" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Education</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="justify-content-center d-flex pt-2 pt-md-1 ml-auto col-lg-4" style={{ margin: 'auto' }}>
                    <Link to={`/industries/`} target="_blank" rel="noreferrer">
                      <Link to={`/industries/`} class="btn_black_border font-weight-normal" style={{ fontSize: '13px' }}>
                        View More Industries
                        <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzE0NjFfMTUwMTgpIj4KPHBhdGggZD0iTTE0LjE2NTQgNS44MzM1TDUuODMyMDMgMTQuMTY2OCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTYuNjY3OTcgNS44MzM1SDE0LjE2OFYxMy4zMzM1IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuMjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzE0NjFfMTUwMTgiPgo8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==" />
                      </Link>
                    </Link>
                  </div>
                </div>
              </div>

            </div>
            {/* <div class="megaMenuColumnbox dropdown">
              <Link to={`/dedicated-development-team`} aria-haspopup="true" aria-expanded="false" id="dropdown-autoclose-outside-2" data-rb-event-key="/dedicated-development-team/" class="text-white dropdown-toggl nav-link">Hire Developers
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2FyZXQtZG93bi1maWxsIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik03LjI0NyAxMS4xNCAyLjQ1MSA1LjY1OEMxLjg4NSA1LjAxMyAyLjM0NSA0IDMuMjA0IDRoOS41OTJhMSAxIDAgMCAxIC43NTMgMS42NTlsLTQuNzk2IDUuNDhhMSAxIDAgMCAxLTEuNTA2IDB6Ii8+Cjwvc3ZnPg==" decoding="async" loading="lazy" alt="caret down" class="Header-module--alignHeaderDropIcon--dc9c2" />
              </Link>
              <div aria-labelledby="dropdown-autoclose-outside-2" class="dropdown-menu dropdown-menu-right" style={{ margin: '0px' }}>
                <div class="Header-module--dropdownMulticolMenu--1a58e Header-module--column3--78d0f row" style={{ maxHeight: '500px', overflowY: 'auto', position: 'fixed', marginRight: 'auto', marginTop: 'auto', margin: 'auto' }}>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/hire-elixir-developers/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/hire_elixir_developer_58b54dce6f.svg" alt="Hire Elixir Developer" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Hire Elixir Developer</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/javascript-development-company/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/hire_javascript_developer_9b827260b7.svg" alt="Hire JavaScript Developer" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Hire JavaScript Developer</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/hire-node-js-developer/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/hire_node_js_developer_49b51803e9.svg" alt="Hire Node.js Developer" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Hire Node.js Developer</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/hire-ruby-on-rails-developer/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/hire_ror_developer_c776d8907c.svg" alt="Hire RoR Developer" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Hire RoR Developer</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/hire-flutter-developers/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/hire_flutter_developer_eb86b19eec.svg" alt="Hire Flutter Developer" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Hire Flutter Developer</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/hire-python-developers/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/hire_python_developer_6977bba61b.svg" alt="Hire Python Developer" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Hire Python Developer</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/hire-penetration-testing-consultants/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/hire_penetration_testing_consultants_a268029104.svg" alt="Hire Penetration Testing Consultants" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Hire Penetration Testing Consultants</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/hire-data-engineers/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/hire_data_engineers_a20c23d635.svg" alt="Hire Data Engineers" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Hire Data Engineers</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-4">
                    <Link to={`/hire-unreal-engine-developers/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/hire_unreal_engine_developer_d672ea5147.svg" alt="Hire Unreal Engine Developer" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Hire Unreal Engine Developer</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="justify-content-center d-flex pt-2 pt-md-1 ml-auto col-lg-4">
                    <Link to={`/dedicated-development-team/`} target="_blank" rel="noreferrer">
                      <button class="btn_black_border font-weight-normal" style={{ fontSize: '13px' }}>
                        View All
                        <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzE0NjFfMTUwMTgpIj4KPHBhdGggZD0iTTE0LjE2NTQgNS44MzM1TDUuODMyMDMgMTQuMTY2OCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTYuNjY3OTcgNS44MzM1SDE0LjE2OFYxMy4zMzM1IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuMjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzE0NjFfMTUwMTgiPgo8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==" />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

            </div> */}
            {/* <div class="megaMenuColumnbox dropdown">
                        <Link aria-haspopup="true" aria-expanded="false" href="#" data-rb-event-key="#" class="text-white dropdown-toggl nav-link" role="button">Content Hub<img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2FyZXQtZG93bi1maWxsIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik03LjI0NyAxMS4xNCAyLjQ1MSA1LjY1OEMxLjg4NSA1LjAxMyAyLjM0NSA0IDMuMjA0IDRoOS41OTJhMSAxIDAgMCAxIC43NTMgMS42NTlsLTQuNzk2IDUuNDhhMSAxIDAgMCAxLTEuNTA2IDB6Ii8+Cjwvc3ZnPg==" decoding="async" loading="lazy" alt="caret down" class="Header-module--alignHeaderDropIcon--dc9c2" />
                        </Link>
                      </div> */}
            {/* <div class="megaMenuColumnbox dropdown">
              <a aria-haspopup="true" aria-expanded="false" data-rb-event-key="#" class="text-white dropdown-toggl nav-link" role="button">Company
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2FyZXQtZG93bi1maWxsIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik03LjI0NyAxMS4xNCAyLjQ1MSA1LjY1OEMxLjg4NSA1LjAxMyAyLjM0NSA0IDMuMjA0IDRoOS41OTJhMSAxIDAgMCAxIC43NTMgMS42NTlsLTQuNzk2IDUuNDhhMSAxIDAgMCAxLTEuNTA2IDB6Ii8+Cjwvc3ZnPg==" decoding="async" loading="lazy" alt="caret down" class="Header-module--alignHeaderDropIcon--dc9c2" />
              </a>
              <div aria-labelledby="dropdown-autoclose-outside-4" class="dropdown-menu dropdown-menu-right" style={{ marginLeft: '-150px' }}>
                <div class="Header-module--dropdownMulticolMenu--1a58e Header-module--column2--6d5de row" style={{ maxHeight: '500px', overflowY: 'auto', position: 'unset', marginRight: '-105px', marginTop: '8px' }}>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-6">
                    <Link to={`/about-us/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/about_us_5f8a7bed34.svg" alt="About Us" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">About Us</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-6">
                    <Link to={`/careers/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/career_aefb7daa9b.svg" alt="Careers" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Careers</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-6">
                    <Link to={`/portfolio/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/portfolio_c88d44e3b2.svg" alt="Portfolio" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Portfolio</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                  <div class="Header-module--megaMenuColumn--3729c col-lg-6">
                    <Link to={`/events/`} class="container justify-content-start Header-module--megaMenuLink--86e9d dropdown-item">
                      <img src="https://invozone-backend.s3.amazonaws.com/press_hq_0f19136de9.svg" alt="Press HQ" width="32" decoding="async" loading="lazy" style={{ float: 'left' }} />
                      <div class="d-flex ml-2">Press HQ</div>
                    </Link>
                    <div class="Header-module--divider--01de5 dropdown-divider" role="separator"></div>
                  </div>
                </div>
              </div>

            </div> */}

            <div>
              <Link to={`/careers`} id="" class="nav-link">Careers</Link>
            </div>
            <div>
              <Link to={`/about-us`} id="" class="nav-link">About Us</Link>
            </div>
            <div>
              <Link to={`/Pricing`} id="header_pricing_btn_gt" class="nav-link">Pricing</Link>
            </div>
            <div className="contactus">
              <Link to={`/contact-us`} id="header_contact_us_btn_gt" class="btn btn-light px-3 ml-3 mt-2 mt-md-0 Header-module--contactLink--2141f nav-link">Contact Us</Link>
            </div>
          </div>
          </div>
        </div>
      </nav>
    </header >
  );
}

export default Navbar;
