import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import Pricing from './pages/Pricing';
import Services from './pages/Services';
import Industries from './pages/Industries';
import HireDevelopers from './pages/HireDevelopers';
import ContactUs from './pages/ContactUs';
import AI from './pages/AI';
import Ecommerce from './pages/Ecommerce';
import Fintech from './pages/Fintech';
import OnDemand from './pages/OnDemand';
import WebAppDevelopment from './pages/WebAppDevelopment';
import Customweb from './pages/Customweb';
import Mobile from './pages/Mobile';
import SoftwareQuality from './pages/SoftwareQuality'
import ProductDevelop from './pages/Productdevelop';
import EnterPriseSoftware from './pages/EnterPriseSoftware';
import Careers from './pages/Careers';
import AboutUs from './pages/AboutUs';
import GroceryFood from './pages/GroceryFood';
import HealthCare from './pages/HealthCare';
import TravelTourism from './pages/TravelTourism';
import Educational from './pages/Educational';
import ServicesWebsite from './pages/ServicesWebsite';
import BrandWebsite from './pages/BrandWebsite';
import MetaAds from './pages/MetaAds';
import GoogleAdwords from './pages/GoogleAdwords';
import SnapchatAds from './pages/SnapchatAds';
import TiktokAds from './pages/TiktokAds';
import EmailMarketing from './pages/EmailMarketing';
import ProductPhotography from './pages/ProductPhotography';
import Videography from './pages/Videography';
import LifestylePhotography from './pages/LifestylePhotography';
import SocialMedia from './pages/SocialMedia';
import SEO from './pages/SEO';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}/>
        {/* <Route path="/signin" element={<SignInPage/>}/> */}
        <Route path="/Pricing/" element={<Pricing/>}/>
        <Route path="/software-development-services/" element={<Services/>}/>
        <Route path="/industries/" element={<Industries/>}/>
        <Route path="/dedicated-development-team/" element={<HireDevelopers/>}/>
        <Route path="/contact-us/" element={<ContactUs/>}/>
        <Route path="/ai-development-services/" element={<AI/>}/>
        <Route path="/e-commerce/" element={<Ecommerce/>}/>
        <Route path="/fintech/" element={<Fintech/>}/>
        <Route path="/on-demand-services/" element={<OnDemand/>}/>
        <Route path="/web-application-development-services/" element={<WebAppDevelopment/>}/>
        <Route path="/custom-software-development/" element={<Customweb/>}/>
        <Route path="/mobile-app-development/" element={<Mobile/>}/>
        <Route path="/software-quality-assurance/" element={<SoftwareQuality/>}/>
        <Route path="/software-product-development-services/" element={<ProductDevelop/>}/>
        <Route path="/enterprise-application-development/" element={<EnterPriseSoftware/>}/>
        <Route path="/careers/" element={<Careers/>}/>
        <Route path="/about-us/" element={<AboutUs/>}/>
        <Route path="/food-groceries/" element={<GroceryFood/>}/>
        <Route path="/healthcare/" element={<HealthCare/>}/>
        <Route path="/travel-tourism/" element={<TravelTourism/>}/>
        <Route path="/education/" element={<Educational/>}/>
        <Route path="/serviceswebsite/" element={<ServicesWebsite/>}/>
        <Route path="/brandwebsite/" element={<BrandWebsite/>}/>
        <Route path="/metaads/" element={<MetaAds/>}/>
        <Route path="/googleadwords/" element={<GoogleAdwords/>}/>
        <Route path="/snapchatads/" element={<SnapchatAds/>}/>
        <Route path="/tiktokads/" element={<TiktokAds/>}/>
        <Route path="/emailmarketing/" element={<EmailMarketing/>}/>
        <Route path="/ProductPhotography/" element={<ProductPhotography/>}/>
        <Route path="/Videography/" element={<Videography/>}/>
        <Route path="/LifestylePhotography/" element={<LifestylePhotography/>}/>
        <Route path="/SocialMedia/" element={<SocialMedia/>}/>
        <Route path="/SEO/" element={<SEO/>}/>
      </Routes>
    </Router>
  );
}

export default App;
