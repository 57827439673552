import React from 'react'
import "./OurPricing.css";

export default function OurPricing() {
    return (
        <section style={{backgroundColor: 'rgb(249, 249, 249)'}}>
            <div class="pt-5 container">
                <div class="row">
                    <div class="col-12">
                        <h1 class="main-heading-36 text-center  pb-5">Website</h1></div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="pricingCard-module--cardContainer--63616">
                            <div class="pricingCard-module--headerContainer--b5200 undefined">
                                <div>
                                    
                                    <h5 class="pt-2">BASIC</h5>
                                    <p>Our basic package is best for small business that want to make a bold statement</p>
                                </div>
                            </div>
                            <div class="pricingCard-module--bodyContainer--59c66">
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Vetted developer profiles in 48 hours" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>6 pages / Responsive Design</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Staffing within 1-2 weeks" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Content Upload / 4 plugin Extension</p>
                                    </div>
                                </div>
                                <div class="row"><div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Availability of developers in every tech stack" decoding="async" loading="lazy" height="18" />
                                </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Hosting Setup / Social Media Icons</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Speed optimization</p>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--questionContainer--ef805">
                                <div class="row">
                                    <div class="col-12">
                                    <h1 class="font-weight-bold pb-2 mb-0 text-center">$550</h1>
                                </div>
                                    <div class=" pt-4 text-center col-12">
                                        <button class="btn_black_border" id="pricing_page_staff_augmentation_btn_gt">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--coloredArea--37a50 undefined">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="pricingCard-module--cardContainer--63616">
                            <div class="pricingCard-module--headerContainer--b5200 undefined">
                                <div>
                                    
                                    <h5 class="pt-2">Pro</h5>
                                    <p>Pro services for the professionals of the craft</p>
                                </div>
                            </div>
                            <div class="pricingCard-module--bodyContainer--59c66">
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Vetted developer profiles in 48 hours" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>12 Pages / Responsive Design</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Staffing within 1-2 weeks" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Content Upload / 8 plugin Extension</p>
                                    </div>
                                </div>
                                <div class="row"><div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Availability of developers in every tech stack" decoding="async" loading="lazy" height="18" />
                                </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Ecommerce Functionality / 20 Products</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Hosting Setup / Social Media Icons</p>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--questionContainer--ef805">
                                <div class="row">
                                    <div class="col-12">
                                    <h1 class="font-weight-bold pb-2 mb-0 text-center">$1250</h1>
                                </div>
                                    <div class=" pt-4 text-center col-12">
                                        <button class="btn_black_border" id="pricing_page_staff_augmentation_btn_gt">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--coloredArea--37a50 undefined">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="pricingCard-module--cardContainer--63616">
                            <div class="pricingCard-module--headerContainer--b5200 undefined">
                                <div>
                                    
                                    <h5 class="pt-2">ENTERPRISE</h5>
                                    <p>Get dedicated resources for your enterprise tasks. Only the best of the best</p>
                                </div>
                            </div>
                            <div class="pricingCard-module--bodyContainer--59c66">
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Vetted developer profiles in 48 hours" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>18 Pages / Responsive Design</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Staffing within 1-2 weeks" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Content Upload / 10 Plugin Extensions</p>
                                    </div>
                                </div>
                                <div class="row"><div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Availability of developers in every tech stack" decoding="async" loading="lazy" height="18" />
                                </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Ecommerce Functionality / 45 Products</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Payment Processing / Speed Optimization</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Hosting Setup / Social Media Icons</p>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--questionContainer--ef805">
                                <div class="row">
                                    <div class="col-12">
                                    <h1 class="font-weight-bold pb-2 mb-0 text-center">$1850</h1>
                                </div>
                                    <div class=" pt-4 text-center col-12">
                                        <button class="btn_black_border" id="pricing_page_staff_augmentation_btn_gt">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--coloredArea--37a50 undefined">
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col-12">
                        <h1 class="main-heading-36 text-center  pb-5">APPLICATIONS</h1></div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="pricingCard-module--cardContainer--63616">
                            <div class="pricingCard-module--headerContainer--b5200 undefined">
                                <div>
                                    
                                    <h5 class="pt-2">BASIC</h5>
                                    <p>Our basic package is best for small business that want to make a bold statement</p>
                                </div>
                            </div>
                            <div class="pricingCard-module--bodyContainer--59c66">
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Vetted developer profiles in 48 hours" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Cross Platform App</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Staffing within 1-2 weeks" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Native App</p>
                                    </div>
                                </div>
                                <div class="row"><div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Availability of developers in every tech stack" decoding="async" loading="lazy" height="18" />
                                </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Android & IOS Platforms</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Professional Consultation / Brainstorming</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Testing</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Upload To AppStore</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Push Notification</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Delivery</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Feasibility Check</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Source Code Delivery</p>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--questionContainer--ef805">
                                <div class="row">
                                    <div class="col-12">
                                    <h1 class="font-weight-bold pb-2 mb-0 text-center">$1500</h1>
                                </div>
                                    <div class=" pt-4 text-center col-12">
                                        <button class="btn_black_border" id="pricing_page_staff_augmentation_btn_gt">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--coloredArea--37a50 undefined">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="pricingCard-module--cardContainer--63616">
                            <div class="pricingCard-module--headerContainer--b5200 undefined">
                                <div>
                                    
                                    <h5 class="pt-2">Pro</h5>
                                    <p>Pro services for the professionals of the craft</p>
                                </div>
                            </div>
                            <div class="pricingCard-module--bodyContainer--59c66">
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Vetted developer profiles in 48 hours" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Cross Platform App</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Staffing within 1-2 weeks" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Product Design (UX & UI)</p>
                                    </div>
                                </div>
                                <div class="row"><div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Availability of developers in every tech stack" decoding="async" loading="lazy" height="18" />
                                </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Native App</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Android & IOS Platforms</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Testing</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>MVP Development</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Professional Consultation / Brainstorming</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>QA Testing</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Testing</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Upload To Appstore</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Push Notification</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Admin Panel</p>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--questionContainer--ef805">
                                <div class="row">
                                    <div class="col-12">
                                    <h1 class="font-weight-bold pb-2 mb-0 text-center">$3000</h1>
                                </div>
                                    <div class=" pt-4 text-center col-12">
                                        <button class="btn_black_border" id="pricing_page_staff_augmentation_btn_gt">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--coloredArea--37a50 undefined">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="pricingCard-module--cardContainer--63616">
                            <div class="pricingCard-module--headerContainer--b5200 undefined">
                                <div>
                                    
                                    <h5 class="pt-2">ENTERPRISE</h5>
                                    <p>Get dedicated resources for your enterprise tasks. Only the best of the best</p>
                                </div>
                            </div>
                            <div class="pricingCard-module--bodyContainer--59c66">
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Vetted developer profiles in 48 hours" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Cross Platform App</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Staffing within 1-2 weeks" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Product Design (UX & UI)</p>
                                    </div>
                                </div>
                                <div class="row"><div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Availability of developers in every tech stack" decoding="async" loading="lazy" height="18" />
                                </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Native App</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Android & IOS Platforms</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Testing</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>MVP Development</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Professional Consultation / Brainstorming</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>QA Testing</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Testing</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Upload To Appstore</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Push Notification</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Admin Panel</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Delivery</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Feasibility Check</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Source Code Delivery</p>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--questionContainer--ef805">
                                <div class="row">
                                    <div class="col-12">
                                    <h1 class="font-weight-bold pb-2 mb-0 text-center">$7000</h1>
                                </div>
                                    <div class=" pt-4 text-center col-12">
                                        <button class="btn_black_border" id="pricing_page_staff_augmentation_btn_gt">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--coloredArea--37a50 undefined">
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col-12">
                        <h1 class="main-heading-36 text-center  pb-5">WEB PAGES</h1></div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="pricingCard-module--cardContainer--63616">
                            <div class="pricingCard-module--headerContainer--b5200 undefined">
                                <div>
                                    
                                    <h5 class="pt-2">BASIC</h5>
                                    <p>Best For Startups or Mid-size Companies</p>
                                </div>
                            </div>
                            <div class="pricingCard-module--bodyContainer--59c66">
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Vetted developer profiles in 48 hours" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Web Application</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Staffing within 1-2 weeks" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Product Definition</p>
                                    </div>
                                </div>
                                <div class="row"><div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Availability of developers in every tech stack" decoding="async" loading="lazy" height="18" />
                                </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Custom Product (UX & UI)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Figma Prototype </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Delivery phase1 / 4 to 6 weeks</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Frontend Development</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Backend Development</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Delivery phase2 / 10 to 14 weeks</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>QA Testing & Polish</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>3rd Party Integrations</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Maintenance & Support </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Delivery Time / 3 Month</p>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--questionContainer--ef805">
                                <div class="row">
                                    <div class="col-12">
                                    <h1 class="font-weight-bold pb-2 mb-0 text-center">$5000</h1>
                                </div>
                                    <div class=" pt-4 text-center col-12">
                                        <button class="btn_black_border" id="pricing_page_staff_augmentation_btn_gt">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--coloredArea--37a50 undefined">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="pricingCard-module--cardContainer--63616">
                            <div class="pricingCard-module--headerContainer--b5200 undefined">
                                <div>
                                    
                                    <h5 class="pt-2">Pro</h5>
                                    <p>Best For Startups or Mid-size Companies</p>
                                </div>
                            </div>
                            <div class="pricingCard-module--bodyContainer--59c66">
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Vetted developer profiles in 48 hours" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Web Application</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Staffing within 1-2 weeks" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Product Definition</p>
                                    </div>
                                </div>
                                <div class="row"><div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Availability of developers in every tech stack" decoding="async" loading="lazy" height="18" />
                                </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Custom Product (UX & UI)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Figma Prototype </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Delivery phase1 / 2 to 3 weeks</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Frontend Development</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Backend Development</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Delivery phase2 / 6 to 10 weeks</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>QA Testing & Polish</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>3rd Party Integrations</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Maintenance & Support </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Delivery Time / 3 Months</p>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--questionContainer--ef805">
                                <div class="row">
                                    <div class="col-12">
                                    <h1 class="font-weight-bold pb-2 mb-0 text-center">$7000</h1>
                                </div>
                                    <div class=" pt-4 text-center col-12">
                                        <button class="btn_black_border" id="pricing_page_staff_augmentation_btn_gt">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--coloredArea--37a50 undefined">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="pricingCard-module--cardContainer--63616">
                            <div class="pricingCard-module--headerContainer--b5200 undefined">
                                <div>
                                    
                                    <h5 class="pt-2">ENTERPRISE</h5>
                                    <p>Advanced Functionality with Complex Integrations</p>
                                </div>
                            </div>
                            <div class="pricingCard-module--bodyContainer--59c66">
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Vetted developer profiles in 48 hours" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Web Application</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Staffing within 1-2 weeks" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Product Definition</p>
                                    </div>
                                </div>
                                <div class="row"><div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Availability of developers in every tech stack" decoding="async" loading="lazy" height="18" />
                                </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Custom Product (UX & UI)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Figma Prototype </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Delivery phase1 / 6 to 8 weeks</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Frontend Development</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Backend Development</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Delivery phase2 / 14 to 20 weeks</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>QA Testing & Polish</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>3rd Party Integrations</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Maintenance & Support </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Delivery Time / 4 Months</p>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--questionContainer--ef805">
                                <div class="row">
                                    <div class="col-12">
                                    <h1 class="font-weight-bold pb-2 mb-0 text-center">$15000</h1>
                                </div>
                                    <div class=" pt-4 text-center col-12">
                                        <button class="btn_black_border" id="pricing_page_staff_augmentation_btn_gt">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--coloredArea--37a50 undefined">
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col-12">
                        <h1 class="main-heading-36 text-center  pb-5">DIGITAL MARKETING</h1></div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="pricingCard-module--cardContainer--63616">
                            <div class="pricingCard-module--headerContainer--b5200 undefined">
                                <div>
                                    
                                    <h5 class="pt-2">BASIC</h5>
                                    <p>Best For Startups or Mid-size Companies</p>
                                </div>
                            </div>
                            <div class="pricingCard-module--bodyContainer--59c66">
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Vetted developer profiles in 48 hours" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Social Media Audit</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Staffing within 1-2 weeks" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Facebook Page Setup & Management</p>
                                    </div>
                                </div>
                                <div class="row"><div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Availability of developers in every tech stack" decoding="async" loading="lazy" height="18" />
                                </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Instagram Profile Setup & Management </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Snapchat Page Setup & Management</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Google Account Setup & Management</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Monitoring</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Monthly 30 social media Posts (20 static posts and 10 GIF)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Social media analytics & reporting</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Google Analytics Integration on social channels </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Integration of all social media channels with Website</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Monthly 10 campaigns on Facebook & Instagram</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Monthly Reporting</p>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--questionContainer--ef805">
                                <div class="row">
                                    <div class="col-12">
                                    <h1 class="font-weight-bold pb-2 mb-0 text-center">$2500</h1>
                                </div>
                                    <div class=" pt-4 text-center col-12">
                                        <button class="btn_black_border" id="pricing_page_staff_augmentation_btn_gt">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--coloredArea--37a50 undefined">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="pricingCard-module--cardContainer--63616">
                            <div class="pricingCard-module--headerContainer--b5200 undefined">
                                <div>
                                    
                                    <h5 class="pt-2">PRO</h5>
                                    <p>Best For Startups or Mid-size Companies</p>
                                </div>
                            </div>
                            <div class="pricingCard-module--bodyContainer--59c66">
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Vetted developer profiles in 48 hours" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Social Media Audit</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Staffing within 1-2 weeks" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Facebook Page Setup & Management</p>
                                    </div>
                                </div>
                                <div class="row"><div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Availability of developers in every tech stack" decoding="async" loading="lazy" height="18" />
                                </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Instagram Profile Setup & Management </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Snapchat Page Setup & Management</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>TikTok account Setup & Management</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Google Account Setup & Management </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Content Writing </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Monitoring</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Monthly 60 social media Posts (40 static posts and 20 GIF)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Social media analytics & reporting</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Google Analytics Integration on social channels</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Integration of all social media channels with Website</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Monthly ads creation and management on all platforms</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Monthly Reporting</p>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--questionContainer--ef805">
                                <div class="row">
                                    <div class="col-12">
                                    <h1 class="font-weight-bold pb-2 mb-0 text-center">$6000</h1>
                                </div>
                                    <div class=" pt-4 text-center col-12">
                                        <button class="btn_black_border" id="pricing_page_staff_augmentation_btn_gt">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--coloredArea--37a50 undefined">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="pricingCard-module--cardContainer--63616">
                            <div class="pricingCard-module--headerContainer--b5200 undefined">
                                <div>
                                    
                                    <h5 class="pt-2">ENTERPRISE</h5>
                                    <p>Advanced Functionality with Complex Integrations</p>
                                </div>
                            </div>
                            <div class="pricingCard-module--bodyContainer--59c66">
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Vetted developer profiles in 48 hours" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Social Media Audit</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Staffing within 1-2 weeks" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Facebook Page Setup & Management</p>
                                    </div>
                                </div>
                                <div class="row"><div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Availability of developers in every tech stack" decoding="async" loading="lazy" height="18" />
                                </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Instagram Profile Setup & Management </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Snapchat Page Setup & Management</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>TikTok account Setup & Management</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>YouTube account Setup & Management</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Google Account Setup & Management</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Content Writing</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Monitoring</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Monthly 90 social media Posts (50 static posts and 40 GIF) including custom posts (designed on client request)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Social media analytics & reporting</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Google Analytics Integration on social channels</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Integration of all social media channels with Website</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Monthly ads creation and management on all platforms </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Monthly Reporting</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Complete E-commerce management</p>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--questionContainer--ef805">
                                <div class="row">
                                    <div class="col-12">
                                    <h1 class="font-weight-bold pb-2 mb-0 text-center">$10000</h1>
                                </div>
                                    <div class=" pt-4 text-center col-12">
                                        <button class="btn_black_border" id="pricing_page_staff_augmentation_btn_gt">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--coloredArea--37a50 undefined">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    {/* <div class="col-12">
                        <h1 class="main-heading-36 text-center  pb-5">DIGITAL MARKETING</h1>
                        </div> */}
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="pricingCard-module--cardContainer--63616">
                            <div class="pricingCard-module--headerContainer--b5200 undefined">
                                <div>
                                    
                                    <h5 class="pt-2">LAUNCHPAD</h5>
                                    <p>Ideal for startups</p>
                                </div>
                            </div>
                            <div class="pricingCard-module--bodyContainer--59c66">
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Vetted developer profiles in 48 hours" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>CMS (WordPress)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Staffing within 1-2 weeks" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Custom Homepage mockup</p>
                                    </div>
                                </div>
                                <div class="row"><div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Availability of developers in every tech stack" decoding="async" loading="lazy" height="18" />
                                </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Custom Inner page layout</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Wireframe design</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Page Speed Score Optimization</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Personalized according to brand colors</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Mobile Responsive</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Stock images upto 12</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>SEO Optimized with YOAST (WorPress CMS)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Lead capture contact forms</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Visual editor elementor / WP bakery basic (WorPress CMS Only)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Landing page builder</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Secured using Wordfence (WorPress CMS Only)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Complementary Hosting - 12 months</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Upto 3 email accounts</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Free SSL Certificate</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Free backup upto 12 months</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Basic On-page & technical SEO</p>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--questionContainer--ef805">
                                <div class="row">
                                    <div class="col-12">
                                    <h1 class="font-weight-bold pb-2 mb-0 text-center">$990</h1>
                                </div>
                                    <div class=" pt-4 text-center col-12">
                                        <button class="btn_black_border" id="pricing_page_staff_augmentation_btn_gt">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--coloredArea--37a50 undefined">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="pricingCard-module--cardContainer--63616">
                            <div class="pricingCard-module--headerContainer--b5200 undefined">
                                <div>
                                    
                                    <h5 class="pt-2">BUSINESS BLAZE</h5>
                                    <p>Ideal for growing small businesses</p>
                                </div>
                            </div>
                            <div class="pricingCard-module--bodyContainer--59c66">
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Vetted developer profiles in 48 hours" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>CMS (Wordpress, Webflow, Squarespace, Wix)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Staffing within 1-2 weeks" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Custom Homepage mockup x1</p>
                                    </div>
                                </div>
                                <div class="row"><div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Availability of developers in every tech stack" decoding="async" loading="lazy" height="18" />
                                </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Custom Inner page layout upto 4</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Custom Wireframe design</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Page Speed Score Optimization</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Personalized according to brand colors</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Mobile Responsive</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Stock images upto 30</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>CMS Training session</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>SEO Optimized with YOAST (WorPress CMS)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Lead capture contact forms</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Visual editor elementor pro / WP bakery (WorPress CMS Only)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Landing page builder</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Secured using Wordfence (WorPress CMS Only)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Complementary Hosting - 12 months</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Upto 3 email accounts</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Free SSL Certificate</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Free backup upto 12 months</p>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--questionContainer--ef805">
                                <div class="row">
                                    <div class="col-12">
                                    <h1 class="font-weight-bold pb-2 mb-0 text-center">$2500</h1>
                                </div>
                                    <div class=" pt-4 text-center col-12">
                                        <button class="btn_black_border" id="pricing_page_staff_augmentation_btn_gt">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--coloredArea--37a50 undefined">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="pricingCard-module--cardContainer--63616">
                            <div class="pricingCard-module--headerContainer--b5200 undefined">
                                <div>
                                    
                                    <h5 class="pt-2">PROFESSIONAL POWER</h5>
                                    <p>Ideal for established businesses</p>
                                </div>
                            </div>
                            <div class="pricingCard-module--bodyContainer--59c66">
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Vetted developer profiles in 48 hours" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>CMS (Wordpress, Webflow, Squarespace, Wix)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Staffing within 1-2 weeks" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Custom Homepage mockup x1</p>
                                    </div>
                                </div>
                                <div class="row"><div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Availability of developers in every tech stack" decoding="async" loading="lazy" height="18" />
                                </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Custom Inner page layout upto 6</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Wireframe design</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Page Speed Score Optimization 80+ WordPress Only</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Personalized according to brand colors</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Mobile Responsive</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Stock images upto 50</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>CMS Training session</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>SEO Optimized with YOAST (WorPress CMS)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Lead capture contact forms</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Visual editor elementor pro / WP bakery (WorPress CMS Only)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Landing page builder</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Secured using Wordfence (WorPress CMS Only)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Complementary Hosting - 12 months</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Upto 3 email accounts</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Free SSL Certificate</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Free backup upto 12 months</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Basic On-page & technical SEO</p>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--questionContainer--ef805">
                                <div class="row">
                                    <div class="col-12">
                                    <h1 class="font-weight-bold pb-2 mb-0 text-center">$4500</h1>
                                </div>
                                    <div class=" pt-4 text-center col-12">
                                        <button class="btn_black_border" id="pricing_page_staff_augmentation_btn_gt">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--coloredArea--37a50 undefined">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div class="pricingCard-module--cardContainer--63616">
                            <div class="pricingCard-module--headerContainer--b5200 undefined">
                                <div>
                                    
                                    <h5 class="pt-2">ULTIMATE IMPACT</h5>
                                    <p>Ideal for enterprise or eCommerce</p>
                                </div>
                            </div>
                            <div class="pricingCard-module--bodyContainer--59c66">
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Vetted developer profiles in 48 hours" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>CMS (WooCommerce, Webflow, Squarespace, Wix, Shopify)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Staffing within 1-2 weeks" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Custom Homepage mockup upto 2</p>
                                    </div>
                                </div>
                                <div class="row"><div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="Availability of developers in every tech stack" decoding="async" loading="lazy" height="18" />
                                </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Custom Inner page layout upto 8</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Wireframe design</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Page Speed Score Optimization 90+ WordPress Only</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Designed according to brand style guide</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Mobile Responsive</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Stock images upto 50</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>CMS Training session</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>SEO Optimized with YOAST (WorPress CMS)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Lead capture contact forms</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Visual editor elementor pro / WP bakery (WorPress CMS Only)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Landing page builder</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Secured using Wordfence (WorPress CMS Only)</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Complementary Hosting - 12 months</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Upto 3 email accounts</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Free SSL Certificate</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Free backup upto 12 months</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-2">
                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOSAxOS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOSAxOS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBGQjJENzt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJTaGFwZV81NzZfY29weSIgY2xhc3M9InN0MCIgZD0iTTAsMS44QzAsMC44LDAuOCwwLDEuOCwwYzAsMCwwLDAsMCwwaDE1LjRjMSwwLDEuOCwwLjgsMS44LDEuOGMwLDAsMCwwLDAsMHYxNS41CgljMCwxLTAuOCwxLjgtMS44LDEuOGMwLDAsMCwwLDAsMEgxLjhjLTEsMC0xLjgtMC44LTEuOC0xLjhjMCwwLDAsMCwwLDBWMS44eiBNNCwxMC42bDIuOSwyLjlsMCwwYzAuNCwwLjUsMS4yLDAuNSwxLjYsMGw2LjQtNmwwLDAKCWMwLjItMC4yLDAuMy0wLjYsMC0wLjhsMCwwbC0wLjMtMC40bDAsMGMtMC4yLTAuMi0wLjYtMC4yLTAuOCwwbC01LjksNS40bDAsMGMtMC4xLDAuMS0wLjMsMC4xLTAuNCwwTDUuMiw5LjRsMCwwCgljLTAuMi0wLjItMC42LTAuMi0wLjgsMGMwLDAsMCwwLDAsMEw0LDkuN2wwLDBDMy44LDEwLDMuOCwxMC4zLDQsMTAuNkw0LDEwLjZ6Ii8+Cjwvc3ZnPgo=" alt="No hiring and retention stress" decoding="async" loading="lazy" height="18" />
                                    </div>
                                    <div class="pricingCard-module--tickText--3e912 col-xl-11 col-md-10 col-sm-11 col-10">
                                        <p>Basic On-page & technical SEO</p>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--questionContainer--ef805">
                                <div class="row">
                                    <div class="col-12">
                                    <h1 class="font-weight-bold pb-2 mb-0 text-center">$6000</h1>
                                </div>
                                    <div class=" pt-4 text-center col-12">
                                        <button class="btn_black_border" id="pricing_page_staff_augmentation_btn_gt">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="pricingCard-module--coloredArea--37a50 undefined">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
