import React from 'react';

// Custom previous arrow component
const PrevArrow = (props) => {
    return (

        <div className="WorkPortfolio-module--iconContainerLeft--4d499">
            <div className="WorkPortfolio-module--portfolioArrowIconCover--12a28">
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktYXJyb3ctbGVmdCIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNSA4YS41LjUgMCAwIDAtLjUtLjVIMi43MDdsMy4xNDctMy4xNDZhLjUuNSAwIDEgMC0uNzA4LS43MDhsLTQgNGEuNS41IDAgMCAwIDAgLjcwOGw0IDRhLjUuNSAwIDAgMCAuNzA4LS43MDhMMi43MDcgOC41SDE0LjVBLjUuNSAwIDAgMCAxNSA4eiIvPgo8L3N2Zz4=" className="WorkPortfolio-module--portfolioArrowIcon--e5a44" decoding="async" loading="lazy" alt="arrow" onClick={props.onClick} />
            </div>
        </div>
    );
};

// Custom next arrow component
const NextArrow = (props) => {
    return (
        <div className="WorkPortfolio-module--iconContainer--37a96">
            <div className="WorkPortfolio-module--portfolioArrowRightIconCover--e3136">
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktYXJyb3ctcmlnaHQiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMSA4YS41LjUgMCAwIDEgLjUtLjVoMTEuNzkzbC0zLjE0Ny0zLjE0NmEuNS41IDAgMCAxIC43MDgtLjcwOGw0IDRhLjUuNSAwIDAgMSAwIC43MDhsLTQgNGEuNS41IDAgMCAxLS43MDgtLjcwOEwxMy4yOTMgOC41SDEuNUEuNS41IDAgMCAxIDEgOHoiLz4KPC9zdmc+" className="WorkPortfolio-module--portfolioArrowIcon--e5a44" decoding="async" loading="lazy" alt="arrow" onClick={props.onClick} />
            </div>
        </div>
    );
};

export { PrevArrow, NextArrow };
