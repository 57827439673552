import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import InfoSection from '../components/InfoSection'
import Models from '../components/Models/Models'
import WorkPortfolio from '../components/WorkPortfolio/WorkPortfolio'
import Hiring from '../components/Hiring/Hiring'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'
import Footer from '../components/Footer/Footer'
import SoftwareDevelopmentConsulting from '../components/SoftwareDevelopmentConsulting'
import IndustryElements from '../components/Industry'

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        let abortController = new AbortController();
        setIsOpen(!isOpen);
        return () => {
            abortController.abort();
        }
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar/>
            <SoftwareDevelopmentConsulting />
            <InfoSection/>
            <IndustryElements/>
            <Models/>
            <WorkPortfolio/>
            <Hiring/>
            <LetUsKnow/>
            <Footer/>
        </>
    )
}

export default Home