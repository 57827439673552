import React from 'react'
import "./SoftwareDevelopmentConsulting.css";
import { Link } from "react-router-dom";
import innovators from '../../images/innovators.webp'

export default function SoftwareDevelopmentConsulting() {
    return (
        <>
            <section class="Banner-module--section--e5bdb">
                <div class="container">
                    <div class="align-items-center row">
                        <div class="col-lg-6">
                            <h1>Software Development Consulting for</h1>
                            <div class="Banner-module--typingH1--e650b">
                                <span class="Typewriter__wrapper">Innovators</span>
                            </div>
                            <div class="paragraph">ReownLogics offers software development consulting services for businesses, startups, and enterprises. Partner with a reliable software development company to scale up your engineering capacity.</div>
                            <div class="mt-5">
                                <Link to={`/contact-us`}>
                                    <button class="btn_white_border" id="hp_hero_section_start_project_btn_gt">Schedule a Call</button>
                                </Link>
                            </div>
                        </div>
                        <div class="mt-5 mt-lg-0 col-lg-6">
                            <div data-gatsby-image-wrapper="" class="gatsby-image-wrapper gatsby-image-wrapper-constrained">
                                <div style={{maxWidth:'610px', display:'block'}}>
                                    <img alt="" role="presentation" aria-hidden="true" src="data:image/svg+xml;charset=utf-8,%3Csvg height='376' width='610' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E" style={{maxWidth:'100%',display:'block',position:'static'}} />
                                </div>
                                <img aria-hidden="true" data-placeholder-image="" style={{opacity:'0',transition:'opacity 500ms linear',objectFit:'cover'}} decoding="async" src="data:image/webp;base64,UklGRtwBAABXRUJQVlA4WAoAAAAQAAAAEwAACwAAQUxQSO8AAAANgKRt27Hl/plt27Zr1PEPwwLaQYvgrGltoaORRjZHtm1+9vuETUREAv4tueU2pYND/Ej/MP4drtqHcLwsdX2f3QVGZvtsaivwFx1B3/sBnrCIBOuiqYarVvuIrOgHt+btcvh42S5iFe8sr4JBs/1SuGV2E7CFBbxzRRBVJBbdPXI6C/pOitc+PlPAmGwPtTjv2A8x7TaM2H1cMMzg5nHZHZp8XMqQQmQXQ0JJ9clWdqQ2PU0PQ4CshbuiYL0bqR6aaDWYm6C9chn5RM5UU7HhZCMnKtFmLUic3knMRq6IaWBITnwqHHgDLnJ9QbCRGwBWUDggxgAAAPAFAJ0BKhQADAA+0VSjS6gkoyGwCAEAGglsAJ0yhHg+grwG2A54rTK95hIjIW3wzVsnZWXR/pwAAP37caYmMAdx4tINHCSdEno15ANqk9lJgYVkJYqqjBrNUqwrNNFd+MNlXBjHDhL/L+pN9c9nwTlhQCt0Q4go1Hw9/newx0iITPwLE0ttBsf7KdjDX+fpB5E7SGvW+wOEc5Uywnkyw7LnVO4yd+ezAMnw53sVWbVzLKdsD7UCMjygOpcrIfx5hij7Qa4AAA==" alt="" />
                                <img decoding="async" width="610" height="376" data-main-image="" style={{objectFit:'cover',opacity:'1'}} sizes="(min-width: 610px) 610px, 100vw" loading="lazy" src={innovators} alt="Innovators" />
                                <noscript>
                                    <img decoding="async" width="610" height="376" data-main-image="" style={{objectFit:'cover',opacity:'1'}} sizes="(min-width: 610px) 610px, 100vw" loading="lazy" src="/static/13aebbec83f3f2959700dea2846446fd/1cc75/innovators.webp" srcSet="/static/13aebbec83f3f2959700dea2846446fd/c0620/innovators.webp 153w,
/static/13aebbec83f3f2959700dea2846446fd/d9511/innovators.webp 305w,
/static/13aebbec83f3f2959700dea2846446fd/1cc75/innovators.webp 610w" alt="Innovators" />
                                </noscript>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
    }