import React from 'react'
import FintechSoftware from '../Fullpages/Fintech/FintechSoftware'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'

export default function Fintech() {
  return (
    <>
    <Navbar/>
    <FintechSoftware/>
    <Footer/>
    </>
  )
}
