import React from 'react'
import Grocery from '../Fullpages/Grocery&Food/Grocery'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'

export default function GroceryFood() {
  return (
    <>
    <Navbar/>
    <Grocery/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
