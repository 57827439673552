import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'
import EducationalSoftware from '../Fullpages/Educational/EducationalSoftware'
import WorkPortfolio from '../components/WorkPortfolio/WorkPortfolio'

export default function Educational() {
  return (
    <>
    <Navbar/>
    <EducationalSoftware/>
    <WorkPortfolio/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
