import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'
import MetaAdsServices from '../Fullpages/MetaAdsServices/MetaAdsServices'

export default function MetaAds() {
  return (
    <>
    <Navbar/>
    <MetaAdsServices/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
