import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'
import SnapchatAdsServices from '../Fullpages/SnapchatAdsServices/SnapchatAdsServices'

export default function SnapchatAds() {
  return (
    <>
    <Navbar/>
    <SnapchatAdsServices/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
