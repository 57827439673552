import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import About from '../Fullpages/AboutUs/About'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'

export default function AboutUs() {
  return (
    <>
    <Navbar/>
    <About/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
