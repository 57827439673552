import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'
import TiktokAdsServices from '../Fullpages/TiktokAdsServices/TiktokAdsServices'

export default function TiktokAds() {
  return (
    <>
    <Navbar/>
    <TiktokAdsServices/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
