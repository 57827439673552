import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import "./EcommerceDevelopment.css";
import bannerImg from '../../images/bannerimg.svg';
import WorkPortfolio from '../../components/WorkPortfolio/WorkPortfolio';
import LetUsKnow from '../../components/LetUsKnow/LetUsKnow';
import Faq from './Faq1';

export default function EcommerceDevelopment() {

    const [activeTab, setActiveTab] = useState('TechStacks');

    const tabContent = {
        TechStacks: (
            <div className={`fade tab-pane ${activeTab === 'TechStacks' ? 'active show' : ''}`} role="tabpanel" aria-hidden={activeTab !== 'TechStacks'}>
                <div class="Tools2-module--techIcon--575c9">
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/5676f455a126492a63b28181898e13f4/javascript_3758b5915f.svg" alt="JavaScript" />
                            </div>
                            <h3>JavaScript</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/2a81072fd4637477140393a594f5f282/html_8797336511.svg" alt="HTML" />
                            </div>
                            <h3>HTML</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/79ea30135c1ebcf352608f7b8b7033fe/css_182264ba3d.svg" alt="CSS" />
                            </div>
                            <h3>CSS</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/388b63a90b30256764265bc2c42632e7/node_js_9996aa4840.svg" alt="Node Js" />
                            </div>
                            <h3>Node Js</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/228da9d912827550ba3077af58f438fd/typescript_d840633b4c.svg" alt="TypeScript" />
                            </div>
                            <h3>TypeScript</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/f13197a87f04d810256f06c0461cd955/firebase_3053bf70d1.svg" alt="Firebase" />
                            </div>
                            <h3>Firebase</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/11ba80f61b6d12513ac544872922db09/php_c735bf5151.svg" alt="РHP" />
                            </div>
                            <h3>РHP</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/9433bb3b828ff9007627998462f7ee08/python_03b29918c6.svg" alt="Python" />
                            </div>
                            <h3>Python</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/c5bd91ac4792ae4dadc65796ea14f0c5/ruby_on_rails_3929f3fc1a.svg" alt="Ruby On Rails" />
                            </div>
                            <h3>Ruby On Rails</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/e734b57559e55c71f5a4e2e16371c979/django_7634fbdc2b.svg" alt="Django" />
                            </div>
                            <h3>Django</h3>
                        </div>
                    </div>
                </div>
            </div>
        ),
        Frameworks: (
            <div className={`fade tab-pane ${activeTab === 'Frameworks' ? 'active show' : ''}`} role="tabpanel" aria-hidden={activeTab !== 'Frameworks'}>
                <div class="Tools2-module--techIcon--575c9">
                    <div class="Tools2-module--iconssCard--e9737"><div class="Tools2-module--technologyIcon--89419">
                        <div class="Tools2-module--techImg--de605">
                            <img decoding="async" loading="lazy" src="https://invozone.com/static/4a0597722561fd92a08b6c9da4a16ba2/magento_255a14cebf.svg" alt="Magento" />
                        </div>
                        <h3>Magento</h3>
                    </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/940de45c981cdcc186cba20dad325e8a/drupal_commerce_831ec755e3.svg" alt="Drupal Commerce" />
                            </div>
                            <h3>Drupal Commerce</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/600ac9797da1aa36ca4fdb08bd2f1343/angular_c7c636926f.svg" alt="Angular" />
                            </div>
                            <h3>Angular</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/b896beaca8d889372eb44c166a7ac3fd/shopify_ad4201fb78.svg" alt="Shopify" />
                            </div>
                            <h3>Shopify</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/b896beaca8d889372eb44c166a7ac3fd/shopify_ad4201fb78.svg" alt="Opencart" />
                            </div>
                            <h3>Opencart</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/b896beaca8d889372eb44c166a7ac3fd/shopify_ad4201fb78.svg" alt="WordPress" />
                            </div>
                            <h3>WordPress</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/4165f337e8c35f0fca5e1ba0a0b8b680/bubble_io_323be9c22b.svg" alt="Bubble.io" />
                            </div>
                            <h3>Bubble.io</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/1df894c12f5ab5f008e3e710facba198/wix_e7a51ce544.svg" alt="Wix" />
                            </div>
                            <h3>Wix</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/973490a87b8d94b17497b643affaf452/bigcommerce_b1b3a5d8e3.svg" alt="Bigcommerce" />
                            </div>
                            <h3>Bigcommerce</h3>
                        </div>
                    </div>
                    <div class="Tools2-module--iconssCard--e9737">
                        <div class="Tools2-module--technologyIcon--89419">
                            <div class="Tools2-module--techImg--de605">
                                <img decoding="async" loading="lazy" src="https://invozone.com/static/e8d176927d77dbfb147cb0e5e06f6361/prestashop_85d830b44b.svg" alt="Prestashop" />
                            </div>
                            <h3>Prestashop</h3>
                        </div>
                    </div>
                </div>
            </div>
        ),
        // Add more tabs as needed
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            <div class="Banner-module--banner--66017">
                <div class="container">
                    <nav class="mb-4">
                        <Link to={`/`} class="text-white">Home</Link> »
                        <Link to={`/industries`} class="text-white">Industries</Link> » <span class="text-white">Ecommerce</span>
                    </nav>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="Banner-module--heading--85745">
                                <h1 class="Banner-module--bannerHeading--2cd13">Custom Ecommerce Development Services</h1>
                                <p>
                                    <p>Hire a top-notch Ecommerce development company to build your platform from scratch, revamp your existing solution or seamlessly integrate it with third-party services. With a decade of experience, we deliver customized, feature-rich Ecommerce solutions for a seamless shopping experience.</p>
                                    <p>ReownLogics with proven expertise in Ecommerce development services offers a full range of Ecommerce web &amp; mobile app development.</p>
                                </p>
                            </div>
                            <div class="Banner-module--bannerBtn--ea3f6">
                                <Link to={`/contact-us/`} class="Banner-module--btn_white2_border--17068">Get My Free  Quote</Link>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="Banner-module--bnrImg--370cf">
                                <img alt="Ecommerce Development Services" src={bannerImg} decoding="async" loading="lazy" class="Banner-module--BannerImage--c9643 " />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Explore-module--subservice--312e6">
                <div class="container">
                    <h2 class="Explore-module--heading--084a5">Explore Our Ecommerce Services In Detail</h2>
                    <div class="Explore-module--description--ecb11">Our development team with the experience of 300+ projects excels in delivering custom Ecommerce development services. We offer everything you need for Ecommerce brilliance.</div>
                    <div class="Explore-module--cardBg--47d64">
                    </div>
                    <div class="gap-30 Explore-module--cardWrapper--7d9bb row">
                        <div class="gap-30 col-xl-4 col-md-6 col-12">
                            <div class="Explore-module--card--7f9b6">
                                <img src="https://invozone.com/static/1f7a95527057919a99576c29b8fec4ea/magento_ecommerce_development_f6d6d7dbff.svg" decoding="async" loading="lazy" alt="Magento Ecommerce Development" />
                                <h3>Magento Ecommerce</h3>
                                <p>Our developers know how to combine technology and creativity to build B2B &amp; B2C Magento Ecommerce solutions to grow your business.</p>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-4 col-md-6 col-12">
                            <div class="Explore-module--card--7f9b6">
                                <img src="https://invozone.com/static/0a12cfc316525d270b936727ffac95e7/ecommerce_app_development_515b645e9b.svg" decoding="async" loading="lazy" alt="Ecommerce Application Development" />
                                <h3>Ecommerce Application</h3>
                                <p>Our Ecommerce mobile app development service can include any feature you want-like push notifications etc.</p>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-4 col-md-6 col-12">
                            <div class="Explore-module--card--7f9b6">
                                <img src="https://invozone.com/static/8f8f57b72beb808886ed9fc0369d086b/b2b_ecommerce_development_4f2476f8d4.svg" decoding="async" loading="lazy" alt="B2B Ecommerce Development" />
                                <h3>B2B Ecommerce</h3>
                                <p>Our B2B Ecommerce developers can create customized Ecommerce solutions for your business. Whether it is a web or mobile app we have got you covered!</p>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-4 col-md-6 col-12">
                            <div class="Explore-module--card--7f9b6">
                                <img src="https://invozone.com/static/97464f17922af8f9f224b8dbe1f042f2/shopify_ecommerce_development_b2d7a2ebe4.svg" decoding="async" loading="lazy" alt="Shopify Ecommerce Development" />
                                <h3>Shopify Ecommerce</h3>
                                <p>ReownLogics offers Shopify development solutions focusing on ease of use to minimize development time and cost suitable for startups &amp; small businesses.</p>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-4 col-md-6 col-12">
                            <div class="Explore-module--card--7f9b6">
                                <img src="https://invozone.com/static/79615b619e8c7daa35d80db2ab7264d8/woocommerce_development_8ac90152cb.svg" decoding="async" loading="lazy" alt="WooCommerce Development" />
                                <h3>WooCommerce</h3>
                                <p>We offer dedicated WooCommerce development teams for startups &amp; large enterprises looking for customization options &amp; rich plugin-based functionalities.</p>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-4 col-md-6 col-12">
                            <div class="Explore-module--card--7f9b6">
                                <img src="https://invozone.com/static/364c7faf657e515f90bfe13e72e21b71/ecommerce_portal_development_0f5e0762dc.svg" decoding="async" loading="lazy" alt="Ecommerce Portal Development" />
                                <h3>Ecommerce Portal</h3>
                                <p>Our best Ecommerce development company develops portals equipped with transaction facilities, authentication mechanisms, content management tools, and chatbots.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Services-module--onDemandmainWork--8db37">
                <div class="container">
                    <h2 class="Services-module--heading--76cde">Boost Your Business With Our Innovative Ecommerce Solutions</h2>
                    <div class="Services-module--description--8d692">ReownLogic’s innovative Ecommerce solutions are at your beck and call to set you apart in the market.</div>
                    <div class=" Services-module--cardWrapper--35421 row">
                        <div class="col-12 col-md-4">
                            <div class="Services-module--card--2c98e">
                                <img src="https://invozone.com/static/b566814336b24e29e88738403a9769a0/3d_modeling_51e806837b.svg" decoding="async" loading="lazy" />
                                <h3>3D Modeling</h3>
                                <p>Employ 3D modeling for online product visualization to gain your customer confidence by offering a more accurate & interactive representation of products.</p>
                            </div>
                        </div>

                        <div class="col-12 col-md-4">
                            <div class="Services-module--card--2c98e">
                                <img src="https://invozone.com/static/91958d05d835740d68b279004152ba7c/live_video_consultations_120fef987e.svg" decoding="async" loading="lazy" />
                                <h3>Live Video Consultations</h3>
                                <p>Allow your customers to engage with your products in real-time including live shopping, product demonstrations, and 1-2-1 video consultations.</p>
                            </div>
                        </div>

                        <div class="col-12 col-md-4">
                            <div class="Services-module--card--2c98e">
                                <img src="https://invozone.com/static/1407d386f1b47bf1cc386da61022b5a6/voice_assisted_shopping_3b08efd69f.svg" decoding="async" loading="lazy" />
                                <h3>Voice-Assisted Shopping</h3>
                                <p>Make it easier and faster for your customers to buy your products with voice commands and increase your sales by saving them from log-ins and fill-ins.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="Industry-module--ready--a00f8">
                <div class="Industry-module--pad--57e25 container">
                    <div class="Industry-module--readyBg--7ef89">
                        <div class="row">
                            <div class="col-lg-7">
                                <h2>Join ReownLogics Wide Array Of Market-Tested Ecommerce Services And Make Your Mark In The Industry</h2>
                                <div class="Industry-module--bannerBtn--b5e2b">
                                    <Link to={`/contact-us/`} class="Industry-module--btn_white2_border--83102">Book My Call</Link>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-12">
                                <div data-gatsby-image-wrapper="" class="gatsby-image-wrapper gatsby-image-wrapper-constrained Industry-module--bnrImg--b3196">
                                    <div style={{ maxWidth: '988px', display: 'block' }}>
                                        <img alt="" role="presentation" aria-hidden="true" src="data:image/svg+xml;charset=utf-8,%3Csvg height='1020' width='988' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E" style={{ maxWidth: '100%', display: 'block', position: 'static' }} />
                                    </div>
                                    <div aria-hidden="true" data-placeholder-image="" style={{ opacity: '0', transition: 'opacity 500ms linear', backgroundColor: '#080808', position: 'absolute', top: '0', left: '0', bottom: '0', right: '0', objectFit: 'cover' }}>
                                    </div>
                                    <img placeholder="blurred" decoding="async" src="https://invozone.com/static/1215d671cccf61ae9240ebf705db2c3b/e6f44/ecommerce_service_1bce25efbd.png" data-main-image="" width="988" height="1020" style={{ objectFit: 'cover', opacity: '1' }} alt="Join ReownLogic's Wide Array Of Market-Tested Ecommerce Services And Make Your Mark In The Industry" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Disposal-module--Ideation--9e497">
                <div class="container">
                    <h2 class="Disposal-module--heading--0b2eb">Our Top Ecommerce Development Process At Your Disposal</h2>
                    <p class="Disposal-module--dec--a27d9">Let us set you on the path to success and profits with our flawless and intact development process.</p>
                    <div class="Disposal-module--cardsData--06386">
                        <div class="Disposal-module--leftText--a320d">
                            <div class="Disposal-module--cards--0a8d8">
                                <div class="Disposal-module--peakNumber2--11ccc">
                                    <span class="Disposal-module--serial2--6747f">
                                    </span>
                                    <div class="Disposal-module--research--7dc62">
                                        <span>01-</span>
                                        <h3>Ideation &amp; Planning</h3>
                                    </div>
                                </div>
                                <p>In this phase, we do rigorous client meetings to collect all their demands, requirements, do analysis, and gather data.</p>
                            </div>
                            <div class="Disposal-module--cards--0a8d8">
                                <div class="Disposal-module--peakNumber2--11ccc">
                                    <span class="Disposal-module--serial2--6747f">
                                    </span>
                                    <div class="Disposal-module--research--7dc62">
                                        <span>02-</span>
                                        <h3>Design</h3>
                                    </div>
                                </div>
                                <p>Here the wireframe layout is generated and a prototype is designed. This phase aims to achieve design sign-off after client feedback.</p>
                            </div>
                            <div class="Disposal-module--cards--0a8d8">
                                <div class="Disposal-module--peakNumber2--11ccc">
                                    <span class="Disposal-module--serial2--6747f"></span>
                                    <div class="Disposal-module--research--7dc62">
                                        <span>03-</span>
                                        <h3>Development</h3>
                                    </div>
                                </div>
                                <p>Next is the development phase in which coding is done on the main layout and again client feedback is included.</p>
                            </div>
                        </div>
                        <div class="Disposal-module--closeUp--32d9c">
                            <img src="https://invozone.com/static/ba152e5498570970caccdb50801a3b67/ecommerce_process_of_invozone_8b1cc681b4.svg" alt="Our Top Ecommerce Development Process At Your Disposal" decoding="async" loading="lazy" />
                        </div>
                        <div class="Disposal-module--rightText--b7b0d">
                            <div class="Disposal-module--cards2--ce80d">
                                <div class="Disposal-module--peakNumber--d5c16">
                                    <span class="Disposal-module--serial2--6747f">
                                    </span>
                                    <div class="Disposal-module--research--7dc62">
                                        <span>04-</span>
                                        <h3>Testing</h3>
                                    </div>
                                </div>
                                <p>Our dedicated QA team checks code quality, performance, and does security and integration testing, &amp; also identifies any potential bugs.</p>
                            </div>
                            <div class="Disposal-module--cards2--ce80d">
                                <div class="Disposal-module--peakNumber--d5c16">
                                    <span class="Disposal-module--serial2--6747f">
                                    </span>
                                    <div class="Disposal-module--research--7dc62">
                                        <span>05-</span>
                                        <h3>Release</h3>
                                    </div>
                                </div>
                                <p>After undergoing QA testing, the product is smoothly released and made available to users. This release can be public or private.</p>
                            </div>
                            <div class="Disposal-module--cards2--ce80d">
                                <div class="Disposal-module--peakNumber--d5c16">
                                    <span class="Disposal-module--serial2--6747f">
                                    </span>
                                    <div class="Disposal-module--research--7dc62">
                                        <span>06-</span>
                                        <h3>Maintenance</h3>
                                    </div>
                                </div>
                                <p>Any post-release feedback received from the users is promptly implemented and overall upgrades and repairs are done.</p>
                            </div>
                        </div>
                    </div>
                    <div class="Disposal-module--bannerBtn--21a75">
                    </div>
                </div>
            </div>
            <div class="Benefit-module--hireCyber--1ab56">
                <div class="container">
                    <div class="Benefit-module--hire--037db">
                        <h2>Benefits Of Hiring Us For Your Next Big Project?</h2>
                        <div class="Benefit-module--description--24589">Elevate your project with ReownLogic's expert Ecommerce developers. Ensure innovation and growth in your next big project with the best ecommerce development team.</div>
                        <div class="Benefit-module--hireImg2--85be8">
                            <img src="https://invozone.com/static/fe44e34bf258d7b5ecda3c914ede5df6/benefits_of_hiring_invozone_f409f4deda.svg" alt="Best Ecommerce Development Team" decoding="async" loading="lazy" />
                        </div>
                    </div>
                    <div class="gap-30 row">
                        <div>
                            <div class="Benefit-module--cards--60a8f">
                                <div class="Benefit-module--talent--d8e87">
                                    <img class="Benefit-module--tick--c22a4" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Strong Communication Skills" />
                                    <div class="Benefit-module--text--0810a">
                                        <p>Strong Communication Skills</p>
                                    </div>
                                </div>
                                <div class="Benefit-module--talent--d8e87">
                                    <img class="Benefit-module--tick--c22a4" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Expertise in Popular Frameworks &amp; Tools" />
                                    <div class="Benefit-module--text--0810a">
                                        <p>Expertise in Popular Frameworks &amp; Tools</p>
                                    </div>
                                </div>
                                <div class="Benefit-module--talent--d8e87">
                                    <img class="Benefit-module--tick--c22a4" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Experienced with Cloud Based Technologies" />
                                    <div class="Benefit-module--text--0810a">
                                        <p>Experienced with Cloud Based Technologies</p>
                                    </div>
                                </div>
                                <div class="Benefit-module--talent--d8e87">
                                    <img class="Benefit-module--tick--c22a4" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Regular Reporting" />
                                    <div class="Benefit-module--text--0810a">
                                        <p>Regular Reporting</p>
                                    </div>
                                </div>
                                <div class="Benefit-module--talent--d8e87">
                                    <img class="Benefit-module--tick--c22a4" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="“Never Say Die” Attitude" />
                                    <div class="Benefit-module--text--0810a">
                                        <p>“Never Say Die” Attitude</p>
                                    </div>
                                </div>
                                <div class="Benefit-module--talent--d8e87">
                                    <img class="Benefit-module--tick--c22a4" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="User-Centric Perspective" />
                                    <div class="Benefit-module--text--0810a">
                                        <p>User-Centric Perspective</p>
                                    </div>
                                </div>
                                <div class="Benefit-module--talent--d8e87">
                                    <img class="Benefit-module--tick--c22a4" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Reliable Time &amp; Task Management" />
                                    <div class="Benefit-module--text--0810a">
                                        <p>Reliable Time &amp; Task Management</p>
                                    </div>
                                </div>
                                <div class="Benefit-module--talent--d8e87">
                                    <img class="Benefit-module--tick--c22a4" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Eye for Detail" />
                                    <div class="Benefit-module--text--0810a">
                                        <p>Eye for Detail</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div class="Outsource-module--gotProject--0f72c">
                <div class="container">
                    <div class="Outsource-module--form--9800a">
                        <div class="Outsource-module--projectForm--c664e">
                            <div>
                                <div class="Outsource-module--formData--6a2dd">
                                    <h2>
                                        <b>Outsource Your Ecommerce Development Project Today</b>
                                    </h2>
                                    <div class="formContent1">
                                        <div class="row">
                                            <div class="col-md-6 col-12">
                                                <div class="mb-1 form-group">
                                                    <label class="font-weight-normal px-1 form-label" for="name">
                                                    </label>
                                                    <input name="fullName" placeholder="Full Name*" type="text" id="name" class="form-control" value="" />
                                                    <div class="text-left position-absolute invalid-feedback">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <div class="mb-1 form-group">
                                                    <label class="font-weight-normal px-1 form-label" for="name">
                                                    </label>
                                                    <input name="email" placeholder="Email Address*" type="text" id="name" class="form-control" value="" />
                                                    <div class="text-left position-absolute invalid-feedback">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="pt-3 pt-md=0 form-group">
                                                    <label class="font-weight-normal px-1 form-label" for="message">Message <span class="text-danger">*</span>
                                                    </label>
                                                    <textarea rows="7" name="message" placeholder="Drop Us A Message*" id="message" class="form-control">
                                                    </textarea>
                                                    <div class="text-left  invalid-feedback">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="Outsource-module--submitBtn--2fd79">
                                                    <button class="btn_white_border_yellow mt-3 " type="submit">Submit</button>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <p class="m-0 mt-2 w-100 text-left">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="Outsource-module--briefSec--d9b24">
                                <div class="Outsource-module--brief--b61ae">
                                    <h3> Seamless Ecommerce Software: Achieve More in Less Cost And Time</h3>
                                    <div class="Outsource-module--containerIntro--d5c8d">
                                        <div class="Outsource-module--briefDot--55603">
                                        </div>
                                        <h4>Our Agile Approach </h4>
                                        <br />
                                        <p>ReownLogics offers a streamlined Agile approach for your development needs, ensuring efficient and flexible progress.</p>
                                    </div>
                                    <div class="Outsource-module--containerIntro--d5c8d">
                                        <div class="Outsource-module--briefDot--55603">
                                        </div>
                                        <h4>Flexible Engagement Models </h4>
                                        <br />
                                        <p>Our flexible engagement models are tailored to meet your unique project requirements with ease and efficiency.</p>
                                    </div>
                                    <div class="Outsource-module--containerIntro--d5c8d">
                                        <div class="Outsource-module--briefDot--55603">
                                        </div>
                                        <h4>Trailblazing Technology </h4>
                                        <br />
                                        <p>With our trailblazing technology solutions, set new benchmarks in innovation and performance.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tech">
                <div class="Tools2-module--globalIndus--eaf65">
                    <div class="container">
                        <h2 class="Tools2-module--heading--bbf9a">Your Success, Our Top Tech Stacks</h2>
                        <div class="Tools2-module--description--c263a">We leverage top tech stacks, including low-code/no-code platforms and blockchain integration, to drive your progress and deliver efficient e-commerce solutions.</div>
                        <div class="Tools2-module--cardBg--4ef53">
                        </div>
                        <div class="Tools2-module--cards--28f17">
                            <div class="Tools2-module--cardsContent--90593">
                                <div>
                                    <div className="commerceTab nav nav-pills" role="tablist">
                                        {Object.keys(tabContent).map((tab) => (
                                            <div className="nav-item" key={tab}>
                                                <a

                                                    role="tab"
                                                    data-rb-event-key={tab}
                                                    id={`left-tabs-example-tab-${tab}`}
                                                    aria-controls={`left-tabs-example-tabpane-${tab}`}
                                                    aria-selected={activeTab === tab}
                                                    className={`nav-link ${activeTab === tab ? 'active' : ''}`}
                                                    onClick={() => handleTabClick(tab)}
                                                >
                                                    <div className="Tools2-module--tabData--aeee7">
                                                        <p>{tab}</p>
                                                    </div>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="contentBlock tab-content">
                                        {Object.keys(tabContent).map((tab) => tabContent[tab])}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="paddingEngagement">
                <div class="EngagementModels-module--customized--98ee7">
                    <div class="EngagementModels-module--con--c6774 container">
                        <div class="EngagementModels-module--engagment--08989">
                            <h2 class="EngagementModels-module--mainHeading--6143f">Benefits Of Hiring Us For Your Next Big Project?</h2>
                            <p class="EngagementModels-module--subHeading--ed660 pt-2">Elevate your project with ReownLogic's expert Ecommerce developers. Ensure innovation and growth in your next big project with the best ecommerce development team.</p>
                            <div class="EngagementModels-module--flexWrapper--fd313">
                                <div class="EngagementModels-module--card--49dea">
                                    <div>
                                        <img decoding="async" loading="lazy" src="https://invozone-backend.s3.amazonaws.com/staff_augmentation_2d38971296.svg" alt="Staff Augmentation" />
                                    </div>
                                    <h3 class="EngagementModels-module--pHead--b6403 ">Staff Augmentation</h3>
                                </div>
                                <div class="EngagementModels-module--card--49dea">
                                    <div>
                                        <img decoding="async" loading="lazy" src="https://invozone-backend.s3.amazonaws.com/dedicated_teams_b039269a37.svg" alt="Dedicated Teams" />
                                    </div>
                                    <h3 class="EngagementModels-module--pHead--b6403 ">Dedicated Teams</h3>
                                </div>
                                <div class="EngagementModels-module--card--49dea">
                                    <div>
                                        <img decoding="async" loading="lazy" src="https://invozone-backend.s3.amazonaws.com/fixed_gigs_e698272070.svg" alt="Product Development" />
                                    </div>
                                    <h3 class="EngagementModels-module--pHead--b6403 ">Product Development</h3>
                                </div>
                            </div>
                            {/* <div class="EngagementModels-module--teamBtn2--41cc5">
                                <a class="EngagementModels-module--btn--f07fb" href="/pricing/">Get The Best Quote Today</a>
                            </div> */}
                        </div>
                        {/* <div class="EngagementModels-module--teamBtn--3dc66">
                            <a class="EngagementModels-module--btn--f07fb" href="/pricing/">Get The Best Quote Today</a>
                        </div> */}
                    </div>
                </div>
            </div>
            <div class="Hire-module--banner--2cbea">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2">
                            <div class="Hire-module--heading--8a8ca">
                                <h2 class="Hire-module--bannerHeading--beba2">Hire eCommerce Developers</h2>
                                <p>Build your robust, secure, and scalable online ecommerce platform with ReownLogic’s seasoned Ecommerce development experts, who possess the experience of successfully completing 300+ projects with a 99% success rate. Just reach out to us, and we'll take care of everything else.</p>
                            </div>
                            {/* <div class="Hire-module--bannerBtn--f999b">
                                <a class="Hire-module--btn_white2_border--1d752" href="/dedicated-development-team/">Hire Dedicated Developers</a>
                            </div> */}
                        </div>
                        <div class="Hire-module--bnrImg--00cbf col-lg-6 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                            <img src="https://invozone.com/static/82d44c00c9c7332c0a3ec38491c9549c/hire_ecommerce_developers_adbe2929a0.svg" alt="Hire eCommerce Developers" decoding="async" loading="lazy" class="undefined " />
                        </div>
                    </div>
                </div>
            </div>
            <div class="Services-module--subservice--319fd">
                <div class="container">
                    <h2 class="Services-module--heading--9c742">What Is The Cost of Ecommerce Development Services?</h2>
                    <div class="Services-module--description--68845">The ecommerce development cost depends upon project scope and level of requirements. But our models are designed to  accommodate you in the best way possible. Check out below!</div>
                    <div>
                    </div>
                    <div class="gap-30 Services-module--cardWrapper--fdf84 row">
                        <div class="gap-30 col-xl-4 col-md-6 col-12">
                            <div class="Services-module--card--e126e">
                                <img src="https://invozone.com/static/f4c9ba4ba41407763b529f98a8a99aca/basic_eb1993a49c.svg" decoding="async" loading="lazy" alt="Basic Cost For Ecommerce Development" />
                                <h3>Basic</h3>
                                <p>This package has preparation of initial product and fulfillment of all the basic requirements.</p>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-4 col-md-6 col-12">
                            <div class="Services-module--card--e126e">
                                <img src="https://invozone.com/static/a7d740b5eaa27236d84a4414b0403d03/premium_11a60f080a.svg" decoding="async" loading="lazy" alt="Premium Cost For Ecommerce Development" />
                                <h3>Premium</h3>
                                <p>It includes everything from basic package.You can add more functionalities &amp; maintenance services. </p>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-4 col-md-6 col-12">
                            <div class="Services-module--card--e126e">
                                <img src="https://invozone.com/static/a92dbfff9822d35393081f0ad13ef1d2/business_bd0b41c7fe.svg" decoding="async" loading="lazy" alt="Business Cost For Ecommerce Development" />
                                <h3>Business</h3>
                                <p>All things from premium &amp; basic are there.Also you can add more staff &amp; developers.</p>
                            </div>
                        </div>
                    </div>
                    {/* <div class="Services-module--bannerBtn--584a3">
                        <a class="Services-module--btn_white2_border--845de" href="/pricing/">Discover The Cost Of Your Project</a>
                    </div> */}
                </div>
            </div>
            <WorkPortfolio />
            <div class="Ready-module--subservice--6ac92">
                <div class="Ready-module--con--3c35a container">
                    <div class="Ready-module--Ready--f0594">
                        <div class="Ready-module--description2--84d4f">500+ Developers &amp; Trained Professionals | 99 % Success Rate | 300+ Projects</div>
                        <h2 class="Ready-module--heading--22a86">Ready To Be The Next Ecommerce Giant?</h2>
                        <div class="Ready-module--description--d28ee">Elevate your Ecommerce business with ReownLogic’s Affordable Ecommerce solution.</div>
                        {/* <div class="Ready-module--bannerBtn--7f83e">
                            <a class="Ready-module--btn_white2_border--75ddd" href="/contact-us/">Get Connected Today</a>
                        </div> */}
                    </div>
                </div>
            </div>
            <Faq />
            <LetUsKnow />
        </>
    )
}
