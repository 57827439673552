import React from 'react'
import "./OnDemandServices.css";
import { Link } from 'react-router-dom';

export default function OnDemandServices() {
    return (
        <>
            <div class="Banner-module--banner--dba7f">
                <div class="container">
                <nav class="mb-4">
                        <Link to={`/`} class="text-white">Home</Link> »
                        <Link to={`/industries`} class="text-white">Industries</Link> » <span class="text-white">On-Demand Services</span>
                    </nav>
                    <div class="align-items-center row">
                        <div class="col-lg-6 col-md-12">
                            <div class="Banner-module--heading--516dc">
                                <h1 class="Banner-module--bannerHeading--d620f">Best On-Demand App Development Company</h1>
                                <p>
                                    <p>Connect with the thriving community of buyers and shoppers through an innovative on-demand app!</p>
                                    <p>Irrespective of your industry, the on-demand app enhances the accessibility of your products and services, leading to heightened sales, increased profits and improved market visibility with guaranteed scalability. So partner with an on-demand app development company to create a feature-rich app to turn your brand into the next household name.</p>
                                </p>
                            </div>
                            <div class="Banner-module--bannerBtn--0c9cf">
                                <Link to={`/contact-us`} class="Banner-module--btn_white2_border--218ff">Discuss Your App Idea</Link>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="Banner-module--bnrImg--5db6f">
                                <img src="https://invozone.com/static/8198fec5c6f6ab50942fb1fafc1af13c/best_on_demand_app_development_company_16365feca0.svg" alt="Best On-Demand App Development Company" decoding="async" loading="lazy" class="Banner-module--BannerImage--fb58c " />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Services-module--onDemandmainWork--8db37">
                <div class="container">
                    <h2 class="Services-module--heading--76cde">Range Of On-Demand App Development Services</h2>
                    <div class="Services-module--description--8d692">On-demand apps provide increased access and enhanced visibility in the market. Our wide array of on demand app development services include:</div>
                    <div class=" Services-module--cardWrapper--35421 row">
                        <div class="col-12 col-md-4">
                            <div class="Services-module--card--2c98e">
                                <img src="https://invozone.com/static/48f8f070dd08dd127a64906af4acdf5c/grocery_delivery_app_4b4f7be6d3.svg" decoding="async" loading="lazy" />
                                <h3>Grocery Delivery Apps</h3>
                                <p>Boost your retail business with the grocery delivery app containing features such as real-time delivery tracking, loyalty programs, seamless payment options, discount and services promotion via push notifications.</p>
                            </div>
                        </div>

                        <div class="col-12 col-md-4">
                            <div class="Services-module--card--2c98e">
                                <img src="https://invozone.com/static/606578e83af3a5db8b98c495db6e2d09/laundry_app_development_b7f67ba3ca.svg" decoding="async" loading="lazy" />
                                <h3>Laundry App Development</h3>
                                <p>Stay ahead in the market with your laundry app including features like easy booking, real-time order tracking, personalized preferences, and secure payment options enhancing customer satisfaction,&amp; transparency.</p>
                            </div>
                        </div>

                        <div class="col-12 col-md-4">
                            <div class="Services-module--card--2c98e">
                                <img src="https://invozone.com/static/1507385f536895df87bfe50b87c9db90/booking_apps_e09717d016.svg" decoding="async" loading="lazy" />
                                <h3>Booking Apps</h3>
                                <p>If your business relies on reservations &amp; bookings then online booking software is a must with 24/7 access to customers with no need to answer calls. Bringing you a high number of bookings with no fuss &amp; no muss!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="TopFeature-module--featuremainContainer--388a5 container-fluid">
                <div class="TopFeature-module--mainRow--720ca row">
                    <div class="container">
                        <div class="TopFeature-module--innerRow--b9cd6 row">
                            <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                                <div class="TopFeature-module--sticky--c9970">
                                    <img decoding="async" loading="lazy" src="https://invozone.com/static/14b6604d29eab8b8c1fe3eaced686d1b/top_features_of_on_demand_app_solutions_f40b671160.svg" alt="FeaturedImg" />
                                    <h2 class="TopFeature-module--benefitsHead--f9f40">Top Features Of On-Demand App Solutions</h2>
                                    <p class="TopFeature-module--mainDesc--4bec0">We include all the latest and every feature you require in your on-demand app to help you stand apart in the market such as:</p>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12 col-sm-12 col-12">
                                <div class="gap-30 TopFeature-module--cardWrapper--66d5b row">
                                    <div class="gap-30 d-flex col-xl-6 col-md-6 col-12">
                                        <div class="TopFeature-module--card--094b2">
                                            <img src="https://invozone.com/static/9c3debc4a4b80924b6e14a3241c8f3f1/ai_fueled_solution_ae66bf39f5.svg" decoding="async" loading="lazy" />
                                            <h3>AI-Fueled Solutions</h3>
                                            <p>Want to know your customer's needs in advance? Then an AI-fueled app is your ultimate solution to get insights into customer data to stay ahead in competition.</p>
                                        </div>
                                    </div>
                                    <div class="gap-30 d-flex col-xl-6 col-md-6 col-12">
                                        <div class="TopFeature-module--card--094b2">
                                            <img src="https://invozone.com/static/dd3db9fb147928f5e8e306a84c2dc21a/booking_system_0052a790da.svg" decoding="async" loading="lazy" />
                                            <h3>Booking Systems</h3>
                                            <p>Get a booking system in your on-demand delivery app to help your users reserve the desired service, whether it is food delivery or ride hailing app in just a few taps.</p>
                                        </div>
                                    </div>
                                    <div class="gap-30 d-flex col-xl-6 col-md-6 col-12">
                                        <div class="TopFeature-module--card--094b2">
                                            <img src="https://invozone.com/static/8e5c910ceedc008c98e47736fb7efadb/push_notifications_15b034c941.svg" decoding="async" loading="lazy" />
                                            <h3>Push Notifications</h3>
                                            <p>Update your customers with new discounts and offers with push notifications, scoring you more conversions, sales and level of personalization you have always desired for.</p>
                                        </div>
                                    </div>
                                    <div class="gap-30 d-flex col-xl-6 col-md-6 col-12">
                                        <div class="TopFeature-module--card--094b2">
                                            <img src="https://invozone.com/static/586d46feda0ea0148bb59d16644d34d9/payment_integration_d64c12faf6.svg" decoding="async" loading="lazy" />
                                            <h3>Payment Integrations</h3>
                                            <p>To ensure the smooth &amp; secure payment flow we integrate exceptional payment methods so that you don’t have to worry about finances and concentrate on what’s important.</p>
                                        </div>
                                    </div>
                                    <div class="gap-30 d-flex col-xl-6 col-md-6 col-12">
                                        <div class="TopFeature-module--card--094b2">
                                            <img src="https://invozone.com/static/316d9f0868cb6bb69f7f771224ec54c8/real_time_communication_ed03a28977.svg" decoding="async" loading="lazy" />
                                            <h3>Real-Time Communications</h3>
                                            <p>We include real-time communication options (message or call) which are perfect for customers as well as service providers to communicate with each other in real time.</p>
                                        </div>
                                    </div>
                                    <div class="gap-30 d-flex col-xl-6 col-md-6 col-12">
                                        <div class="TopFeature-module--card--094b2">
                                            <img src="https://invozone.com/static/d3d65e0777fc1cf8f31994db995a84d4/effective_customer_care_cf7f134e1e.svg" decoding="async" loading="lazy" />
                                            <h3>Effective Customer Care</h3>
                                            <p>Enable easy customer support via chat, phone, and email for swift issue resolution. AI Chatbots and an SOS customer care helpline boost trust and safety.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Idea-module--mainContainer--29fec container">
                <div class="gx-0 Idea-module--mainRow--98e4f row">
                    <div class="col-lg-6">
                        <h2 class="Idea-module--mainHead--b1067">Idea To MVP In 2 Weeks</h2>
                        <div class="Idea-module--description--54e3a">Get your MVP developed in just 2 weeks with ReownLogics. Tell us what you want &amp; we will build it for you.</div>
                        <Link to={`/contact-us`} class="Idea-module--idea_btn--9f400">Talk To Us Today</Link>
                    </div>
                </div>
            </div>
            <div class="Outsource-module--gotProject--0f72c">
                <div class="container">
                    <div class="Outsource-module--form--9800a">
                        <div class="Outsource-module--projectForm--c664e">
                            <div>
                                <div class="Outsource-module--formData--6a2dd">
                                    <h2>
                                        <b>Expand Your Business By Building Robust On-Demand Apps</b>
                                    </h2>
                                    <div class="formContent1">
                                        <div class="row">
                                            <div class="col-md-6 col-12">
                                                <div class="mb-1 form-group">
                                                    <label class="font-weight-normal px-1 form-label" for="name">
                                                    </label>
                                                    <input name="fullName" placeholder="Full Name*" type="text" id="name" class="form-control" value="" />
                                                    <div class="text-left position-absolute invalid-feedback">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <div class="mb-1 form-group">
                                                    <label class="font-weight-normal px-1 form-label" for="name">
                                                    </label>
                                                    <input name="email" placeholder="Email Address*" type="text" id="name" class="form-control" value="" />
                                                    <div class="text-left position-absolute invalid-feedback">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="pt-3 pt-md=0 form-group">
                                                    <label class="font-weight-normal px-1 form-label" for="message">Message <span class="text-danger">*</span>
                                                    </label><textarea rows="7" name="message" placeholder="Drop Us A Message*" id="message" class="form-control">
                                                    </textarea>
                                                    <div class="text-left  invalid-feedback">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="Outsource-module--submitBtn--2fd79">
                                                    <button class="btn_white_border_yellow mt-3 " type="submit">Submit</button>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <p class="m-0 mt-2 w-100 text-left">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="Outsource-module--briefSec--d9b24">
                                <div class="Outsource-module--brief--b61ae">
                                    <h3>Seamless Integration of Technology &amp; Innovation</h3>
                                    <div class="Outsource-module--containerIntro--d5c8d">
                                        <div class="Outsource-module--briefDot--55603">
                                        </div>
                                        <h4>Top Tech Stacks </h4>
                                        <br />
                                        <p>Our developers are experts in the latest tech stacks and use the best ones to fulfill your app needs.</p>
                                    </div>
                                    <div class="Outsource-module--containerIntro--d5c8d">
                                        <div class="Outsource-module--briefDot--55603">
                                        </div>
                                        <h4>Scalability </h4>
                                        <br />
                                        <p>Our solutions grow with your business so that you can ensure the top-notch performance of your business.</p>
                                    </div>
                                    <div class="Outsource-module--containerIntro--d5c8d">
                                        <div class="Outsource-module--briefDot--55603">
                                        </div>
                                        <h4>Flexibility </h4>
                                        <br />
                                        <p>Our developers work with your schedule, dedicating full time &amp; effort to your project, and inform you about progress via daily meetings.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Industries-module--servicesDemand--dfaaf">
                <div class="container">
                    <h2 class="Industries-module--heading--fcbab">Booming Use Of On-Demand Apps In Different Industries</h2>
                    <div class="Industries-module--description--01686">The on-demand service apps have completely transformed every sector of society steering businesses to adopt one. Numerous industries are using on-demand apps such as:</div>
                    <div class="gap-30 Industries-module--cardWrapper--cfa63 row">
                        <div class="gap-30 col-xl-3 col-md-6 col-sm-6 col-11">
                            <div class="Industries-module--card--f254a">
                                <img src="https://invozone.com/static/e15d77bcb178532a8bf546a0374eb6ae/food_delivery_industry_94543ebdb1.svg" decoding="async" loading="lazy" />
                                <h3>Food Delivery Industry</h3>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-3 col-md-6 col-sm-6 col-11">
                            <div class="Industries-module--card--f254a">
                                <img src="https://invozone.com/static/db00deb6114d4f3a0a871d8c27f4025f/travel_and_tourism_industry_254947ffa4.svg" decoding="async" loading="lazy" />
                                <h3>Travel And Tourism industry</h3>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-3 col-md-6 col-sm-6 col-11">
                            <div class="Industries-module--card--f254a">
                                <img src="https://invozone.com/static/5fae3b8ba796ebbc34423fdb540b8e5d/on_demand_grocery_delivery_industry_1fc77ffc49.svg" decoding="async" loading="lazy" />
                                <h3>On-Demand Grocery Delivery Industry</h3>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-3 col-md-6 col-sm-6 col-11">
                            <div class="Industries-module--card--f254a">
                                <img src="https://invozone.com/static/d1e5baa9cdf683e5ca93f37501d27583/retailers_and_on_demand_marketplace_6741f927fb.svg" decoding="async" loading="lazy" />
                                <h3>Retailers And On-demand Marketplace</h3>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-3 col-md-6 col-sm-6 col-11">
                            <div class="Industries-module--card--f254a">
                                <img src="https://invozone.com/static/0fdad4aa77aa18e8e59a6913a4589a00/education_e_learning_industry_c120bb92dd.svg" decoding="async" loading="lazy" />
                                <h3>Education /e-Learning Industry</h3>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-3 col-md-6 col-sm-6 col-11">
                            <div class="Industries-module--card--f254a">
                                <img src="https://invozone.com/static/0f75084890b2c889f8e0d2416aa30c34/healthcare_industry_62db80705b.svg" decoding="async" loading="lazy" />
                                <h3>Healthcare Industry</h3>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-3 col-md-6 col-sm-6 col-11">
                            <div class="Industries-module--card--f254a">
                                <img src="https://invozone.com/static/59b2b434cb9bf65a42a26a4b0e016591/real_estate_industry_6ac451f7d8.svg" decoding="async" loading="lazy" />
                                <h3>Real-Estate Industry</h3>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-3 col-md-6 col-sm-6 col-11">
                            <div class="Industries-module--card--f254a">
                                <img src="https://invozone.com/static/3d0c78ae5f0cdc6fb219d138e4597589/packaging_and_moving_industry_cb5287b2c0.svg" decoding="async" loading="lazy" />
                                <h3>Packaging And Moving Industry</h3>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-3 col-md-6 col-sm-6 col-11">
                            <div class="Industries-module--card--f254a">
                                <img src="https://invozone.com/static/13295ae01a29a3195eece3a8f09256df/salon_and_beauty_industry_63317c36ff.svg" decoding="async" loading="lazy" />
                                <h3>Salon And Beauty Industry</h3>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-3 col-md-6 col-sm-6 col-11">
                            <div class="Industries-module--card--f254a">
                                <img src="https://invozone.com/static/ee203be123652f4cb229b63abe84ba5d/professional_services_37355ddb9a.svg" decoding="async" loading="lazy" />
                                <h3>Professional Services</h3>
                            </div>
                        </div>
                        <div class="gap-30 col-xl-3 col-md-6 col-sm-6 col-11">
                            <div class="Industries-module--card--f254a">
                                <img src="https://invozone.com/static/6c4e8cdb8123f25f1bd1d96a2873a3b7/fitness_services_bc113c9790.svg" decoding="async" loading="lazy" />
                                <h3>Fitness Services</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="paddingEngagement">
                <div class="EngagementModels-module--customized--98ee7">
                    <div class="EngagementModels-module--con--c6774 container">
                        <div class="EngagementModels-module--engagment--08989">
                            <h2 class="EngagementModels-module--mainHeading--6143f">Our Engagement Models Pave Your Way To Success</h2>
                            <p class="EngagementModels-module--subHeading--ed660 pt-2">ReownLogic’s versatile cost-effective engagement models accommodate your needs in the best possible way within market friendly rates.</p>
                            <div class="EngagementModels-module--flexWrapper--fd313">
                                <div class="EngagementModels-module--card--49dea">
                                    <div>
                                        <img decoding="async" loading="lazy" src="https://invozone-backend.s3.amazonaws.com/staff_augmentation_2d38971296.svg" alt="Staff Augmentation" />
                                    </div>
                                    <h3 class="EngagementModels-module--pHead--b6403 ">Staff Augmentation</h3>
                                </div>
                                <div class="EngagementModels-module--card--49dea">
                                    <div>
                                        <img decoding="async" loading="lazy" src="https://invozone-backend.s3.amazonaws.com/dedicated_teams_b039269a37.svg" alt="Dedicated Teams" />
                                    </div>
                                    <h3 class="EngagementModels-module--pHead--b6403 ">Dedicated Teams</h3>
                                </div>
                                <div class="EngagementModels-module--card--49dea">
                                    <div>
                                        <img decoding="async" loading="lazy" src="https://invozone-backend.s3.amazonaws.com/fixed_gigs_e698272070.svg" alt="Product Development" />
                                    </div>
                                    <h3 class="EngagementModels-module--pHead--b6403 ">Product Development</h3>
                                </div>
                            </div>
                            {/* <div class="EngagementModels-module--teamBtn2--41cc5">
                                <a class="EngagementModels-module--btn--f07fb" href="/pricing/">Let’s Connect Now</a>
                            </div> */}
                        </div>
                        {/* <div class="EngagementModels-module--teamBtn--3dc66">
                            <a class="EngagementModels-module--btn--f07fb" href="/pricing/">Let’s Connect Now</a>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
