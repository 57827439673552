import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import EcommerceDevelopment from '../Fullpages/Ecommerce/EcommerceDevelopment'
export default function Ecommerce() {
  return (
    <>
    <Navbar/>
    <EcommerceDevelopment/>
    <Footer/>
    </>
  )
}
