import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'
import TravelTour from '../Fullpages/TravelTourism/TravelTour'

export default function TravelTourism() {
  return (
    <>
    <Navbar/>
    <TravelTour/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
