import React from 'react'
import "./TravelTour.css";
import { Link } from "react-router-dom";

export default function TravelTour() {
    return (
        <>
            <section class="Banner-module--section--9bbff">
                <div class="container">
                    <nav class="mb-4">
                        <Link to={`/`} class="text-dark">Home</Link> » 
                        <Link to={`/industries`} class="text-dark">Industries</Link> » <span class="text-dark">Travel Tourism</span>
                    </nav>
                    <div class="align-items-center row">
                        <div class="col-md-6">
                            <h1 class="main-banner-heading-55">Travel and Tourism Software Development</h1>
                            <div>Partner with a trusted custom software development company to digitize your travel and tourism business.</div>
                            <div class="mt-5">
                                <Link to={`/contact-us/`}>
                                    <button type="button" class="btn_black_border btn btn-primary">Hire Software Developers</button>
                                </Link>
                            </div>
                        </div>
                        <div class="text-center mt-5 mt-md-0 col-md-6">
                            <div data-gatsby-image-wrapper="" class="gatsby-image-wrapper gatsby-image-wrapper-constrained">
                                <div style={{ maxWidth: '555px', display: 'block' }}>
                                    <img alt="" role="presentation" aria-hidden="true" src="data:image/svg+xml;charset=utf-8,%3Csvg height='384' width='555' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E" style={{ maxWidth: '100%', display: 'block', position: 'static' }} />
                                </div>
                                <div aria-hidden="true" data-placeholder-image="" style={{ opacity: '0', transition: 'opacity 500ms linear 0s', backgroundColor: 'rgb(8, 8, 8)', position: 'absolute', inset: '0px', objectFit: 'cover' }}>
                                </div>
                                <img placeholder="blurred" decoding="async" width="555" height="384" data-main-image="" style={{ objectFit: 'cover', opacity: '1' }} sizes="(min-width: 555px) 555px, 100vw" loading="lazy" src="https://invozone.com/static/480c2f9604ffa6eac030cc408dea55e6/e2866/Travel_and_Tourism_Software_Development_c411538f5a.webp" alt="banner image" />
                                <noscript>
                                    <img placeholder="blurred" decoding="async" width="555" height="384" data-main-image="" style={{ objectFit: 'cover', opacity: '0' }} sizes="(min-width: 555px) 555px, 100vw" loading="lazy" src="/static/3274c748c09e1c663132982356e5b395/e2866/Grocery_and_Food_Delivery_App_Development_Services_4dcf49d037.webp" srcSet="/static/3274c748c09e1c663132982356e5b395/e3ef5/Grocery_and_Food_Delivery_App_Development_Services_4dcf49d037.webp 139w,
/static/3274c748c09e1c663132982356e5b395/e0d65/Grocery_and_Food_Delivery_App_Development_Services_4dcf49d037.webp 278w,
/static/3274c748c09e1c663132982356e5b395/e2866/Grocery_and_Food_Delivery_App_Development_Services_4dcf49d037.webp 555w" alt="banner image" />
                                </noscript>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="SubServices-module--section--f9a5e">
                <div class="container">
                    <div class="text-center">
                        <h2 class="h1 main-heading-36">Custom Travel and <span class="text-primary"> Tourism App Development </span><br /> to Enhance Hospitality
                            Business</h2>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6" style={{ padding: '15px' }}>
                            <div class="SubServices-module--industrySubSerCard--8e136 card">
                                <div class="card-body"><div class="mb-3">
                                    <img src="https://invozone.com/static/279b0b7c0666c0d2e64c1a2f4b301382/On_Demand_Travel_Application_373c79d0d6.svg" alt="Grocery App Development" width="48" decoding="async" loading="lazy" />
                                </div>
                                    <div class="card-title h5">
                                        <h3 class="h4">On-Demand Travel Application</h3>
                                    </div>
                                    <p class="SubServices-module--descriptionTxt--707dd">Leverage on-demand travel apps development services to build custom and advanced travel apps that are robust and scalable. We have expertise in building ride-sharing, taxi booking apps, travel information exchange systems, rental booking, and virtual touring apps.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" style={{ padding: '15px' }}>
                            <div class="SubServices-module--industrySubSerCard--8e136 card">
                                <div class="card-body"><div class="mb-3">
                                    <img src="https://invozone.com/static/7c0169f21c85c736848859329b691aa1/E_booking_and_Ticketing_Systems_f399ac1997.svg" alt="Food Delivery App Development" width="48" decoding="async" loading="lazy" />
                                </div>
                                    <div class="card-title h5">
                                        <h3 class="h4">E-booking and Ticketing Systems</h3>
                                    </div>
                                    <p class="SubServices-module--descriptionTxt--707dd">Provide interactive booking and ticketing experience for your customers by building apps with the latest technologies i.e. AI and cloud computing. ReownLogic’s can help you build all types of e-booking and ticketing systems.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" style={{ padding: '15px' }}>
                            <div class="SubServices-module--industrySubSerCard--8e136 card">
                                <div class="card-body"><div class="mb-3">
                                    <img src="https://invozone.com/static/21463024631ca0f47d9a65523b535f9e/AR_and_VR_based_Travel_Apps_727de37d49.svg" alt="Online Food Ordering App" width="48" decoding="async" loading="lazy" />
                                </div>
                                    <div class="card-title h5">
                                        <h3 class="h4">AR and VR based Travel Apps</h3>
                                    </div>
                                    <p class="SubServices-module--descriptionTxt--707dd">Assist users in defining their travel destinations by unlocking the power of augmented and virtual reality-based apps. Build AR/VR apps like AR based travel planning and guide apps, Travel management solutions, virtual tours apps, and AR-based GPS apps.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" style={{ padding: '15px' }}>
                            <div class="SubServices-module--industrySubSerCard--8e136 card">
                                <div class="card-body">
                                    <div class="mb-3">
                                        <img src="https://invozone.com/static/2dffb979336d2c6e8a98bdcd8ede4f4a/Travel_Mobility_Solutions_a1b00da4cd.svg" alt="Restaurant Delivery App" width="48" decoding="async" loading="lazy" />
                                    </div>
                                    <div class="card-title h5">
                                        <h3 class="h4">Travel Mobility Solutions</h3>
                                    </div>
                                    <p class="SubServices-module--descriptionTxt--707dd">Build next-generation tourism and hospitality apps especially for hotels and restaurants. Our developers are experts in building tourism apps including Customer service kiosks, payment gateways, seat booking, and food ordering apps.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" style={{ padding: '15px' }}>
                            <div class="SubServices-module--industrySubSerCard--8e136 card">
                                <div class="card-body">
                                    <div class="mb-3">
                                        <img src="https://invozone.com/static/8d953843a0413827304b3056ae7fcc3a/Custom_Travel_ERP_Solutions_d960d4c6d2.svg" alt="Grocery Store Software" width="48" decoding="async" loading="lazy" />
                                    </div>
                                    <div class="card-title h5">
                                        <h3 class="h4">Custom Travel ERP Solutions</h3>
                                    </div>
                                    <p class="SubServices-module--descriptionTxt--707dd">Manage and integrate transport workflow with custom-built ERP solutions that initiate better fleet management, budgeting, invoicing, and much more. Hand over your travel and tourism app development needs to InvoZone and scale your business.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6" style={{ padding: '15px' }}>
                            <div class="SubServices-module--industrySubSerCard--8e136 card">
                                <div class="card-body">
                                    <div class="mb-3">
                                        <img src="https://invozone.com/static/ac5efdadf814c23f6df72f195b381145/Transportation_Information_App_f9f835127f.svg" alt="Restaurant Management Software" width="48" decoding="async" loading="lazy" />
                                    </div>
                                    <div class="card-title h5">
                                        <h3 class="h4">Transportation Information App</h3>
                                    </div>
                                    <p class="SubServices-module--descriptionTxt--707dd">Empower users with real-time transportation information apps. We help you develop cross-platform mobile software with user-friendly interfaces and custom-built features.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="GitStrip-module--section--188b0">
                <div class="container">
                    <div class="align-items-center row">
                        <div class="py-2 col-xl-8">
                            <h2 class="text-light mb-0">Address the growing technology demand</h2>
                        </div>
                        <div class="py-2 col-xl-4">
                            <Link to={`/contact-us`}>
                                <button type="button" class="btn_white_border btn btn-mt-3 mt-xl-0">Get in touch</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="align-items-center mr-0 pl-md-5 row">
                    <div class="pl-md-5 col-md-6 col-12">
                        <div class="col-12">
                            <h2 class="h1 main-heading-36">Why  <span class="text-primary"> Outsource Tourism and Travel App Development  </span> to ReownLogic’s?</h2>
                        </div>
                        <ol class="mt-0">
                            <li class="pb-2">Time and Cost Efficient</li>
                            <li class="pb-2">Diverse Technology Stack</li>
                            <li class="pb-2">On demand expertise</li>
                            <li class="pb-2">Dedicated support team</li>
                            <li class="pb-2">Built to scale development</li>
                            <li class="pb-2">UI/UX optimized development</li>
                            <li class="pb-2">Flexible engagement models</li>
                            <li class="pb-2">Dedicated development teams</li>
                        </ol>
                        <div class="mt-5 pl-3">
                            <Link to={`/contact-us`}>
                                <button type="button" class="btn_black_border btn btn-primary">Let us Call You</button>
                            </Link>
                        </div>
                    </div>
                    <div class="text-right mt-5 mt-md-0 px-md-0 col-md-6 col-12">
                        <div data-gatsby-image-wrapper="" class="gatsby-image-wrapper gatsby-image-wrapper-constrained ml-2 ml-md-5">
                            <div style={{ maxWidth: '855px', display: 'block' }}>
                                <img alt="" role="presentation" aria-hidden="true" src="data:image/svg+xml;charset=utf-8,%3Csvg height='538' width='855' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E" style={{ maxWidth: '100%', display: 'block', position: 'static' }} />
                            </div>
                            <img aria-hidden="true" data-placeholder-image="" style={{ opacity: '0', transition: 'opacity 500ms linear 0s', objectFit: 'cover' }} decoding="async" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAADuklEQVQ4y12Te0xTZxiH38PcRN1QJgjBiSCKYVLAOJG1tKUXWnpOaaFAYdyMpZSk4eImMC6DjYGBDQwxKosjbiwTh4yLmRtlLnNkXnbTZE62aNTNmJm5DE00QFvOOf0txYXEvX993y/v9+R5k/cjqQEXpKa5C7I89zl5iecnhd0zqXTOBysrPaSo8NC6YD39v0KD1LQxwUZhYZz/HODPAJCkuGeMZAZAmjkHmdUNeYkHinLvjKoGYdo6kKoSzPoIK8XEOp8AbojbTaFrtLR2hXwJlmg/MmL+YRYkNUCQmtzex0Dv1ZSCWynPP7tzmb8xJDCZVtIyio5xLMFeiHmF1q5U0BrmJcZ/HwIoofjgx5lTD5F7Awt+w4UU7j5SrV7ISz2Ql82Nhodym1ZTxAr/g6eI6Gkiit7soIjIPAoO2ElBJFmE7QcoPr/3A871ADnTECzTIkjGAdsVP55L1F25m1oKyMu9UDpnJzdG7d5CRAH+cZ75z+45iqNVFMX4MwdA27K7+wxjM7BchpB1WYTp/KMGejnDN6XJhbY4Pd+qzJ0WtE6fT1nlRdpr866oGFto8XlQ+e9YNAqk8EVYwTQoju3s1Z/4G1kXwZsvCjCeuV/bBBCZLZeo3cGG9dXZj2tTcz6U5V0SlNUQVbVeqBrmJzfFOkIKvwcp22YDTgIMO+Sjrdr2zvRjf8F8FrzprAD29ExzO0B9AENRkl1UYC7ql6tKW+qqavaW7jn4raLstlddD2haFqB+yz2x5cWaEInla9L03qVYRWur5vCfMLnAG108uIm5pi6ATgFM6v5viCJjMgKl8oI/quwVyc7uW33VB67YtyWVObcX/Tqb1uwT0jsWoO10f7k50ha0VdFer+65A+M4eG6MR+aE2Gic8FIdwKj6fiH98B2iHbtyQuIlmqtqc62ucO/gdYqtWPdFf2dDW8fY+yWtv4Hr9Pq0PT5o3314U3PgH54dhI8d4sGNi23Gz0RiRz0Bmv6bDHvqAWWM3iOKT9C1xCdmDJhs7+zR5TfWy7JbKt8bvv3VvRnRbrEe0Uorrwu6HgH6QwIy+n0wDCyAHRI6uGGB2JM8YzwtMpkukTLPiI9XISlJP5CdV12lcxwN73rz0OoKR11qUfN3NkVh18+rotdviE/pTpTvu/ZJeq97Sn+U/9wwwBeygyIZjgvEjQgMNy6Q33Sp0pTWoKZX3wgvtL9NNkdjEKZvLI+Tc8XJRsc1qWXf6zsMbZQ1jsVe3eFHZPhIIPaEwHAjfkOBuE+FJz76v66gwSMsSFRrAAAAAElFTkSuQmCC" alt="" />
                            <picture />
                            <source type="image/webp" srcset="/static/e52911bbdf2410d7b0cc65a394d00a75/a6da4/why-iz-illustration.webp 214w,
/static/e52911bbdf2410d7b0cc65a394d00a75/ebf9d/why-iz-illustration.webp 428w,
/static/e52911bbdf2410d7b0cc65a394d00a75/15e0a/why-iz-illustration.webp 855w" sizes="(min-width: 855px) 855px, 100vw" />
                            <img decoding="async" width="855" height="538" data-main-image="" style={{ objectFit: 'cover', opacity: '1' }} sizes="(min-width: 855px) 855px, 100vw" loading="lazy" src="https://invozone.com/static/e52911bbdf2410d7b0cc65a394d00a75/15e0a/why-iz-illustration.webp" alt="why ReownLogics" />
                            <picture />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
