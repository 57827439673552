import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { PrevArrow, NextArrow } from './arrows';
import { useState } from 'react';
import './IndustryElements.css'; // Import your CSS file

// import Ecommerce from '../../images/E-Commerce.webp';
// import Fintech from '../../images/Fintech.webp';
// import Healthcare from '../../images/healthcare-2.webp';
import Education from '../../images/Education-2.webp';
import Food from '../../images/foodandgood.webp';
import OnDemand from '../../images/ondemand-2.webp';
// import Blockchain from '../../images/Blockchain.webp';
import Game from '../../images/Gamedevelopment.webp';
import Travel from '../../images/Travel&Tourism.webp';
import Ecommerce from '../../images/E-Commerce Instagram.png';
import Fintech from '../../images/Website Design & Development.png';
import Blockchain from '../../images/Blog Blockchain Technology.png';
import Healthcare from '../../images/Health medical care.png';

function IndustryElements() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    centerMode: true, // Add centerMode
    centerPadding: "0",
    slidesToScroll: 1,
    prevArrow: <PrevArrow />, // Use custom previous arrow component
    nextArrow: <NextArrow />, // Use custom next arrow component
    afterChange: (current) => {
      setCurrentSlide(current);
    },
    responsive:[
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
  };

  const slideContent = [
    {
      title: 'E-Commerce',
      description:
        'Our team of expert software consultants and engineers will analyze your current situation and develop an eCommerce platform that meets all of your needs. We will work with you using the right tools to build an online store that captures more sales and helps you grow faster than ever before.',
    },
    {
      title: 'Fintech',
      description: 'ReownLogics offers consulting services, development for custom software such as digital payment solutions, banking apps, accounting software consulting, etc. We help fintech businesses with all aspects of their operations including strategy development, product design & management, and technical support.',
    },
    {
      title: 'Game Development',
      description: 'Lanista is a Combat system-based game where players fight with different weapons to defeat villains. The game has a detailed character design selection system. Eye-catching VFX and fighting systems are some of its differentiating features.',
    },
    {
      title: 'Healthcare',
      description: 'ReownLogics provides comprehensive healthcare software consulting and development services that include everything from idea generation through development completion including quality assurance testing and consultation and deployment support as part of our value proposition.',
    },
    {
      title: 'Education',
      description: 'ReownLogics builds learning management systems (LMS), virtual classrooms, and other educational solutions using the latest tech stacks. Our consultation and development services cover every step of the process, from project planning to technical implementation.',
    },
    {
      title: 'Food & Groceries',
      description: 'Our website and mobile app consultants have ample experience in creating solutions that support commercial operations like billing, payments processing (online/in-store), order management systems (pickup lists). We can create customized platforms with responsive design your customer will love!',
    },
    {
      title: 'Travel & Tourism',
      description: 'ReownLogics specializes in creating innovative solutions for hotels and travel agencies. We build apps, booking engines, real-time reservation systems, inventory management tools, data visualization dashboards, and more to help our clients increase bookings, boost revenue, and streamline business operations.',
    },
    {
      title: 'On-Demand Services',
      description: 'Taking businesses into the on-demand stride, ReownLogics stands out as one company that helps clients to design and develop on-demand mobile applications to achieve success. We provide you seasoned experts for on-demand app development services to not just sustain but thrive in your industry.',
    },
    {
      title: 'Blockchain',
      description: 'Powered by an eco-friendly polygon network, discover virtual art galleries as well as mint, purchase and bid on NFTs from creators from all over the world. Everscapes posts artwork from global creators, generating revenue worth USD 200,000 via Artefy.io, with reselling worth much more than this for end-users.',
    },
    // Add more content for additional slides
  ];

  return (
    <>
      <section className="Industries-module--section--dd8b5">
        <div className="text-center">
          <h2 className="pb-25 main-heading-36">Industries in Focus</h2>
        </div>
        <div className="container">
          <div className="justify-content-center row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <Slider {...sliderSettings}>
                {slideContent.map((slide, index) => (
                  <div key={index}>
                    <div className="d-flex justify-content-center">
                      <div
                        className={`Industries-module--industryCard--64707 ${currentSlide === index
                          ? 'center-element'
                          : currentSlide === index - 1
                            ? 'left-element'
                            : ''
                          }`}
                      >
                        <img
                          src={
                            index === 0
                              ? Ecommerce
                              : index === 1
                                ? Fintech
                                : index === 2
                                  ? Game
                                  : index === 3
                                    ? Healthcare
                                    : index === 4
                                      ? Education
                                      : index === 5
                                        ? Food
                                        : index === 6
                                          ? Travel
                                          : index === 7
                                            ? OnDemand
                                            : Blockchain
                          }
                          alt={slide.title}
                        />
                        <p className="Industries-module--para--ccdce text-center text-light pt-3">
                          {slide.title}
                        </p>
                        <button type="button" className="btn_white_border btn btn-primary">
                          Learn More
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              <div className="justify-content-center row">
                <div className="col-lg-8 col-md-10 col-12">
                  <p className="pt-5 text-center pt-5">
                    {slideContent[currentSlide].description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default IndustryElements;



