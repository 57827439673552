import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import CustomWeb from '../Fullpages/Custom/CustomWeb'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'

export default function Customweb() {
  return (
    <>
    <Navbar/>
    <CustomWeb/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
