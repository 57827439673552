import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import MobileApp from '../Fullpages/Mobile/MobileApp'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'

export default function Mobile() {
  return (
    <>
    <Navbar/>
    <MobileApp/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
