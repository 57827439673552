import React from 'react'
import "./EducationalSoftware.css";
import { Link } from "react-router-dom";

export default function EducationalSoftware() {
    return (
        <>
            <div class="Banner-module--banner--f6532">
                <div class="container">
                <nav class="mb-4">
                        <Link to={`/`} class="text-white">Home</Link> »
                        <Link to={`/industries`} class="text-white">Industries</Link> » <span class="text-white">Education</span>
                    </nav>
                    <div class="align-items-center row">
                        <div class="col-lg-6 col-md-12">
                            <div class="Banner-module--heading--9593d">
                                <h1 class="Banner-module--bannerHeading--6a2e1">Educational Software Development Services</h1>
                                <p>
                                    <p>Educational software development services have revolutionized the education field by offering increased accessibility, improved engagement &amp; enhanced academic performance. Over 70% of colleges are expected to launch online programs in the coming years.</p>
                                    <p>Our education software development company can help you in either building an education software solution from scratch or scaling your current one.</p>
                                </p>
                            </div>
                            <div class="Banner-module--bannerBtn--0774f">
                                <Link to={`/contact-us`} class="Banner-module--btn_white2_border--fc6d3">Let’s Talk</Link>
                            </div>
                        </div>
                        <div class="Banner-module--ColLeft--a7418 col-lg-6 col-md-12">
                            <div>
                                <img alt="education" src="https://invozone.com/static/education-12e95963732936daf2227c024800c562.png" decoding="async" loading="lazy" class="Banner-module--BannerImage--b19e0" />
                                <img src="https://invozone.com/static/bannerImgMobile-2b308342c84ab6a6f341c7273b98c32a.png" placeholder="blurred" decoding="async" loading="lazy" class="Banner-module--mobImg--db219" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Explore-module--exploreServices--67104">
                <div class="container">
                    <h2 class="Explore-module--heading--7775b">Educational Software Development Services We Offer</h2>
                    <div class="Explore-module--description--72f18">The educational software development services can help you digitize your learning system, enhancing interactivity and engagement, while also reducing cost. Let’s see how our solutions can make a difference.</div>
                    <div class="Explore-module--cardBg--6b09b">
                    </div>
                    <div class="gap-30 Explore-module--cardWrapper--9c789 row">
                        <div class="gap-30 d-flex col-xl-4 col-md-6 col-12">
                            <div class="Explore-module--card--6009a">
                                <img src="https://invozone.com/static/f202151109d917440a1692e22ceef076/learning_management_system_e18d573382.svg" decoding="async" loading="lazy" />
                                <h3>Learning Management System</h3>
                                <p>Integrate educational &amp; training modules with custom, feature-rich, and interactive Learning Management Systems (LMS).</p>
                            </div>
                        </div>
                        <div class="gap-30 d-flex col-xl-4 col-md-6 col-12">
                            <div class="Explore-module--card--6009a">
                                <img src="https://invozone.com/static/c4557508cf62d816c66b17c5e67b22c1/e_learning_application_development_9408a55f45.svg" decoding="async" loading="lazy" />
                                <h3>eLearning Application Development</h3>
                                <p>Get customized eLearning web and mobile apps for your company or educational institution to scale the learning process.</p>
                            </div>
                        </div>
                        <div class="gap-30 d-flex col-xl-4 col-md-6 col-12">
                            <div class="Explore-module--card--6009a">
                                <img src="https://invozone.com/static/4fdcfac85ae8a3906ae7b287a875a252/school_management_system_f1bc4ff649.svg" decoding="async" loading="lazy" />
                                <h3>School Management System</h3>
                                <p>Deploy school management software solutions to ease your administrative burden by digitizing processes such as enrollment &amp; admission etc.</p>
                            </div>
                        </div>
                        <div class="gap-30 d-flex col-xl-4 col-md-6 col-12">
                            <div class="Explore-module--card--6009a">
                                <img src="https://invozone.com/static/6056555097d10d150822e4d75b9d2031/educational_platform_development_e5b942069e.svg" decoding="async" loading="lazy" />
                                <h3>Educational Platform Development</h3>
                                <p>Become a trusted provider of the latest knowledge and new skills by empowering your business with Coursera and Udemy like platforms.</p>
                            </div>
                        </div>
                        <div class="gap-30 d-flex col-xl-4 col-md-6 col-12">
                            <div class="Explore-module--card--6009a">
                                <img src="https://invozone.com/static/8a56d17d94716e64b8f66451cc5392df/software_for_corporate_training_bd206b54aa.svg" decoding="async" loading="lazy" />
                                <h3>Software For Corporate Training</h3>
                                <p>Enhance your employee productivity and increase their skill set efficiently via custom employee training software.</p>
                            </div>
                        </div>
                        <div class="gap-30 d-flex col-xl-4 col-md-6 col-12">
                            <div class="Explore-module--card--6009a">
                                <img src="https://invozone.com/static/fd86abe628591713d29354dd8328a4a3/academic_software_4a5b9226e6.svg" decoding="async" loading="lazy" />
                                <h3>Academic Software</h3>
                                <p>Learn &amp; evaluate your progress to improve the quality of what you are teaching with academic software deployed in your educational institution.</p>
                            </div>
                        </div>
                        <div class="gap-30 d-flex col-xl-4 col-md-6 col-12">
                            <div class="Explore-module--card--6009a">
                                <img src="https://invozone.com/static/a87c8b20ef315eb274c37644a7795756/interactive_learning_software_c07dcbd2f2.svg" decoding="async" loading="lazy" />
                                <h3>Interactive Learning Software</h3>
                                <p>Build a platform like Coursera or Udemy or any customized interactive learning software of your choice to promote virtual learning.</p>
                            </div>
                        </div>
                        <div class="gap-30 d-flex col-xl-4 col-md-6 col-12">
                            <div class="Explore-module--card--6009a">
                                <img src="https://invozone.com/static/adfa9263d1176433466e316ea53f268a/mobile_learning_software_development_b076d04e18.svg" decoding="async" loading="lazy" />
                                <h3>Mobile Learning Software Development</h3>
                                <p>Get mobile learning software to increase the accessibility of your learning and development programs by digitizing them.</p>
                            </div>
                        </div>
                        <div class="gap-30 d-flex col-xl-4 col-md-6 col-12">
                            <div class="Explore-module--card--6009a">
                                <img src="https://invozone.com/static/a02f59d1f9e700e71a9134bf436027d4/virtual_reality_educational_software_d64ddb63e4.svg" decoding="async" loading="lazy" />
                                <h3>Virtual Reality Educational Software</h3>
                                <p>Increase the engagement of your e-learning program with immersive Virtual and Augmented Reality solutions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Solutions-module--hireCyber--a4f4d">
                <div class="container">
                    <div class="gap-30 row">
                        <div class="col-lg-6 col-md-12 col-12 order-lg-1 order-md-2 order-2">
                            <div class="Solutions-module--hire--86235">
                                <h2>Educational Software Solutions To Open Your Horizons</h2>
                                <div class="Solutions-module--description--a063b">
                                </div>
                                <p class="Solutions-module--description--a063b">Educational online software, coupled with the latest technologies, ensures a seamless e-learning process, offering innovative, personalized &amp; cost-effective solutions.<br /> <br /> Some of the solutions available include :</p>
                            </div>
                            <div class="Solutions-module--cards--3f77f">
                                <div class="Solutions-module--talent--9b5ca">
                                    <img class="Solutions-module--tick--1b4b7" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Gamification of Learning" />
                                    <div class="Solutions-module--text--ac319">
                                        <h3>Gamification of Learning</h3>
                                    </div>
                                </div>
                                <div class="Solutions-module--talent--9b5ca">
                                    <img class="Solutions-module--tick--1b4b7" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Utilization of Data to Personalize Learning Process" />
                                    <div class="Solutions-module--text--ac319">
                                        <h3>Utilization of Data to Personalize Learning Process</h3>
                                    </div>
                                </div>
                                <div class="Solutions-module--talent--9b5ca">
                                    <img class="Solutions-module--tick--1b4b7" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Integration of Immersive Learning (VR/AR)" />
                                    <div class="Solutions-module--text--ac319">
                                        <h3>Integration of Immersive Learning (VR/AR)</h3>
                                    </div>
                                </div>
                                <div class="Solutions-module--talent--9b5ca">
                                    <img class="Solutions-module--tick--1b4b7" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="ERP Development" />
                                    <div class="Solutions-module--text--ac319">
                                        <h3>ERP Development</h3>
                                    </div>
                                </div>
                                <div class="Solutions-module--talent--9b5ca">
                                    <img class="Solutions-module--tick--1b4b7" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Performance &amp; Analytics Tools" />
                                    <div class="Solutions-module--text--ac319">
                                        <h3>Performance &amp; Analytics Tools</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="Solutions-module--teamBtn--9586f">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-12 order-lg-2 order-md-1 order-1">
                            <div class="Solutions-module--hire--86235">
                                <img src="https://invozone.com/static/b8b0ce7ee572a26ba22e721b428bae4b/educational_software_solutions_1d850f7bac.svg" decoding="async" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Outsource-module--gotProject--0f72c">
                <div class="container">
                    <div class="Outsource-module--form--9800a">
                        <div class="Outsource-module--projectForm--c664e">
                            <div>
                                <div class="Outsource-module--formData--6a2dd">
                                    <h2>
                                        <b>Empower Your Ideas-Top Education Software Development Services</b>
                                    </h2>
                                    <div class="formContent1">
                                        <div class="row">
                                            <div class="col-md-6 col-12">
                                                <div class="mb-1 form-group">
                                                    <label class="font-weight-normal px-1 form-label" for="name">
                                                    </label>
                                                    <input name="fullName" placeholder="Full Name*" type="text" id="name" class="form-control" value="" />
                                                    <div class="text-left position-absolute invalid-feedback">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <div class="mb-1 form-group">
                                                    <label class="font-weight-normal px-1 form-label" for="name">
                                                    </label>
                                                    <input name="email" placeholder="Email Address*" type="text" id="name" class="form-control" value="" />
                                                    <div class="text-left position-absolute invalid-feedback">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="pt-3 pt-md=0 form-group">
                                                    <label class="font-weight-normal px-1 form-label" for="message">Message <span class="text-danger">*</span></label>
                                                    <textarea rows="7" name="message" placeholder="Drop Us A Message*" id="message" class="form-control">
                                                    </textarea>
                                                    <div class="text-left  invalid-feedback">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="Outsource-module--submitBtn--2fd79">
                                                    <button class="btn_white_border_yellow mt-3 " type="submit">Submit</button>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <p class="m-0 mt-2 w-100 text-left">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="Outsource-module--briefSec--d9b24">
                                <div class="Outsource-module--brief--b61ae">
                                    <h3>We Blend Quality, Technology &amp; Innovation in one place</h3>
                                    <div class="Outsource-module--containerIntro--d5c8d">
                                        <div class="Outsource-module--briefDot--55603">
                                        </div>
                                        <h4>Latest Technology Stacks </h4>
                                        <br />
                                        <p>Our developers are trained in the latest technology stacks &amp; are ready to develop &amp; deliver your edtech solution.</p>
                                    </div>
                                    <div class="Outsource-module--containerIntro--d5c8d">
                                        <div class="Outsource-module--briefDot--55603">
                                        </div>
                                        <h4>Agile Best Practices </h4>
                                        <br />
                                        <p>We staunchly believe in flexibility &amp; adhere to Agile practices by arranging sprints &amp; comprehensive reporting.</p>
                                    </div>
                                    <div class="Outsource-module--containerIntro--d5c8d">
                                        <div class="Outsource-module--briefDot--55603">
                                        </div>
                                        <h4>Data Security </h4>
                                        <br />
                                        <p>Safeguarding your data &amp; ensuring your compliance with applicable regulations is our utmost priority.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="LatestFeatures-module--hireCyber--2fd84">
                <div class="container">
                    <div class="gap-30 align-items-center row">
                        <div class="col-lg-6 col-md-12 col-12 order-lg-1 order-md-2 order-2">
                            <div class="LatestFeatures-module--hire--f97d4">
                                <h2>Get The Latest Features of Educational Software</h2>
                                <div class="LatestFeatures-module--description--deda7">The latest features of educational software can contribute to your growth, meet educational objectives, and help you stand out in the market. Such as:</div>
                            </div>
                            <div class="LatestFeatures-module--cards--a4239">
                                <div class="LatestFeatures-module--talent--593cf">
                                    <img class="LatestFeatures-module--tick--a33c0" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Chatbot Installation For Quick Feedback" />
                                    <div class="LatestFeatures-module--text--e3510">
                                        <h3>Chatbot Installation For Quick Feedback</h3>
                                    </div>
                                </div>
                                <div class="LatestFeatures-module--talent--593cf">
                                    <img class="LatestFeatures-module--tick--a33c0" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Social Networking Features" />
                                    <div class="LatestFeatures-module--text--e3510">
                                        <h3>Social Networking Features</h3>
                                    </div>
                                </div>
                                <div class="LatestFeatures-module--talent--593cf">
                                    <img class="LatestFeatures-module--tick--a33c0" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Rich Collaboration &amp; Communication Facility" />
                                    <div class="LatestFeatures-module--text--e3510">
                                        <h3>Rich Collaboration &amp; Communication Facility</h3>
                                    </div>
                                </div>
                                <div class="LatestFeatures-module--talent--593cf">
                                    <img class="LatestFeatures-module--tick--a33c0" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Alerts &amp; Push Notifications" />
                                    <div class="LatestFeatures-module--text--e3510">
                                        <h3>Alerts &amp; Push Notifications</h3>
                                    </div>
                                </div>
                                <div class="LatestFeatures-module--talent--593cf">
                                    <img class="LatestFeatures-module--tick--a33c0" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Data Transfer &amp; Synchronization" />
                                    <div class="LatestFeatures-module--text--e3510">
                                        <h3>Data Transfer &amp; Synchronization</h3>
                                    </div>
                                </div>
                                <div class="LatestFeatures-module--talent--593cf">
                                    <img class="LatestFeatures-module--tick--a33c0" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="AR &amp; VR inclusion" />
                                    <div class="LatestFeatures-module--text--e3510">
                                        <h3>AR &amp; VR inclusion</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="LatestFeatures-module--subTitle--5acdf">Your project is worth every single feature and functionality that can help you stand apart in the market.</div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-12 order-lg-2 order-md-1 order-1">
                            <div class="LatestFeatures-module--hire--f97d4">
                                <img src="https://invozone.com/static/f05bcf2825dc69873f01ed8d2016c854/latest_features_of_educational_softwares_5a786f19a7.svg" decoding="async" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="HireDevelopers-module--hireCyberDeveloper--ebacb">
                <div class="container">
                    <div class="gap-30 align-items-center row">
                        <div class="col-lg-6 col-md-12 col-12 order-lg-2 order-md-2 order-2">
                            <div class=" undefined HireDevelopers-module--hire--e268a">
                                <h2>Hire Our Software Developers</h2>
                                <div class="HireDevelopers-module--description--9c146">Every educational software solution requires dynamic and experienced developers possessing qualities such as:</div>
                            </div>
                            <div class="HireDevelopers-module--cards--80fe2">
                                <div class="HireDevelopers-module--talent--389f4">
                                    <img class="HireDevelopers-module--tick--f298d" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Knowledge of Appropriate Frameworks &amp; Programming Languages." />
                                    <div class="HireDevelopers-module--text--fdb6b">
                                        <h3>Knowledge of Appropriate Frameworks &amp; Programming Languages.</h3>
                                    </div>
                                </div>
                                <div class="HireDevelopers-module--talent--389f4">
                                    <img class="HireDevelopers-module--tick--f298d" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="Flexibility, Productivity, Quick Learning, And Adaptability" />
                                    <div class="HireDevelopers-module--text--fdb6b">
                                        <h3>Flexibility, Productivity, Quick Learning, And Adaptability</h3>
                                    </div>
                                </div>
                                <div class="HireDevelopers-module--talent--389f4">
                                    <img class="HireDevelopers-module--tick--f298d" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALESURBVHgBxVVfSFNRGP/du/ln0p+raJkwXIrWQ4T4pwwEM0yX+LDApyI2n6pVBCH4VttDUJJowaJeYg+9imv0z03ZXnoQKg3zoUE1GSxosAYJLaas79xtd/fOTa9T6DfOzvl+55zf951zzzkfh83wEpUoxQ1w6ECSCnBQ5DmEyQ5Qy4V1vMA5BAtJcHnZGeip5yG1zkMdnOTIns/RRgceXCb2HkUoYHuIULmLPjEwCbxiiBd36P9JEeIMNVQmMYvbcjK7gre4Se4msTu4Qit5mnXwBgZosVBk5PkQI63j6EcotUUa2HYiztGvS+iCQWfIUAJR91N9LHoNvqNICCUCvK1etO9rRzQRReO7RsQSsVSnDlU8iV9AkWAR+9p8ojhDVUkVWva2ZAfEYdVSdRZFgInPtc2hQdcgcVM/p+CP+rODkjjB0yYdk0+06q2w1Fm2FGeRy8WdYSeGPg3lDu3QkpfqjMWEHUcdYru7shvDy8MbxJsrmuFp9aBeVy9x4yvjGAmMIA8OKS5aXWkd5M4mjkwoRrP9nT85rxC3f7UXEhehwSVcpXoPM5ZWl2CsNqK2rFbs7NzfCY7j4P/lh+mACdMt0xC0gkLc9s2GTRDm6O3xUeN0htGX68WP11TRJI1yRVwYrB6EltNK3GhgFGMrY9gCfp6+gV/OhOIh9H3sQzAelDhTjUkhblm2qBFnt8xd8KKJJ6XdB0O5QeJiazGYP5vhjrihCus4zKff8Ne5fcE/QfS870H4b1i0I4mIaKsWZzmCtPm0p2sswHxOBhYGsPh7Eb0fesVaFZKIigkI8ud6BlayHNgN8PT09+JRqplBPx6TZzt2CqaRFmfQKDqf04m6iFVqnaLVlGF7iNGcW5RoHsjJ/EmfnSyecgQHM9SAwyus4bq6pJ/riN114AwV9iim3q0kftDML1TPUnkGI9n/C/8A0QbHLM5U28AAAAAASUVORK5CYII=" alt="24/7 Availability and Strong Communication Skills" />
                                    <div class="HireDevelopers-module--text--fdb6b">
                                        <h3>24/7 Availability and Strong Communication Skills</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="HireDevelopers-module--bottomDes--193e3" style={{paddingTop: '15px', fontSize: '16px', fontWeight: '400', lineHeight: '26px'}}>Our educational software developers have everything it takes to build robust educational software development.</div>
                            {/* <div class="HireDevelopers-module--teamBtn--f7ffe">
                                <a class="HireDevelopers-module--btn--cf989" href="/dedicated-development-team/">Hire Dedicated Developers</a>
                            </div> */}
                        </div>
                        <div class="col-lg-6 col-md-12 col-12 order-lg-1 order-md-1 order-1">
                            <div class="HireDevelopers-module--hire--e268a">
                                <img src="https://invozone.com/static/f93177ab54da41a96fce1e88552eac92/hire_educational_software_developer_e19d4fc4c0.svg" decoding="async" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
