import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import SoftwareQualityAssurance from '../Fullpages/SoftwareQuality/SoftwareQualityAssurance'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'

export default function WebAppDevelopment() {
  return (
    <>
    <Navbar/>
    <SoftwareQualityAssurance/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
