import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import Career from '../Fullpages/Careers/Career'
export default function Careers() {
  return (
    <>
    <Navbar/>
    <Career/>
    <Footer/>
    </>
  )
}
