import React, { useState, useEffect } from 'react';
import Slider from 'react-slick'; // Import react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './WorkPortfolio.css';
import nolimitlogos from '../../images/nolimitlogos.png';
import bonafide from '../../images/bonafide.png';
import inbaku2 from '../../images/inbaku2.png';
import { PrevArrow } from './arrow';
import { NextArrow } from './arrow';

export default function WorkPortfolio() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slideItems = [
    {
      image: nolimitlogos,
      title: 'No Limit Logos',
      description: 'Helping create brands daily, worldwide. Thousands of custom logo designs created for businesses and ventures. Our numbers keep climbing.',
      link: 'https://nolimitlogos.com',
    },
    {
      image: bonafide,
      title: 'Bona Fide',
      description: 'It provided its services within two main business streams: event management and marketing solutions. Through a team of multidisciplinary, expertise and nationalities working efficiently. BONA FIDE is committed to developing an integrated model through which it became a local model for the brands in the field.',
      link: 'https://nolimitlogos.com',
    },
    {
      image: inbaku2,
      title: 'InbakuTravels',
      description: 'At InbakuTravels, we believe that the journey is just as important as the destination. Our team of experienced travel professionals is committed to crafting seamless and personalized journeys that exceed your expectations.',
      link: 'https://inbakutravels.com/',
    },
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Auto slide every 3 seconds
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === slideItems.length - 1 ? 0 : prevIndex + 1));
    }, 3000); // Auto slide every 3 seconds

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex, slideItems]);

  const handleButtonClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <section className="WorkPortfolio pb-5" style={{ background: 'rgb(242, 242, 242)' }}>
      <div className="WorkPortfolio-module--portfol--09372">
        <div className="container">
          <div className="row">
            <div className="WorkPortfolio-module--portContent--38182">
              <h2 className="main-heading-36">Our Software Development Portfolio</h2>
              <p className="undefined">
                Transform your vision into reality and drive exemplary results through ReownLogic's dynamic engagement models.
              </p>
            </div>
          </div>
          {/* <ul className="button__bar" style={{ display: 'block' }}>
            {slideItems.map((_, index) => (
              <li key={index} className={currentIndex === index ? 'slick-active' : ''}>
                <button onClick={() => handleButtonClick(index)}>{index + 1}</button>
              </li>
            ))}
          </ul> */}
          <Slider {...settings}> {/* Use the Slider component */}
            {slideItems.map((item, index) => (
              <div key={index}>
                <div className="row">
                  <div className="mt-5 mt-md-0 col-md-6" style={{ padding: '70px' }}>
                    <img src={item.image} alt={item.title} />
                  </div>
                  <div className="pt-md-5 mt-md-5 col-md-6">
                    <h3 className="pt-md-3 text-20 simple-font-weight">{item.title}</h3>
                    <div className="my-md-4 pb-3">{item.description}</div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          
        </div>
      </div>
    </section>
  );
}
