import React from 'react'
import "./About.css";
import abdullahaziz from '../../images/abdullahaziz.png'
import office from '../../images/office.png'
import performance from '../../images/performance.png'
import office2 from '../../images/office2.png'
import office3 from '../../images/office3.png'
import office4 from '../../images/office4.jpg'
import { Link } from 'react-router-dom';

export default function About() {
    return (
        <>
            <div class="Banner-module--careers--c8ceb">
                <div class="container">
                    <div class="justify-content-center row">
                        <div class="col-xl-6 col-lg-12">
                            <div class="Banner-module--bannrtText--d1da1">
                                <h1 class="main-banner-heading-55">Accelerate Your Business Growth With Us</h1>
                                <div class="font-weight-normal pt-2">
                                    <p>
                                        <p>Your business is only as good as the technology behind it. We have the expertise. You have the vision. We make technology happen! Our team of world-class engineers and innovators will build your next software project, leaving you to focus on running your business.</p>
                                        <p>
                                            <strong>Got an idea? Bring it to life with ReownLogic's!</strong>
                                        </p>
                                    </p>
                                </div>
                                <div class="Banner-module--bannerBtn--6c461">
                                    <Link to={`/contact-us`} class="Banner-module--btn--94e64">Step Into Innovation</Link>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 mt-md-0 pl-md-5 text-center col-xl-6 col-lg-12">
                            <img src={performance} className='performanceimg'/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="CeoMessage-module--CeoMessage--4d1e2">
                <div class="container">
                    <div>
                        <div class="CeoMessage-module--message--6449a">
                            <div class="row">
                                <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 order-xl-1 order-lg-2 order-md-2 order-sm-2 order-2">
                                    <div class="CeoMessage-module--text--65aef">
                                        <h2>Message From The CEO</h2>
                                        <p>
                                            <p>Hey Everyone,</p>
                                            <p>Welcome to ReownLogics, your trusted partner for software development and consultancy. I'm Abdullah Aziz, the CEO of this esteemed company, and I'm excited to share our story and vision with you.</p>
                                            <p>At ReownLogics, we believe in the power of software to transform industries and unlock boundless possibilities. Our team of exceptional professionals, specializing in technologies like Elixir, Laravel, ROR, AI/ML,and more, is here to drive your digital transformation journey.</p>
                                            <p>We go beyond being just developers; we're your strategic partners. We tailor solutions to ensure your success, foster long-lasting partnerships, and deliver top-notch software. Our innovative mindset keeps us at the forefront of the ever-evolving tech landscape.</p>
                                            <p>Integrity and transparency are the cornerstones of our culture. We earn your trust by delivering excellence and practicing open communication. Moreover, we actively support social causes and embrace sustainable practices to make a positive impact on our world.</p>
                                            <p>Let's shape the future together through technology. Choose ReownLogics for exceptional results and leverage our resource augmentation or remote teams of software developers.</p>
                                            <p>Join us on this exciting journey, and let's make a difference together.</p>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 order-xl-2 order-lg-1 order-md-1 order-sm-1 order-1 d-flex justify-content-center">
                                    <div class="CeoMessage-module--cta--62b0f">
                                        <img src={abdullahaziz} decoding="async" loading="lazy" alt="adbullah-aziz-profile" />
                                        <p class="CeoMessage-module--Furqan--6d9af">Abdullah Aziz</p>
                                        <p>CEO of ReownLogics</p>
                                        <div class="CeoMessage-module--socialBtn--a6356">
                                            <Link to={`https://www.linkedin.com/in/abdullah-aziz-872140217/`} class="btn btn-primary" target="_blank" rel="noopener noreferrer">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z">
                                                </path>
                                                </svg>
                                            </Link>
                                            <Link to={`mailto:abdullah@reownlogics.com`} class="btn btn-primary " target="_blank" rel="noopener noreferrer">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="none" d="M0 0h24v24H0z">
                                                    </path>
                                                    <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z">
                                                    </path>
                                                </svg>
                                            </Link>
                                            {/* <a class="btn btn-primary " href="" target="_blank" rel="noopener noreferrer">
                                                <img class="CeoMessage-module--socialIcons--b75b2" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzMxNjhfODg1NCkiPgo8cGF0aCBkPSJNNC4wNTg0IDVMMTMuMzI0NSAxNy4xMzQzTDQgMjdINi4wOTg3NEwxNC4yNjI1IDE4LjM2MjJMMjAuODU4NCAyN0gyOEwxOC4yMTIzIDE0LjE4MzNMMjYuODkxNiA1SDI0Ljc5MjlMMTcuMjc0NyAxMi45NTVMMTEuMiA1SDQuMDU4NFpNNy4xNDQ2OSA2LjUxMzkySDEwLjQyNTVMMjQuOTEzMyAyNS40ODYxSDIxLjYzMjVMNy4xNDQ2OSA2LjUxMzkyWiIgZmlsbD0iIzIxNzZGRiIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzMxNjhfODg1NCI+CjxyZWN0IHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K" alt="twitter" loading="lazy" decoding="async" />
                                            </a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="CeoMessage-module--inspiring--ba305">
                            <div class="row">
                                <div class="col-xl-6">
                                    <p class="CeoMessage-module--inspirHeading2--9d27e">Our Inspiring Vision</p>
                                    <img class="CeoMessage-module--missionImg--c61a5" src={office} decoding="async" loading="lazy" alt="our_inspiring_vision" />
                                </div>
                                <div class="col-xl-6">
                                    <div class="CeoMessage-module--inspirinText--1cc65">
                                        <h2 class="CeoMessage-module--inspirHeading--3ac98">Our Inspiring Vision</h2>
                                        <p>
                                            <p>We envision a future where innovation knows no bounds, where groundbreaking ideas transform industries, and where technological advancements create positive change. We strive to create disruptive AI-driven solutions that redefine the landscape of the digital world.</p>
                                            <p>With unwavering determination, we leverage the power of our collective imagination to shape a world filled with endless possibilities. Together, we dare to dream, innovate fearlessly, and pave the way for a brighter tomorrow.</p>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="CeoMessage-module--inspirinText2--73140">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 order-xl-1 order-lg-2 order-md-2 order-sm-2 order-2">
                                        <div class="CeoMessage-module--inspirinText--1cc65">
                                            <h2 class="CeoMessage-module--inspirHeading--3ac98">Our Impactful Mission</h2>
                                            <p>
                                                <p>At the core of our mission lies empowering businesses with transformative technology solutions. Through our proficiency in custom software development, Cloud Services, AI/ML/AL, DevOps, and cybersecurity, we craft personalized solutions that propel growth, optimize operations, and spur innovation.</p>
                                                <p>By prioritizing our clients' needs, we consistently surpass expectations, nurturing enduring partnerships grounded in trust and mutual collaboration. Join us in shaping the future of business.</p>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 order-xl-2 order-lg-1 order-md-1 order-sm-1 order-1">
                                        <p class="CeoMessage-module--inspirHeading2--9d27e">Our Impactful Mission</p>
                                        <img class="CeoMessage-module--missionImg--c61a5" src={office} decoding="async" loading="lazy" alt="Our_Impactful_Mission" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}
