import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'
import SEOServices from '../Fullpages/SEOServices/SEOServices'

export default function SEO() {
  return (
    <>
    <Navbar/>
    <SEOServices/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
