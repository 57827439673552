import React, { useState } from 'react';

const FaqItem = ({ index, question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="card border-light" style={{ overflow: 'visible' }}>
      <div className="card-header" onClick={toggleAccordion}>
        <div className="d-flex justify-content-between align-items-center">
          <div className={isOpen ? 'Faqs2-module--quesColorSelected--49a56' : 'Faqs2-module--quesColor--4b2cd'}>
            <div className="Faqs2-module--faqsNum--44993">
              <span className="Faqs2-module--serial2--88c80">0{index}:</span>
              <span className="Faqs2-module--serial--98a5e">0{index}</span>
              <h3 className="Faqs2-module--faqsSub--2f843 py-2 mb-0" type="button">
                {question}
              </h3>
            </div>
          </div>
          <div type="button" className="Faqs2-module--faqButton--5e66d">
            {isOpen ? (
              <img
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MCA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMTIuMDAxIiB5PSIxOS4wNDk4IiB3aWR0aD0iMTYiIGhlaWdodD0iMiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="
                alt="Toggle Icon"
                className="Faqs2-module--quesColorSelected--49a56"
              />
            ) : (
              <img
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MCA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwLjc3MjIgMjcuNjk2M0MyMC41OTE2IDI3Ljg3NjggMjAuMzYyNCAyNy45NjcxIDIwLjA4NDcgMjcuOTY3MUMxOS44MzQ3IDI3Ljk2NzEgMTkuNjE5NCAyNy44NzY4IDE5LjQzODggMjcuNjk2M0MxOS4yNTgzIDI3LjUxNTcgMTkuMTY4IDI3LjMwMDUgMTkuMTY4IDI3LjA1MDVWMjEuMTMzOEgxMy4wNDNDMTIuNzY1MiAyMS4xMzM4IDEyLjUzNiAyMS4wNDM1IDEyLjM1NTUgMjAuODYzQzEyLjE3NDkgMjAuNjgyNCAxMi4wODQ3IDIwLjQ1MzIgMTIuMDg0NyAyMC4xNzU1QzEyLjA4NDcgMTkuOTI1NSAxMi4xNzQ5IDE5LjcwMzIgMTIuMzU1NSAxOS41MDg4QzEyLjUzNiAxOS4zMTQzIDEyLjc1MTMgMTkuMjE3MSAxMy4wMDEzIDE5LjIxNzFIMTkuMTY4VjEzLjA5MjFDMTkuMTY4IDEyLjgxNDMgMTkuMjUxMyAxMi41ODUyIDE5LjQxOCAxMi40MDQ2QzE5LjU4NDcgMTIuMjI0MSAxOS44MDY5IDEyLjEzMzggMjAuMDg0NyAxMi4xMzM4QzIwLjM2MjQgMTIuMTMzOCAyMC41OTE2IDEyLjIyNDEgMjAuNzcyMiAxMi40MDQ2QzIwLjk1MjcgMTIuNTg1MiAyMS4wNDMgMTIuODAwNSAyMS4wNDMgMTMuMDUwNVYxOS4yMTcxSDI2Ljk1OTdDMjcuMjM3NCAxOS4yMTcxIDI3LjQ2NjYgMTkuMzA3NCAyNy42NDcyIDE5LjQ4OEMyNy44Mjc3IDE5LjY2ODUgMjcuOTE4IDE5Ljg5NzcgMjcuOTE4IDIwLjE3NTVDMjcuOTE4IDIwLjQyNTUgMjcuODI3NyAyMC42NDc3IDI3LjY0NzIgMjAuODQyMUMyNy40NjY2IDIxLjAzNjYgMjcuMjUxMyAyMS4xMzM4IDI3LjAwMTMgMjEuMTMzOEgyMS4wNDNWMjcuMDA4OEMyMS4wNDMgMjcuMjg2NiAyMC45NTI3IDI3LjUxNTcgMjAuNzcyMiAyNy42OTYzWiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="
                alt="Toggle Icon"
                className="Faqs2-module--quesColor--4b2cd"
              />
            )}
          </div>
        </div>
      </div>
      <div className={`collapse ${isOpen ? 'show' : ''}`}>
        <div className="Faqs2-module--textColor--e10b6 card-body">
          <div>
            <p>{answer}</p>
          </div>
        </div>
      </div>
      <hr style={{ margin: '0px' }} />
    </div>
  );
};

const FaqList = () => {
  const faqs = [
    {
      question: 'What is software development?',
      answer: 'A collection of computer science activities dedicated to the process of producing, designing, deploying, and maintaining software is referred to as software development.',
    },
    {
      question: 'Why should you outsource software development services from ReownLogics?',
      answer: 'Our experience as a leading software development service provider spans all verticals...',
    },
    {
      question: 'How can you hire software developers?',
      answer: 'You can hire top software developers from ReownLogics through our wallet-friendly engagement models, such as staff augmentation, dedicated teams and fixed gigs. Contact us today to explore our models in detail.',
    },
    {
      question: 'How long does it take to develop software?',
      answer: 'On average, software projects take 4 to 6 months to design and develop. However, the time frame varies depending on project scope, budget, timeline, sprints, tech stacks, resources and other factors.',
    },
    {
      question: 'How is software development cost calculated?',
      answer: 'We calculate software development costs by employing agile estimation techniques such as PERT, Expert judgment etc.',
    },
    {
      question: 'What is the software development life cycle?',
      answer: 'The software development life cycle consists of 8 stages, i.e., identifying the project team, project planning, requirement analysis, design, development, testing, deployment and maintenance. At ReownLogics, our SDLC empowers you to contribute to the project effectively.',
    },
    {
      question: 'In which areas is ReownLogics serving?',
      answer: 'ReownLogics offers full cycle software development services globally.',
    },
  ];

  return (
    <div className="faqsAccordian">
      <div className="accordion">
        {faqs.map((faq, index) => (
          <FaqItem key={index} index={index + 1} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

const Faq = () => {
  return (
    <div className="Faqs2-module--section--b0065">
      <div className="container">
        <h2 className="Faqs2-module--head--b1ec9 text-center">Frequently Asked Questions </h2>
        <div className="row">
          <div className="col-lg-12">
            <FaqList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
