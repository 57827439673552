import React from 'react'
import "./LetUsKnow.css";
import maira from '../../images/maira.jpg'
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { Link } from "react-router-dom";

export default function LetUsKnow() {

    return (
        <section class="ContactSales-module--section--afe0d">
            <div class="container">
                <div style={{ minWidth: '500px', position: 'absolute', top: '0', right: '0', zIndex: 0 }}>
                    {/* <img decoding="async" loading="lazy" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1NTYgNTQzIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1NTYgNTQzOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0YwRjBGMDt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yMDguOSw1NDIuNWMtNywwLTE0LDAtMjEsMGMtMC44LTAuMy0xLjUtMC42LTIuMy0wLjdjLTktMS4yLTE4LjItMS43LTI3LjEtMy42CglDNTMuNSw1MTUuNC0xMy42LDQxOC4xLDMuMiwzMTIuNWM4LjUtNTMuMiwzNC4zLTk2LjcsNzgtMTI3LjljODYtNjEuNCwxNzIuOS0xMjEuNSwyNTkuNS0xODIuMWMyLTEuNCw0LjktMS45LDcuNC0xLjkKCWM2OS0wLjEsMTM4LjEtMC4xLDIwNy4xLTAuMWMwLDExMi4xLDAsMjI0LjMsMCwzMzYuNGMtMS40LDAuOC0yLjksMS42LTQuMiwyLjVjLTc4LjgsNTUuMi0xNTcuNiwxMTAuMy0yMzYuMywxNjUuNQoJYy0yMy45LDE2LjgtNTAsMjguNi03OC45LDMzLjhDMjI3LDU0MC4zLDIxOCw1NDEuMiwyMDguOSw1NDIuNXoiLz4KPC9zdmc+Cg==" alt="sales" width="100%"/> */}
                </div>
                <div class="justify-content-center row" style={{ zIndex: '1' }}>
                    <div class="text-center col-lg-7 col-md-8">
                        <h2 class="main-heading-36">Let Us Know What You’re Looking for,<br />We’ll Build it for You</h2>
                    </div>
                </div>
                <div class="mt-5 row">
                    <div class="ContactSales-module--head--f7f47 mx-auto col-lg-6 col-md-12">
                        <h3 class="ContactSales-module--sales--8cf0a pt-2 pt-md-4 ">Talk to our Sales Team</h3>
                        <div class="ContactSales-module--personCard--00251">
                            <div data-gatsby-image-wrapper="" class="saleimage gatsby-image-wrapper gatsby-image-wrapper-constrained">
                                <div style={{ maxWidth: '131px', display: 'block' }}>
                                    <img alt="" role="presentation" aria-hidden="true" src="data:image/svg+xml;charset=utf-8,%3Csvg height='128' width='131' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E" style={{ maxWidth: '100%', display: 'block', position: 'static' }} />
                                </div>
                                <img aria-hidden="true" data-placeholder-image="" style={{ opacity: '0', transition: 'opacity 500ms linear 0s', objectFit: 'cover' }} decoding="async" src="data:image/webp;base64,UklGRvoBAABXRUJQVlA4WAoAAAAQAAAAEwAAEwAAQUxQSJkAAAABgFvb1rLo4g4NUAEteOoe0gAZuTtE7jDTgFfgqftk7u4uON9XQkRMAHoF7on9j9/H1bqVj4GOw9bgUy+vh5H8bw29qQAQaJFeyKH+JWrVmEst8lNti+Jvkcb9Ko3FJxq+fwrv8ncKGeyRncowSvRnAPREaQCsdYJxFgDo/oaa5qNvZIj/Agf9mYnfficuDKscu/p7mPGIMRAAVlA4IDoBAACwBgCdASoUABQAPm0wkkckIqGhKAqogA2JbACdMtU5n6bTG8ADbO3cAOSKRvrcqMy2KPJqq99M09vlcCbMAAD681hRS+C4v489xPbNsRHrBn3cucf17HmcebMa8q+IklAVitu97wSwG4iGiXpxr5DH+9Vm9/01Si6TaxUCKqNc72cEgbvv8LAKuodCdJwEGNlPQxnqzfFFZMxMFpzG16Zlm5fh4kXbmk6f75uUECoDUxlo6NCeMLDNQ3rjF2BywQ1p0V9YARB2tmi3Kqo/cBv5zD8xj7W/822bshoGw59Xb3uazIFUdMVx8cw0XOZVhttiujpaJ/NQhxH9YCSSB88fcdsfbgdLzuRnmPb8GabH02dR9oMsbl51JVHt/EIxAa76dEuUEGXJ/zpxpjomLvTh7b/8CTIjr+YAAA==" alt="" />
                                <img decoding="async" width="131" height="128" data-main-image="" style={{ objectFit: 'cover', opacity: '1', borderRadius: '50px' }} sizes="(min-width: 131px) 131px, 100vw" loading="lazy" src={maira} alt="Maira Hussain" />
                            </div>
                            <div class="ml-4 pt-4"><p class="text-20 simple-font-weight mb-0">Maira Hussain</p><div>Head of Sales</div>
                                <div class="ContactSales-module--contactIcons--31b38 mt-3">
                                    <Link to={`mailto:hello@reownlogics.com`} aria-label="Email him">
                                        <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNTEyIiB3aWR0aD0iNTEyIj48cmVjdCB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgcng9IjE1JSIgZmlsbD0iI2ZmZiIvPjxyZWN0IHdpZHRoPSIzNjAuMDQxIiBoZWlnaHQ9IjI2Ny4wNDkiIHg9Ijc1Ljc3NyIgeT0iMTIyLjU2MyIgcnk9IjI1LjMyIiBmaWxsPSIjZDU0YzNmIi8+PHBhdGggZmlsbD0iI2VhZWFlYSIgZD0iTTEyNC41IDEyMy44M2gyNzQuNDRWMzg5LjZIMTI0LjV6Ii8+PHBhdGggZD0iTTIzMy4zNiAzMDQuOUwxMjQuNSAzODkuNmwuMDA4LTE5Mi41MTIgMTA4Ljg0IDEwNy44MDIiIGZpbGw9IiNlM2UzZTMiLz48cGF0aCBkPSJNMzE3LjMyNyAzODkuNjM2TDkyLjE3IDE2NC41NWwzMDcuNjg3LTcuMzA1LS45MjUgMjMyLjM2NHoiIG9wYWNpdHk9Ii4xIi8+PHBhdGggZD0iTTk4LjQyNyAxMjIuNjZMMjYwLjggMjQyLjdsMTQ5LjQtMTIweiIgZmlsbD0iI2VmZWZlZiIvPjxwYXRoIGQ9Ik00MjYuNyAxNjIuOWEyMiAyMiAwIDEgMC0yNS41LTM2LjVMMjU4LjEgMjMwLjVsLS4wMTUuMS0uMDItLjAwN0wxMTUgMTI2LjQwNWEyMi4zIDIyIDAgMCAwLTI1LjUgMzYuNWwtLjU5My0uNCAxNjkuMiAxMjMuMm0xNjkuMjItMTIzLjJsLTE3MCAxMjQiIGZpbGw9IiNkZTUxNDUiLz48L3N2Zz4=" color="#f14336" decoding="async" loading="lazy" alt="gmail" />
                                    </Link>
                                    <a href="https://www.linkedin.com/company/reownlogics/mycompany/" target="_blank" rel="noreferrer noopener nofollow" aria-label="View LinkedIn profile">
                                        <img
                                            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmZmYiIGhlaWdodD0iNTEyIiB3aWR0aD0iNTEyIj48cmVjdCB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgZmlsbD0iIzAwNzdiNSIgcng9IjE1JSIvPjxjaXJjbGUgY3g9IjE0MiIgY3k9IjEzOCIgcj0iMzciLz48cGF0aCBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iNjYiIGQ9Ik0yNDQgMTk0djE5OE0xNDIgMTk0djE5OCIvPjxwYXRoIGQ9Ik0yNzYgMjgyYzAtMjAgMTMtNDAgMzYtNDAgMjQgMCAzMyAxOCAzMyA0NXYxMDVoNjZWMjc5YzAtNjEtMzItODktNzYtODktMzQgMC01MSAxOS01OSAzMiIvPjwvc3ZnPg=="
                                            color="#0073B1"
                                            decoding="async"
                                            loading="lazy"
                                            alt="LinkedIn"
                                        />
                                    </a>
                                    {/* <a href="" aria-label="Contact him on WhatsApp" target="_blank" rel="noreferrer">
                                        <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmZmYiIGhlaWdodD0iNTEyIiB3aWR0aD0iNTEyIj48cmVjdCB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgZmlsbD0iIzQ1ZDM1NCIgcng9IjE1JSIvPjxwYXRoIGQ9Ik0zMDggMjczYy0zLTItNi0zLTkgMWwtMTIgMTZjLTMgMi01IDMtOSAxLTE1LTgtMzYtMTctNTQtNDctMS00IDEtNiAzLThsOS0xNGMyLTIgMS00IDAtNmwtMTItMjljLTMtOC02LTctOS03aC04Yy0yIDAtNiAxLTEwIDUtMjIgMjItMTMgNTMgMyA3MyAzIDQgMjMgNDAgNjYgNTkgMzIgMTQgMzkgMTIgNDggMTAgMTEtMSAyMi0xMCAyNy0xOSAxLTMgNi0xNiAyLTE4bS03OSA5NGMtNDEgMC03Mi0yMi03Mi0yMmwtNDkgMTMgMTItNDhzLTIwLTMxLTIwLTcwYzAtNzIgNTktMTMyIDEzMi0xMzIgNjggMCAxMjYgNTMgMTI2IDEyNyAwIDcyLTU4IDEzMS0xMjkgMTMybS0xNTkgMjlsODMtMjNhMTU4IDE1OCAwIDAgMCAyMzAtMTQwYzAtODYtNjgtMTU1LTE1NC0xNTVhMTU4IDE1OCAwIDAgMC0xMzcgMjM2Ii8+PC9zdmc+" color="#3dbc4d" decoding="async" loading="lazy" alt="whatsapp" />
                                    </a> */}
                                    {/* <a href="" aria-label="Schedule a meeting with calendly" target="_blank" rel="noreferrer">
                                        <img src="data:image/svg+xml;base64,
                                            PHN2ZyB3aWR0aD0iMTUwIiBoZWlnaHQ9IjE1MCIgdmlld0JveD0iMCAwIDE1MCAxNTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF82NjEzXzIwNTcwKSI
                                            +CjxyZWN0IHdpZHRoPSIxNTAiIGhlaWdodD0iMTUwIiByeD0iMTIiIGZpbGw9IiMwMDZCRkYiLz4KPHBhdGggZD0iTTEzNS4xNzMgODUuNzAzMUMxMzIuNTA5IDgzLjc1NTUgMTI3Lj
                                            A4NSA4MS4zMzUzIDEyMi41MDYgNzkuNzg0OEMxMjIuOTggNzYuODA2OCAxMjMuMTUgNzMuMDcyNCAxMjIuNDIgNjguNzUxOUgxMjIuNDAxVjY4LjY0NzlIMTIyLjQyVjY4Ljc1MTlDM
                                            TI3LjAyOCA2Ny45MiAxMzEuMzkgNjYuMDU3NSAxMzUuMTczIDYzLjI5NjlDMTM4LjgwNSA2MC42MTIgMTM4LjEwMyA1Ny41ODY2IDEzNy41NTMgNTUuNzkwNEMxMzMuMDU5IDQxLjI1
                                            OTQgMTIzLjQ4MiAyOC44MjczIDExMC41NzcgMjAuNzM0NkM5Ny42NzI3IDEyLjY1MTQgODIuMjc0MiA5LjQ0NjQ3IDY3LjE5ODEgMTEuNzA2QzUyLjEyMiAxMy45NzUgMzguMzYzOSA
                                            yMS41NTcxIDI4LjQxNzUgMzMuMDgxN0MxOC40NzEgNDQuNTk2NyAxMyA1OS4yOTc4IDEzIDc0LjVDMTMgODkuNzAyMiAxOC40NzEgMTA0LjQwMyAyOC40MTc1IDExNS45MThDMzguMz
                                            YzOSAxMjcuNDQzIDUyLjEyMiAxMzUuMDI1IDY3LjE5ODEgMTM3LjI5NEM4Mi4yNzQyIDEzOS41NTQgOTcuNjcyNyAxMzYuMzQ5IDExMC41NzcgMTI4LjI2NUMxMjMuNDgyIDEyMC4xN
                                            zMgMTMzLjA1OSAxMDcuNzQxIDEzNy41NTMgOTMuMjA5NkMxMzguMTAzIDkxLjQxMzMgMTM4LjgwNSA4OC4zODggMTM1LjE3MyA4NS43MDMxWk0xMzEuMDc3IDkxLjIxNDhDMTI3LjA2
                                            NiAxMDQuMTc2IDExOC41MjMgMTE1LjI2NiAxMDcuMDEyIDEyMi40NzlDOTUuNDkxOSAxMjkuNjkzIDgxLjc2MjIgMTMyLjUzOSA2OC4zMTcgMTMwLjUxNUM1NC44NjIzIDEyOC40OTI
                                            gNDIuNTkyOCAxMjEuNzMzIDMzLjcxNzggMTExLjQ0N0MyNC44NTIzIDEwMS4xNyAxOS45NjkxIDg4LjA1NzEgMTkuOTY5MSA3NC41QzE5Ljk2OTEgNjAuOTQyOCAyNC44NTIzIDQ3Lj
                                            gzIDMzLjcxNzggMzcuNTUzNUM0Mi41OTI4IDI3LjI2NzQgNTQuODYyMyAyMC41MDc3IDY4LjMxNyAxOC40ODQ2QzgxLjc2MjIgMTYuNDYxNCA5NS40OTE5IDE5LjMwNzEgMTA3LjAxM
                                            iAyNi41MjA1QzExOC41MjMgMzMuNzM0IDEyNy4wNjYgNDQuODIzNiAxMzEuMDc3IDU3Ljc4NTJDMTMxLjA3NyA1Ny44MTM1IDEzMS4wOTYgNTcuODUxNCAxMzEuMTE1IDU3Ljg3OTdD
                                            MTI5LjE1MiA1OS4yOTc4IDEyNC42MTEgNjAuOTk5NiAxMjAuNTUyIDYyLjIwMDJMMTIwLjUxNCA2Mi4xMjQ2QzExOS45MzYgNjAuNjk3IDExOS4yNjMgNTkuMjk3OCAxMTguNDg1IDU
                                            3Ljk2NDhMMTEyLjAyOCA0Ni44MDlDMTA5LjExNyA0MS43ODg5IDEwNC45MzYgMzcuNjEwMiA5OS44OTE1IDM0LjcwNzhDOTQuODQ3MSAzMS44MDU0IDg5LjEyOTYgMzAuMjczOCA4My
                                            4zMDc3IDMwLjI3MzhINzAuMzg0QzY0LjU2MjIgMzAuMjczOCA1OC44NDQ2IDMxLjgwNTQgNTMuODAwMyAzNC43MDc4QzQ4Ljc2NTUgMzcuNjEwMiA0NC41NzQ1IDQxLjc4ODkgNDEuN
                                            jYzNiA0Ni44MDlMMzUuMjA2NCA1Ny45NjQ4QzMyLjI5NTUgNjIuOTk0NCAzMC43NTk1IDY4LjY5NTIgMzAuNzU5NSA3NC41QzMwLjc1OTUgODAuMzA0OCAzMi4yOTU1IDg2LjAwNTYg
                                            MzUuMjA2NCA5MS4wMzUyTDQxLjY2MzYgMTAyLjE5MUM0NC41NzQ1IDEwNy4yMTEgNDguNzY1NSAxMTEuMzkgNTMuODAwMyAxMTQuMjkyQzU4Ljg0NDYgMTE3LjE5NSA2NC41NjIyIDE
                                            xOC43MjYgNzAuMzg0IDExOC43MjZIODMuMzA3N0M4OS4xMjk2IDExOC43MjYgOTQuODQ3MSAxMTcuMTk1IDk5Ljg5MTUgMTE0LjI5MkMxMDQuOTM2IDExMS4zOSAxMDkuMTE3IDEwNy
                                            4yMTEgMTEyLjAyOCAxMDIuMTkxTDExOC40ODUgOTEuMDU0MUMxMTkuMjYzIDg5LjcyMTEgMTE5LjkzNiA4OC4zMjE5IDEyMC41MTQgODYuODk0M0wxMjAuNTUyIDg2LjgwOTJDMTIwL
                                            jU1MiA4Ni44MDkyIDEyMC41NTIgODYuNzk5OCAxMjAuNTYyIDg2Ljc5MDNIMTIwLjU3MUMxMjQuMzgzIDg3LjM3NjUgMTI3Ljk5NiA4OC44NjA3IDEzMS4xMTUgOTEuMTIwM0MxMzEu
                                            MTE1IDkxLjE0ODYgMTMxLjA5NiA5MS4xODY1IDEzMS4wNzcgOTEuMjE0OFoiIGZpbGw9IndoaXRlIi8
                                            +CjxwYXRoIGQ9Ik0xMjEuMDE1IDYyLjYzNDlDMTE5LjU0NSA2Mi44NzIzIDExOC4wNTYgNjMuMDA1MyAxMTYuNTU3IDYzLjAwNTNDMTA2Ljc2OCA2My4wMDUzIDEwMy4wOTggNTkuNz
                                            g1OCA5OC44NTE3IDU2LjA2M0M5NC43NDk2IDUyLjQ2MzYgODkuNjQ4MyA0OCA4MC4zNTg1IDQ4SDc0LjgwNThDNjguMDkwNiA0OCA2MS45ODA2IDUwLjQxMjIgNTcuNjE5MSA1NC43ODA5QzUzLjM1MzcgNTkuMDU0NSA1MSA2NC45MDQ3IDUxIDcxLjI0ODdWNzguNzUxM0M1MSA4NS4wOTUzIDUzLjM1MzcgOTAuOTQ1NSA1Ny42MTkxIDk1LjIxOTFDNjEuOTkwMiA5OS41ODc4IDY4LjA5MDYgMTAyIDc0LjgwNTggMTAySDgwLjM1ODVDODkuNjQ4MyAxMDIgOTQuNzQ5NiA5Ny41MjY5IDk4Ljg1MTcgOTMuOTM3QzEwMy4wOTggOTAuMjMzMiAxMDYuNzc3IDg2Ljk5NDcgMTE2LjU1NyA4Ni45OTQ3QzExOC4wNDYgODYuOTk0NyAxMTkuNTQ1IDg3LjEwODcgMTIxLjAxNSA4Ny4zNDYxQzEyMS44NiA4NS4yNTY4IDEyMy4wOTkgNzkuODI0NSAxMjIuOTk0IDgwLjI4OThDMTIwLjc2NSA4MC4wOTA0IDExOC42NzEgODAuMTg1NCAxMTYuNTM4IDgwLjE4NTRDOTQuMDQ4MyA4MC4xODU0IDk1LjIyMDMgOTUuMjAwMSA4MC4zMjk3IDk1LjIwMDFINzQuNzc2OUM2NC41NTUzIDk1LjIwMDEgNTcuODQwMSA4Ny45ODI0IDU3Ljg0MDEgNzguNzQxOFY3MS4yMjAyQzU3Ljg0MDEgNjEuOTg5MSA2NC41NTUzIDU0Ljc3MTQgNzQuNzc2OSA1NC43NzE0SDgwLjMyOTdDOTUuMjIwMyA1NC43NzE0IDk0LjA0ODMgNjkuNzc2NiAxMTYuNTM4IDY5Ljc3NjZDMTE4LjY3MSA2OS43NzY2IDEyMC43OTQgNjkuNTg2NyAxMjIuODg4IDY5LjIwNjhWNjkuMTAyNEMxMjIuNDg1IDY2Ljg4OTYgMTIxLjg2IDY0LjcxNDcgMTIxLjAxNSA2Mi42MjU0VjYyLjYzNDlaIiBmaWxsPSIjMDA2QkZGIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfNjYxM18yMDU3MCI+CjxyZWN0IHdpZHRoPSIxNTAiIGhlaWdodD0iMTUwIiByeD0iMTIiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==" color="#03a9f4" decoding="async" loading="lazy" alt="calendly" /></a> */}
                                </div>
                            </div>
                        </div>
                        <div class="pt-5 pb-5 row">
                            <div class="col-lg-3 col-6"><p class="main-heading-36 simple-font-weight">300+</p><p>Successful projects</p>
                            </div>
                            <div class="col-lg-3 col-6">
                                <p class="main-heading-36 simple-font-weight">97%</p>
                                <p>Customer satisfaction rate</p>
                            </div>
                            <div class="col-lg-3 col-6">
                                <p class="main-heading-36 simple-font-weight">500+</p>
                                <p>Developers and engineers</p></div><div class="col-lg-3 col-6"><p class="main-heading-36 simple-font-weight">8+</p>
                                <p>Years of experience</p>
                            </div>
                        </div>
                        {/* <div class="accslider">
                                                            <div class="slick-slider pl-3 pl-md-0 slick-initialized" dir="ltr">
                                                                <div class="slick-list">
                                                                    <div class="slick-track" style={{width: '3256px', opacity: '1', transform: 'translate3d(-1628px, 0px, 0px)'}}>
                                                                        <div data-index="-4" tabindex="-1" class="slick-slide slick-cloned" aria-hidden="true" style={{width: '148px'}}><div>
                                                                            <div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://techreviewer.co/top-software-development-companies" target="_blank" rel="noreferrer noopener nofollow">
                                                <img src="/static/tech_reviewer_top_software_developers_2020-562eec793e356113a009f4aed5e48ded.svg" alt="Top 100+ Software Development Companies in 2021" title="Top 100+ Software Development Companies in 2021" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="-3" tabindex="-1" class="slick-slide slick-cloned" aria-hidden="true" style={{width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://www.topdevelopers.co/press-releases/leading-mobile-app-development-companies-march-2021" target="_blank" rel="noreferrer noopener nofollow"><img src="/static/top_developers_2021-dd23c99461906b1c45b32e53a654c846.svg" alt="Finest Mobile App Developers in Canada - March 2021" title="Finest Mobile App Developers in Canada - March 2021" placeholder="blurred" decoding="async" loading="lazy"/></a>
                                                </div>
                                                </div>
                                                </div>
                                                <div data-index="-2" tabindex="-1" class="slick-slide slick-cloned" aria-hidden="true" style={{width: '148px'}}><div>
                                                    <div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://selectedfirms.co/companies/ecommerce-development/usa" target="_blank" rel="noreferrer noopener nofollow">
                                                    <img src="/static/selected_firms_top_ecommerce_development_company-7b6f23046d5a69342336c8c123021606.svg" alt="Top eCommerce Development Companies in the USA" title="Top eCommerce Development Companies in the USA" placeholder="blurred" decoding="async" loading="lazy"/></a>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    <div data-index="-1" tabindex="-1" class="slick-slide slick-cloned" aria-hidden="true" style={{width: '148px'}}><div>
                                                        <div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://superbcompanies.com/categories/web-app-development-companies/" target="_blank" rel="noreferrer noopener nofollow">
                                                        <img src="/static/superb_company_in_2023-46c0b277a82d8f2079ec135b287577c7.svg" alt="Top Web Development Companies" title="Top Web Development Companies" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="0" class="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: 'none', width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://www.goodfirms.co/directory/city/top-website-development-companies/mississauga" target="_blank" rel="noreferrer noopener nofollow">
                                                            <img src="/static/good_firms_top_web_development_company-d8afdd3bf52e7c99198027728e6f7981.svg" alt="Top Web Development Companies in Mississauga" title="Top Web Development Companies in Mississauga" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="1" class="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: 'none', width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://www.designrush.com/agency/ar-vr" target="_blank" rel="noreferrer noopener nofollow">
                                                                <img src="/static/design_rush_best_ar_vr_agency-9dafbc7d12a5020f1e21c448e6dc75fe.svg" alt="Top VR &amp; AR Companies" title="Top VR &amp; AR Companies" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="2" class="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: 'none', width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://upcity.com/ca/software-development/toronto" target="_blank" rel="noreferrer noopener nofollow">
                                                                    <img src="/static/featured_on_upcity-4f67e820876fba8ecad1a7712b52782f.svg" alt="Top Software Development Companies in Toronto, ON" title="Top Software Development Companies in Toronto, ON" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="3" class="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: 'none', width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://topfirms.co/companies/software-development/world" target="_blank" rel="noreferrer noopener nofollow">
                                                                        <img src="/static/top_firms_top_software_development_companies-a83da71d8cdc0867a3a1cd5a4aafb926.svg" alt="Best Software Development Companies In 2021" title="Best Software Development Companies In 2021" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="4" class="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: 'none', width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://www.mobileappdaily.com/reports/fastest-growing-app-development-companies" target="_blank" rel="noreferrer noopener nofollow">
                                                                            <img src="/static/fastest_growing_app_development_company_2021-988b8e06a4ca171178eb147dc7aa5a67.svg" alt="Fatest growing app development companies" title="Fatest growing app development companies" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="5" class="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: 'none', width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://techreviewer.co/top-software-development-companies" target="_blank" rel="noreferrer noopener nofollow">
                                                                            <img src="/static/tech_reviewer_top_software_developers_2020-562eec793e356113a009f4aed5e48ded.svg" alt="Top 100+ Software Development Companies in 2021" title="Top 100+ Software Development Companies in 2021" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="6" class="slick-slide" tabindex="-1" aria-hidden="true" style={{outline: 'none', width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://www.topdevelopers.co/press-releases/leading-mobile-app-development-companies-march-2021" target="_blank" rel="noreferrer noopener nofollow">
                                                                            <img src="/static/top_developers_2021-dd23c99461906b1c45b32e53a654c846.svg" alt="Finest Mobile App Developers in Canada - March 2021" title="Finest Mobile App Developers in Canada - March 2021" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="7" class="slick-slide slick-active slick-current" tabindex="-1" aria-hidden="false" style={{outline: 'none', width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://selectedfirms.co/companies/ecommerce-development/usa" target="_blank" rel="noreferrer noopener nofollow">
                                                                            <img src="/static/selected_firms_top_ecommerce_development_company-7b6f23046d5a69342336c8c123021606.svg" alt="Top eCommerce Development Companies in the USA" title="Top eCommerce Development Companies in the USA" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="8" class="slick-slide slick-active" tabindex="-1" aria-hidden="false" style={{outline: 'none', width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://superbcompanies.com/categories/web-app-development-companies/" target="_blank" rel="noreferrer noopener nofollow">
                                                                            <img src="/static/superb_company_in_2023-46c0b277a82d8f2079ec135b287577c7.svg" alt="Top Web Development Companies" title="Top Web Development Companies" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="9" tabindex="-1" class="slick-slide slick-active slick-cloned" aria-hidden="false" style={{width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://www.goodfirms.co/directory/city/top-website-development-companies/mississauga" target="_blank" rel="noreferrer noopener nofollow">
                                                                            <img src="/static/good_firms_top_web_development_company-d8afdd3bf52e7c99198027728e6f7981.svg" alt="Top Web Development Companies in Mississauga" title="Top Web Development Companies in Mississauga" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="10" tabindex="-1" class="slick-slide slick-active slick-cloned" aria-hidden="false" style={{width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://www.designrush.com/agency/ar-vr" target="_blank" rel="noreferrer noopener nofollow">
                                                                            <img src="/static/design_rush_best_ar_vr_agency-9dafbc7d12a5020f1e21c448e6dc75fe.svg" alt="Top VR &amp; AR Companies" title="Top VR &amp; AR Companies" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="11" tabindex="-1" class="slick-slide slick-cloned" aria-hidden="true" style={{width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://upcity.com/ca/software-development/toronto" target="_blank" rel="noreferrer noopener nofollow">
                                                                            <img src="/static/featured_on_upcity-4f67e820876fba8ecad1a7712b52782f.svg" alt="Top Software Development Companies in Toronto, ON" title="Top Software Development Companies in Toronto, ON" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="12" tabindex="-1" class="slick-slide slick-cloned" aria-hidden="true" style={{width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://topfirms.co/companies/software-development/world" target="_blank" rel="noreferrer noopener nofollow">
                                                                            <img src="/static/top_firms_top_software_development_companies-a83da71d8cdc0867a3a1cd5a4aafb926.svg" alt="Best Software Development Companies In 2021" title="Best Software Development Companies In 2021" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="13" tabindex="-1" class="slick-slide slick-cloned" aria-hidden="true" style={{width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://www.mobileappdaily.com/reports/fastest-growing-app-development-companies" target="_blank" rel="noreferrer noopener nofollow">
                                                                            <img src="/static/fastest_growing_app_development_company_2021-988b8e06a4ca171178eb147dc7aa5a67.svg" alt="Fatest growing app development companies" title="Fatest growing app development companies" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="14" tabindex="-1" class="slick-slide slick-cloned" aria-hidden="true" style={{width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://techreviewer.co/top-software-development-companies" target="_blank" rel="noreferrer noopener nofollow">
                                                                            <img src="/static/tech_reviewer_top_software_developers_2020-562eec793e356113a009f4aed5e48ded.svg" alt="Top 100+ Software Development Companies in 2021" title="Top 100+ Software Development Companies in 2021" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="15" tabindex="-1" class="slick-slide slick-cloned" aria-hidden="true" style={{width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://www.topdevelopers.co/press-releases/leading-mobile-app-development-companies-march-2021" target="_blank" rel="noreferrer noopener nofollow">
                                                                            <img src="/static/top_developers_2021-dd23c99461906b1c45b32e53a654c846.svg" alt="Finest Mobile App Developers in Canada - March 2021" title="Finest Mobile App Developers in Canada - March 2021" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="16" tabindex="-1" class="slick-slide slick-cloned" aria-hidden="true" style={{width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://selectedfirms.co/companies/ecommerce-development/usa" target="_blank" rel="noreferrer noopener nofollow">
                                                                            <img src="/static/selected_firms_top_ecommerce_development_company-7b6f23046d5a69342336c8c123021606.svg" alt="Top eCommerce Development Companies in the USA" title="Top eCommerce Development Companies in the USA" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div><div data-index="17" tabindex="-1" class="slick-slide slick-cloned" aria-hidden="true" style={{width: '148px'}}><div><div class="pt-2 pr-2 pb-2 pl-0" tabindex="-1" style={{width: '100%', display: 'inline-block'}}><a href="https://superbcompanies.com/categories/web-app-development-companies/" target="_blank" rel="noreferrer noopener nofollow">
                                                                            <img src="/static/superb_company_in_2023-46c0b277a82d8f2079ec135b287577c7.svg" alt="Top Web Development Companies" title="Top Web Development Companies" placeholder="blurred" decoding="async" loading="lazy"/></a></div></div></div></div></div></div>
                                                                            </div> */}
                    </div>
                    <div class="justify-content-lg-end justify-content-center d-flex col-lg-6 col-md-12">
                        <div>
                            <p class="pt-4 pb-1">We are passionate about delivering great software and services.</p>
                            <div class="ContactSales-module--card--2d5a9 px-md-5">
                                <form name="Contact Sales Form" method="POST" class="">
                                    <div class="ContactSalesForm-module--fieldColor--2d9c9 row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                {/* <input placeholder="Name*" name="fullName" type="text" id="fullName" class="form-control" value="" /> */}
                                                <input placeholder="Name*" name="fullName" type="text" class="form-control is-valid" id="validationServer01" value="" required />
                                                <div class="invalid-feedback">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                {/* <input placeholder="Email*" name="email" type="email" id="email" class="form-control" value="" /> */}
                                                <input placeholder="Email*" name="email" type="email" class="form-control is-valid" id="email" value="" required />
                                                <div class="invalid-feedback">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="form-group">
                                        <div class="phoneSelect react-tel-input ">
                                            <div class="special-label">Phone</div>
                                            <input class="form-control " placeholder="xxxx-xxxxxxx" type="tel" value="" />
                                            <div class="flag-dropdown ">
                                                <div class="selected-flag" title="" tabindex="0" role="button" aria-haspopup="listbox">
                                                    <div class="flag 0">
                                                        <div class="arrow">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="form-group">
                                        <div className="phoneSelect react-tel-input">
                                            <div className="special-label">Phone</div>
                                            <PhoneInput
                                                inputProps={{
                                                    name: 'phone',
                                                    required: true,
                                                    // autoFocus: true
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* <div class="form-group">
                                        <div class=" css-2b097c-container">
                                            <span aria-live="polite" aria-atomic="false" aria-relevant="additions text" class="css-7pg0cj-a11yText"></span>
                                            <div class=" css-a33x3s-control">
                                                <div class=" css-1hwfws3">
                                                    <div class=" css-1wa3eu0-placeholder">What are you seeking?*</div>
                                                    <div class="css-1g6gooi">
                                                        <div class="" style={{ display: 'inline-block' }}>
                                                            <input autocapitalize="none" autocomplete="off" autocorrect="off" id="react-select-2-input" spellcheck="false" tabindex="0" type="text" aria-autocomplete="list" value="" style={{ boxSizing: 'content-box', width: '2px', background: '0px center', border: '0px', fontSize: 'inherit', opacity: 1, outline: '0px', padding: '0px', color: 'inherit', }} />
                                                            <div style={{ position: 'absolute', top: '0px', left: '0px', visibility: 'hidden', height: '0px', overflow: 'scroll', whiteSpace: 'pre', fontSize: '16px', fontFamily: '"Public Sans"', fontWeight: '400', fontStyle: 'normal', letterSpacing: 'normal', textTransform: 'none' }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=" css-1wy0on6">
                                                    <div class=" css-xzu46z-indicatorContainer" aria-hidden="true">
                                                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-8mmkcg">
                                                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <input name="serviceType" type="hidden" value="" />
                                        </div>
                                    </div> */}
                                    {/* <div className="form-group">
                                        <div className="css-2b097c-container">
                                            <div className=" css-a33x3s-control">
                                                <div className=" css-1hwfws3">
                                                    <div className=" css-1wa3eu0-placeholder">What are you seeking?*</div>
                                                    <div className="css-1g6gooi">
                                                        <div className="" style={{ display: 'inline-block' }}>
                                                            <input
                                                                autocapitalize="none"
                                                                autocomplete="off"
                                                                autocorrect="off"
                                                                id="react-select-2-input"
                                                                spellcheck="false"
                                                                tabIndex="0"
                                                                type="text"
                                                                aria-autocomplete="list"
                                                                value={inputValue}
                                                                onChange={handleInputChange}
                                                                style={{ boxSizing: 'content-box', width: '2px', background: '0px center', border: '0px', fontSize: 'inherit', opacity: 1, outline: '0px', padding: '0px', color: 'inherit' }}
                                                            />
                                                            <div style={{ position: 'absolute', top: '0px', left: '0px', visibility: 'hidden', height: '0px', overflow: 'scroll', whiteSpace: 'pre', fontSize: '16px', fontFamily: '"Public Sans"', fontWeight: '400', fontStyle: 'normal', letterSpacing: 'normal', textTransform: 'none' }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=" css-1wy0on6" onClick={handleArrowClick}>
                                                    <div className=" css-xzu46z-indicatorContainer" aria-hidden="true">
                                                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-8mmkcg">
                                                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            {dropdownVisible && (
                                                <div className="country-dropdown" style={dropdownStyle}>
                                                    {dropdownOptions.map((option) => (
                                                        <div key={option} onClick={() => handleOptionClick(option)} style={{ color: 'your-desired-color' }}>
                                                            {option}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            <input name="serviceType" type="hidden" value={selectedOption} />
                                        </div>
                                    </div> */}
                                    <select class="form-select form-selec form-select-lg mb-3" aria-label=".form-select-lg example">
                                        <option selected>What are you seeking?*</option>
                                        <option value="1">Staff Augmentation</option>
                                        <option value="2">Dedicated Team</option>
                                        <option value="3">Fixed Gigs</option>
                                        <option value="4">Career Opportunities</option>
                                        <option value="5">General Inquiry</option>
                                    </select>
                                    <select class="form-select form-selec form-select-lg mb-3" aria-label=".form-select-lg example">
                                        <option selected>How did you hear about Reownlogics?</option>
                                        <option value="1">Social Media</option>
                                        <option value="2">Clutch.co/Good Firms or other listings</option>
                                        <option value="3">Google Search</option>
                                        <option value="4">Email from Reownlogics</option>
                                        <option value="5">Recommendation from Reownlogics client</option>
                                        <option value="5">Data Scraping</option>
                                        <option value="5">Other</option>
                                    </select>
                                    <div class="ContactSalesForm-module--fieldColor--2d9c9 form-group">
                                        <textarea rows="3" placeholder="Message" name="message" id="message" class="form-control">
                                        </textarea>
                                        <div class="invalid-feedback">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="ContactSalesForm-module--salesFormRowHeight--f968e col-md-6 col-sm-12 col-12">
                                            <div class="pl-md-1 form-group">
                                                <div class="ContactSalesForm-module--salesFormCheckBoxSize--dfe0a form-check">
                                                    <input name="getnda" type="checkbox" id="getnda" class="form-check-input" value="" />
                                                    <label title="" for="getnda" class="form-check-label">
                                                        <span>Get NDA</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ContactSalesForm-module--attachment--e028e  col-md-6">
                                            <div class="pl-3 mb-3 text-md-right form-group" style={{ wordBreak: 'break-all' }}>
                                                <label for="attached" class="mb-0 font-weight-normal ContactSalesForm-module--attachmentLabel--02256">📎 Attach File</label>
                                                <input name="attached" accept=".pdf, .doc, .docx, .png,.ppt, .txt" type="file" id="attached" class="invisible form-control-file" style={{ marginBottom: '-30px' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="ContactSalesForm-module--salesFormRowHeight--f968e  col-sm-12 col-12">
                                            <div class="pl-md-1 form-group">
                                                <div class="ContactSalesForm-module--salesFormCheckBoxSize--dfe0a form-check">
                                                    <input name="isAgree" type="checkbox" id="isAgree" class="form-check-input" value="false" />
                                                    <label title="" for="isAgree" class="form-check-label">
                                                        <span>I agree to the <a class="font-weight-normal" href="/terms-conditions/">Terms &amp; Conditions *</a></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ContactSalesForm-module--salesFormRowHeight--f968e d-md-none  col-md-12 col-sm-12 col-12">
                                            <div class="text-md-right form-group" style={{ wordBreak: 'break-all' }}>
                                                <label for="attached_mobile" class="mb-0 font-weight-normal ContactSalesForm-module--attachmentLabel--02256">📎 Attach File</label>
                                                <input name="attached" accept=".pdf, .doc, .docx, .png,.ppt, .txt" type="file" id="attached_mobile" class="invisible form-control-file" style={{ marginBottom: '-30px' }} />
                                            </div>
                                        </div>
                                        <div class="text-center col-12"><div class="invalid-feedback" style={{ display: 'block', visibility: 'hidden', marginTop: '0px', textAlign: 'left' }}>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="mt-3 text-center submit-button-cta">
                                        <button aria-label="Send" id="sale_section_contact_us_form_gt" type="submit" class="btn_white_border mt-4 btn btn-primary" style={{ zIndex: '0' }}>Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const dropdownStyle = {
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    zIndex: 1,
    border: '1px solid #ccc',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    background: '#fff',
    color: 'black',
    cursor: 'pointer',
    lineheight: '5rem'
};