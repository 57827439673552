import React from 'react'
import OnDemandServices from '../Fullpages/OndemandServices/OnDemandServices'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import WorkPortfolio from '../components/WorkPortfolio/WorkPortfolio'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'

export default function OnDemand() {
  return (
    <>
    <Navbar/>
    <OnDemandServices/>
    <WorkPortfolio/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
