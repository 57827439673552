import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'
import ProductPhotographyServices from '../Fullpages/ProductPhotographyServices/ProductPhotographyServices'

export default function ProductPhotography() {
  return (
    <>
    <Navbar/>
    <ProductPhotographyServices/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
