import React from 'react'
import HealthCareSoftware from '../Fullpages/HealthCare/HealthCareSoftware'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'
import WorkPortfolio from '../components/WorkPortfolio/WorkPortfolio'
export default function HealthCare() {
  return (
    <>
    <Navbar/>
    <HealthCareSoftware/>
    <WorkPortfolio/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
