import React from 'react'
import SoftwareDevelopment from '../Fullpages/Services/SoftwareDevelopment'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import WorkPortfolio from '../components/WorkPortfolio/WorkPortfolio'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'

export default function Services() {
  return (
    <>
    <Navbar/>
    <SoftwareDevelopment/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
