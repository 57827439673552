import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'
import GoogleAdwordsServices from '../Fullpages/GoogleAdwordsServices/GoogleAdwordsServices'

export default function GoogleAdwords() {
  return (
    <>
    <Navbar/>
    <GoogleAdwordsServices/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
