import React, { useState } from 'react';

const FaqItem = ({ index, question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="card border-light" style={{ overflow: 'visible' }}>
      <div className="card-header" onClick={toggleAccordion}>
        <div className="d-flex justify-content-between align-items-center">
          <div className={isOpen ? 'Faqs2-module--quesColorSelected--49a56' : 'Faqs2-module--quesColor--4b2cd'}>
            <div className="Faqs2-module--faqsNum--44993">
              <span className="Faqs2-module--serial2--88c80">0{index}:</span>
              <h3 className="Faqs2-module--faqsSub--2f843 py-2 mb-0" type="button">
                {question}
              </h3>
            </div>
          </div>
          <div type="button" className="Faqs2-module--faqButton--5e66d">
            {isOpen ? (
              <img
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MCA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMTIuMDAxIiB5PSIxOS4wNDk4IiB3aWR0aD0iMTYiIGhlaWdodD0iMiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="
                alt="Toggle Icon"
                className="Faqs2-module--quesColorSelected--49a56"
              />
            ) : (
              <img
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MCA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwLjc3MjIgMjcuNjk2M0MyMC41OTE2IDI3Ljg3NjggMjAuMzYyNCAyNy45NjcxIDIwLjA4NDcgMjcuOTY3MUMxOS44MzQ3IDI3Ljk2NzEgMTkuNjE5NCAyNy44NzY4IDE5LjQzODggMjcuNjk2M0MxOS4yNTgzIDI3LjUxNTcgMTkuMTY4IDI3LjMwMDUgMTkuMTY4IDI3LjA1MDVWMjEuMTMzOEgxMy4wNDNDMTIuNzY1MiAyMS4xMzM4IDEyLjUzNiAyMS4wNDM1IDEyLjM1NTUgMjAuODYzQzEyLjE3NDkgMjAuNjgyNCAxMi4wODQ3IDIwLjQ1MzIgMTIuMDg0NyAyMC4xNzU1QzEyLjA4NDcgMTkuOTI1NSAxMi4xNzQ5IDE5LjcwMzIgMTIuMzU1NSAxOS41MDg4QzEyLjUzNiAxOS4zMTQzIDEyLjc1MTMgMTkuMjE3MSAxMy4wMDEzIDE5LjIxNzFIMTkuMTY4VjEzLjA5MjFDMTkuMTY4IDEyLjgxNDMgMTkuMjUxMyAxMi41ODUyIDE5LjQxOCAxMi40MDQ2QzE5LjU4NDcgMTIuMjI0MSAxOS44MDY5IDEyLjEzMzggMjAuMDg0NyAxMi4xMzM4QzIwLjM2MjQgMTIuMTMzOCAyMC41OTE2IDEyLjIyNDEgMjAuNzcyMiAxMi40MDQ2QzIwLjk1MjcgMTIuNTg1MiAyMS4wNDMgMTIuODAwNSAyMS4wNDMgMTMuMDUwNVYxOS4yMTcxSDI2Ljk1OTdDMjcuMjM3NCAxOS4yMTcxIDI3LjQ2NjYgMTkuMzA3NCAyNy42NDcyIDE5LjQ4OEMyNy44Mjc3IDE5LjY2ODUgMjcuOTE4IDE5Ljg5NzcgMjcuOTE4IDIwLjE3NTVDMjcuOTE4IDIwLjQyNTUgMjcuODI3NyAyMC42NDc3IDI3LjY0NzIgMjAuODQyMUMyNy40NjY2IDIxLjAzNjYgMjcuMjUxMyAyMS4xMzM4IDI3LjAwMTMgMjEuMTMzOEgyMS4wNDNWMjcuMDA4OEMyMS4wNDMgMjcuMjg2NiAyMC45NTI3IDI3LjUxNTcgMjAuNzcyMiAyNy42OTYzWiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="
                alt="Toggle Icon"
                className="Faqs2-module--quesColor--4b2cd"
              />
            )}
          </div>
        </div>
      </div>
      <div className={`collapse ${isOpen ? 'show' : ''}`}>
        <div className="Faqs2-module--textColor--e10b6 card-body">
          <div>
            <p>{answer}</p>
          </div>
        </div>
      </div>
      <hr style={{ margin: '0px' }} />
    </div>
  );
};

const FaqList = () => {
  const faqs = [
    {
      question: 'Why outsource Ecommerce development services from ReownLogic’s?',
      answer: 'With a proven track record of completing 300+ projects successfully we help companies develop their projects from scratch or improve their already existing products. We leverage the power of the latest technologies to boost revenue, expand customer reach, and elevate online businesses.',
    },
    {
      question: 'How do I choose an Ecommerce development company?',
      answer: 'The first and foremost thing you should do is check their portfolio . Also, use websites like Clutch etc to get reviews and then hold a series of meetings with a company to check their expertise.',
    },
    {
      question: 'Where to find experienced Ecommerce developers?',
      answer: 'You can find experienced Ecommerce developers by viewing freelance networks and professional networking sites. But, this can be risky, the best thing to do is to hire an Ecommerce development firm that offers experienced Ecommerce developers. Additionally, seek recommendations from industry peers and colleagues.',
    },
    {
      question: 'Can you make our web ecommerce solution mobile-friendly?',
      answer: 'Yes you can make your web ecommerce solution mobile-friendly by hiring any software development company. This can help you increase your reach and enhance user experience.',
    },
  ];

  return (
    <div className="faqsAccordian">
      <div className="accordion">
        {faqs.map((faq, index) => (
          <FaqItem key={index} index={index + 1} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

const Faq = () => {
  return (
    <div className="Faqs2-module--section--b0065">
      <div className="container">
        <h2 className="Faqs2-module--head--b1ec9 text-center">Frequently Asked Questions </h2>
        <div className="row">
          <div className="col-lg-12">
            <FaqList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
