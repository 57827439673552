import React from 'react'
import Hire from '../Fullpages/HireDevelopers/Hire'
import Navbar from '../components/Navbar'
import LetUsKnow from '../components/LetUsKnow/LetUsKnow'
import Footer from '../components/Footer/Footer'

export default function HireDevelopers() {
  return (
    <>
    <Navbar/>
    <Hire/>
    <LetUsKnow/>
    <Footer/>
    </>
  )
}
