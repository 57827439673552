import React from 'react'
import WeGot from '../components/ContactUs/WeGot'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer/Footer'

export default function ContactUs() {
  return (
    <>
    <Navbar/>
    <WeGot/>
    <Footer/>
    </>
  )
}
