import React from 'react'
import "./IndustriesWeServe.css";
import ec from '../../images/ec.webp'
import fin from '../../images/fin.webp'
import health from '../../images/health.webp'
import edu from '../../images/edu.webp'
import food from '../../images/f&e.webp'
import travel from '../../images/t&t.webp'
import On_Demand_Services_d2bec8e620 from '../../images/On_Demand_Services_d2bec8e620.webp'
import block from '../../images/block.webp'
import game from '../../images/gd.webp'
import { Link } from 'react-router-dom';

export default function IndustriesWeServe() {
    return (
        <>
            <section class="Banner-module--section--1f46d">
                <div class="container">
                    <nav class="mb-4 text-white">
                        <a class="text-white" href="/">Home</a> » <span class="text-white">Industries</span>
                    </nav>
                    <div class="text-light text-center">
                        <h1 class="Banner-module--mainHeading--13b6e">Industries We Serve</h1>
                        <div class="justify-content-center row">
                            <div class="col-lg-10 col-md-11 col-sm-12 col-12">
                                <p class="h4 font-weight-normal">ReownLogics provides global enterprises with dedicated software development outsourcing teams to help them be successful, competitive, and innovative.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="pb-3 p-md-0" style={{ backgroundColor: 'rgb(248, 248, 248)' }}>
                <div class="ImageCard-module--card--83478 container">
                    <div class="d-none d-md-none d-lg-block false">
                        <div data-gatsby-image-wrapper="" class="gatsby-image-wrapper gatsby-image-wrapper-constrained">
                            <div style={{ maxWidth: '1346px', display: 'block' }}>
                                <img alt="" role="presentation" aria-hidden="true" src="data:image/svg+xml;charset=utf-8,%3Csvg height='680' width='1346' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E" style={{ maxWidth: '100%', display: 'block', position: 'static' }} />
                            </div>
                            <div aria-hidden="true" data-placeholder-image="" style={{ opacity: '0', transition: 'opacity 500ms linear 0s', backgroundColor: 'rgb(8, 8, 8)', position: 'absolute', inset: '0px', objectFit: 'cover' }}>
                            </div>
                            <img placeholder="blurred" decoding="async" width="1346" height="680" data-main-image="" style={{ objectFit: 'cover', opacity: '1' }} sizes="(min-width: 1346px) 1346px, 100vw" loading="lazy" src={ec} alt="E-Commerce" />
                            <noscript>
                                <img placeholder="blurred" decoding="async" width="1346" height="680" data-main-image="" style={{ objectFit: 'cover', opacity: '0' }} sizes="(min-width: 1346px) 1346px, 100vw" loading="lazy" src="/static/ad31ea5e1b5ec3dd50aa07ff475f7591/67853/E_Commerce_d807df9568.webp" srcSet="/static/ad31ea5e1b5ec3dd50aa07ff475f7591/9abb6/E_Commerce_d807df9568.webp 337w,
/static/ad31ea5e1b5ec3dd50aa07ff475f7591/e268d/E_Commerce_d807df9568.webp 673w,
/static/ad31ea5e1b5ec3dd50aa07ff475f7591/67853/E_Commerce_d807df9568.webp 1346w" alt="E-Commerce" />
                            </noscript>
                        </div>
                    </div>
                    <div class="ImageCard-module--content--7bd66 d-none d-md-none d-lg-block">
                        <h2 class="pl-3 pb-3 main-heading-36">E-Commerce</h2>
                        <div class="mb-3 pl-3">
                            <p>Harness the power of technology to scale your business. Build or transform your online business to reach more customers.</p>
                        </div>
                        <div class="pl-2">
                            <Link to={`/e-commerce`}>
                                <button type="button" class="btn_white_border1 btn btn-primary">Learn More</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div class="ImageCard-module--card--83478 container">
                    <div class="d-none d-md-none d-lg-block false">
                        <div data-gatsby-image-wrapper="" class="gatsby-image-wrapper gatsby-image-wrapper-constrained">
                            <div style={{ maxWidth: '1346px', display: 'block' }}>
                                <img alt="" role="presentation" aria-hidden="true" src="data:image/svg+xml;charset=utf-8,%3Csvg height='680' width='1346' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E" style={{ maxWidth: '100%', display: 'block', position: 'static' }} />
                            </div>
                            <div aria-hidden="true" data-placeholder-image="" style={{ opacity: '0', transition: 'opacity 500ms linear 0s', backgroundColor: 'rgb(8, 8, 24)', position: 'absolute', inset: '0px', objectFit: 'cover' }}>
                            </div>
                            <img placeholder="blurred" decoding="async" width="1346" height="680" data-main-image="" style={{ objectFit: 'cover', opacity: '1' }} sizes="(min-width: 1346px) 1346px, 100vw" loading="lazy" src={fin} alt="Fintech" />
                            <noscript>
                                <img placeholder="blurred" decoding="async" width="1346" height="680" data-main-image="" style={{ objectFit: 'cover', opacity: '0' }} sizes="(min-width: 1346px) 1346px, 100vw" loading="lazy" src="/static/2a29d7a7e1001fe9befb8df94dde2c70/67853/Fintech_9630ed78d6.webp" srcSet="/static/2a29d7a7e1001fe9befb8df94dde2c70/9abb6/Fintech_9630ed78d6.webp 337w,
/static/2a29d7a7e1001fe9befb8df94dde2c70/e268d/Fintech_9630ed78d6.webp 673w,
/static/2a29d7a7e1001fe9befb8df94dde2c70/67853/Fintech_9630ed78d6.webp 1346w" alt="Fintech" />
                            </noscript>
                        </div>
                    </div>
                    <div class="ImageCard-module--content--7bd66 d-none d-md-none d-lg-block">
                        <h2 class="pl-3 pb-3 main-heading-36">Fintech</h2>
                        <div class="mb-3 pl-3">
                            <p>ReownLogics provides dedicated remote resources for custom software development and related services to fintech companies including digital payments solutions, digital banking apps.</p>
                        </div>
                        <div class="pl-2">
                            <Link to={`/fintech`}>
                                <button type="button" class="btn_white_border1 btn btn-primary">Learn More</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div class="ImageCard-module--card--83478 container">
                    <div class="d-none d-md-none d-lg-block false">
                        <div data-gatsby-image-wrapper="" class="gatsby-image-wrapper gatsby-image-wrapper-constrained">
                            <div style={{ maxWidth: '1346px', display: 'block' }}>
                                <img alt="" role="presentation" aria-hidden="true" src="data:image/svg+xml;charset=utf-8,%3Csvg height='680' width='1346' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E" style={{ maxWidth: '100%', display: 'block', position: 'static' }} />
                            </div>
                            <div aria-hidden="true" data-placeholder-image="" style={{ opacity: '0', transition: 'opacity 500ms linear 0s', backgroundColor: 'rgb(8, 56, 104)', position: 'absolute', inset: '0px', objectFit: 'cover' }}>
                            </div>
                            <img placeholder="blurred" decoding="async" width="1346" height="680" data-main-image="" style={{ objectFit: 'cover', opacity: '1' }} sizes="(min-width: 1346px) 1346px, 100vw" loading="lazy" src={health} alt="Healthcare" />
                            <noscript>
                                <img placeholder="blurred" decoding="async" width="1346" height="680" data-main-image="" style={{ objectFit: 'cover', opacity: '0' }} sizes="(min-width: 1346px) 1346px, 100vw" loading="lazy" src="/static/e6dd4b9cbafa6ea289327b805fa14227/67853/Healthcare_56156b7f2d.webp" srcSet="/static/e6dd4b9cbafa6ea289327b805fa14227/9abb6/Healthcare_56156b7f2d.webp 337w,
/static/e6dd4b9cbafa6ea289327b805fa14227/e268d/Healthcare_56156b7f2d.webp 673w,
/static/e6dd4b9cbafa6ea289327b805fa14227/67853/Healthcare_56156b7f2d.webp 1346w" alt="Healthcare" />
                            </noscript>
                        </div>
                    </div>
                    <div class="ImageCard-module--content--7bd66 d-none d-md-none d-lg-block">
                        <h2 class="pl-3 pb-3 main-heading-36">Healthcare</h2>
                        <div class="mb-3 pl-3">
                            <p>Enhance the quality of patient care and streamline the workflow of your healthcare organization with ReownLogic's integrated healthcare software solutions.</p>
                        </div>
                        <div class="pl-2">
                            <Link to={`/healthcare`}>
                                <button type="button" class="btn_white_border1 btn btn-primary">Learn More</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div class="ImageCard-module--card--83478 container">
                    <div class="d-none d-md-none d-lg-block false">
                        <div data-gatsby-image-wrapper="" class="gatsby-image-wrapper gatsby-image-wrapper-constrained">
                            <div style={{ maxWidth: '1346px', display: 'block' }}>
                                <img alt="" role="presentation" aria-hidden="true" src="data:image/svg+xml;charset=utf-8,%3Csvg height='680' width='1346' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E" style={{ maxWidth: '100%', display: 'block', position: 'static' }} />
                            </div>
                            <div aria-hidden="true" data-placeholder-image="" style={{ opacity: '0', transition: 'opacity 500ms linear 0s', backgroundColor: 'rgb(8, 8, 8)', position: 'absolute', inset: '0px', objectFit: 'cover' }}>
                            </div>
                            <img placeholder="blurred" decoding="async" width="1346" height="680" data-main-image="" style={{ objectFit: 'cover', opacity: '1' }} sizes="(min-width: 1346px) 1346px, 100vw" loading="lazy" src={edu} alt="Education" />
                            <noscript>
                                <img placeholder="blurred" decoding="async" width="1346" height="680" data-main-image="" style={{ objectFit: 'cover', opacity: '0' }} sizes="(min-width: 1346px) 1346px, 100vw" loading="lazy" src="/static/b74e233a040263d64cca0fa0a9f1bf2d/67853/Education_aaf7b698d7.webp" srcSet="/static/b74e233a040263d64cca0fa0a9f1bf2d/9abb6/Education_aaf7b698d7.webp 337w,
/static/b74e233a040263d64cca0fa0a9f1bf2d/e268d/Education_aaf7b698d7.webp 673w,
/static/b74e233a040263d64cca0fa0a9f1bf2d/67853/Education_aaf7b698d7.webp 1346w" alt="Education" />
                            </noscript>
                        </div>
                    </div>
                    <div class="ImageCard-module--content--7bd66 d-none d-md-none d-lg-block">
                        <h2 class="pl-3 pb-3 main-heading-36">Education</h2>
                        <div class="mb-3 pl-3">
                            <p>Build scalable educational platforms to enhance your eLearning business. ReownLogics provides dedicated remote teams with expertise in developing eLearning platforms, language learning apps, personalize learning platforms.</p>
                        </div>
                        <div class="pl-2">
                            <Link to={`/education`}>
                                <button type="button" class="btn_white_border1 btn btn-primary">Learn More</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div class="ImageCard-module--card--83478 container">
                <div class="d-none d-md-none d-lg-block false">
                    <div data-gatsby-image-wrapper="" class="gatsby-image-wrapper gatsby-image-wrapper-constrained">
                        <div style={{maxWidth:'1346px',display:'block'}}>
                            <img alt="" role="presentation" aria-hidden="true" src="data:image/svg+xml;charset=utf-8,%3Csvg height='680' width='1346' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E" style={{maxWidth:'100%',display:'block',position:'static'}} />
                        </div>
                        <div aria-hidden="true" data-placeholder-image="" style={{opacity: '0', transition: 'opacity 500ms linear 0s', backgroundColor: 'rgb(8, 8, 8)', position: 'absolute', inset: '0px', objectFit: 'cover'}}>
                        </div>
                        <img placeholder="blurred" decoding="async" width="1346" height="680" data-main-image="" style={{objectFit: 'cover', opacity: '1'}} sizes="(min-width: 1346px) 1346px, 100vw" loading="lazy" src={food} alt="Food &amp; Groceries" />
                        <noscript>
                            <img placeholder="blurred" decoding="async" width="1346" height="680" data-main-image="" style={{objectFit:'cover',opacity:'0'}} sizes="(min-width: 1346px) 1346px, 100vw" loading="lazy" src="/static/54f9c57ea1a2f9bf6d0b9b99cc4baf8f/67853/Food_Groceries_941d8bbd7e.webp" srcSet="/static/54f9c57ea1a2f9bf6d0b9b99cc4baf8f/9abb6/Food_Groceries_941d8bbd7e.webp 337w,
/static/54f9c57ea1a2f9bf6d0b9b99cc4baf8f/e268d/Food_Groceries_941d8bbd7e.webp 673w,
/static/54f9c57ea1a2f9bf6d0b9b99cc4baf8f/67853/Food_Groceries_941d8bbd7e.webp 1346w" alt="Food &amp; Groceries" />
                        </noscript>
                    </div>
                </div>
                <div class="ImageCard-module--content--7bd66 d-none d-md-none d-lg-block">
                    <h2 class="pl-3 pb-3 main-heading-36">Food &amp; Groceries</h2>
                    <div class="mb-3 pl-3">
                        <p>Render consistent and customer-centric food and grocery shopping experience with ultimate web and mobile app development.</p>
                    </div>
                    <div class="pl-2">
                        <Link to={`/food-groceries`}>
                            <button type="button" class="btn_white_border1 btn btn-primary">Learn More</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div class="ImageCard-module--card--83478 container">
                <div class="d-none d-md-none d-lg-block false">
                    <div data-gatsby-image-wrapper="" class="gatsby-image-wrapper gatsby-image-wrapper-constrained">
                        <div style={{ maxWidth: '1343px', display: 'block' }}>
                            <img alt="" role="presentation" aria-hidden="true" src="data:image/svg+xml;charset=utf-8,%3Csvg height='680' width='1343' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E" style={{ maxWidth: '100%', display: 'block', position: 'static' }} />
                        </div>
                        <div aria-hidden="true" data-placeholder-image="" style={{ opacity: '0', transition: 'opacity 500ms linear 0s', backgroundColor: 'rgb(56, 72, 248)', position: 'absolute', inset: '0px', objectFit: 'cover' }}>
                        </div>
                        <img placeholder="blurred" decoding="async" width="1343" height="680" data-main-image="" style={{ objectFit: 'cover', opacity: '1' }} sizes="(min-width: 1343px) 1343px, 100vw" loading="lazy" src={travel} alt="Travel &amp; Tourism" />
                        <noscript>
                            <img placeholder="blurred" decoding="async" width="1343" height="680" data-main-image="" style={{ objectFit: 'cover', opacity: '0' }} sizes="(min-width: 1343px) 1343px, 100vw" loading="lazy" src="/static/3096c792aabe6d4e3527088eee2d2b9a/8096d/Travel_Tourism_fba9ec4a73.webp" srcSet="/static/3096c792aabe6d4e3527088eee2d2b9a/84a88/Travel_Tourism_fba9ec4a73.webp 336w,
/static/3096c792aabe6d4e3527088eee2d2b9a/f9619/Travel_Tourism_fba9ec4a73.webp 672w,
/static/3096c792aabe6d4e3527088eee2d2b9a/8096d/Travel_Tourism_fba9ec4a73.webp 1343w" alt="Travel &amp; Tourism" />
                        </noscript>
                    </div>
                </div>
                <div class="ImageCard-module--content--7bd66 d-none d-md-none d-lg-block">
                    <h2 class="pl-3 pb-3 main-heading-36">Travel &amp; Tourism</h2>
                    <div class="mb-3 pl-3">
                        <p>ReownLogic provides you a dedicated software development team for hotel app development, real-time booking systems, hotel and flight search engines, inventory management solutions.</p>
                    </div>
                    <div class="pl-2">
                        <Link to={`/travel-tourism`}>
                            <button type="button" class="btn_white_border1 btn btn-primary">Learn More</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div class="ImageCard-module--card--83478 container">
                <div class="d-none d-md-none d-lg-block false">
                    <div data-gatsby-image-wrapper="" class="gatsby-image-wrapper gatsby-image-wrapper-constrained">
                        <div style={{ maxWidth: '1346px', display: 'block' }}>
                            <img alt="" role="presentation" aria-hidden="true" src="data:image/svg+xml;charset=utf-8,%3Csvg height='680' width='1346' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E" style={{ maxWidth: '100%', display: 'block', position: 'static' }} />
                        </div>
                        <div aria-hidden="true" data-placeholder-image="" style={{ opacity: '0', transition: 'opacity 500ms linear 0s', backgroundColor: 'rgb(72, 136, 216)', position: 'absolute', inset: '0px', objectFit: 'cover' }}>
                        </div>
                        <img placeholder="blurred" decoding="async" width="1346" height="680" data-main-image="" style={{ objectFit: 'cover', opacity: '1' }} sizes="(min-width: 1346px) 1346px, 100vw" loading="lazy" src={On_Demand_Services_d2bec8e620} alt="On-Demand Services" />
                        <noscript>
                            <img placeholder="blurred" decoding="async" width="1346" height="680" data-main-image="" style={{ objectFit: 'cover', opacity: '0' }} sizes="(min-width: 1346px) 1346px, 100vw" loading="lazy" src="/static/2075e1a44954c9df10995542622fd43f/67853/On_Demand_Services_d2bec8e620.webp" srcSet="/static/2075e1a44954c9df10995542622fd43f/9abb6/On_Demand_Services_d2bec8e620.webp 337w,
/static/2075e1a44954c9df10995542622fd43f/e268d/On_Demand_Services_d2bec8e620.webp 673w,
/static/2075e1a44954c9df10995542622fd43f/67853/On_Demand_Services_d2bec8e620.webp 1346w" alt="On-Demand Services" />
                        </noscript>
                    </div>
                </div>
                <div class="ImageCard-module--content--7bd66 d-none d-md-none d-lg-block">
                    <h2 class="pl-3 pb-3 main-heading-36">On-Demand Services</h2>
                    <div class="mb-3 pl-3">
                        <p>Taking businesses into the on-demand stride, ReownLogic stands out as one company that helps clients to design and develop on-demand mobile applications to achieve success.</p>
                    </div>
                    <div class="pl-2">
                        <Link to={`/on-demand-services`}>
                            <button type="button" class="btn_white_border1 btn btn-primary">Learn More</button>
                        </Link>
                    </div>
                </div>
            </div>
            </div>
            
            {/* <div class="ImageCard-module--card--83478 container">
                <div class="d-none d-md-none d-lg-block true">
                    <div data-gatsby-image-wrapper="" class="gatsby-image-wrapper gatsby-image-wrapper-constrained true">
                        <div style={{ maxWidth: '1343px', display: 'block' }}>
                            <img alt="" role="presentation" aria-hidden="true" src="data:image/svg+xml;charset=utf-8,%3Csvg height='680' width='1343' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E" style={{ maxWidth: '100%', display: 'block', position: 'static' }} />
                        </div>
                        <div aria-hidden="true" data-placeholder-image="" style={{ opacity: '0', transition: 'opacity 500ms linear 0s', backgroundColor: 'rgb(8, 40, 72)', position: 'absolute', inset: '0px', objectFit: 'cover' }}>
                        </div>
                        <img placeholder="blurred" decoding="async" width="1343" height="680" data-main-image="" style={{ objectFit: 'cover', opacity: '1' }} sizes="(min-width: 1343px) 1343px, 100vw" loading="lazy" src={block} alt="Blockchain" />
                        <noscript>
                            <img placeholder="blurred" decoding="async" width="1343" height="680" data-main-image="" style={{ objectFit: 'cover', opacity: '0' }} sizes="(min-width: 1343px) 1343px, 100vw" loading="lazy" src="/static/e55aaad786ce1e7623b870ca5fb30a8c/8096d/Blockchain_751b0552cd.webp" srcSet="/static/e55aaad786ce1e7623b870ca5fb30a8c/84a88/Blockchain_751b0552cd.webp 336w,
/static/e55aaad786ce1e7623b870ca5fb30a8c/f9619/Blockchain_751b0552cd.webp 672w,
/static/e55aaad786ce1e7623b870ca5fb30a8c/8096d/Blockchain_751b0552cd.webp 1343w" alt="Blockchain" />
                        </noscript>
                    </div>
                </div>
                <div class="ImageCard-module--content--7bd66 d-none d-md-none d-lg-block">
                    <h2 class="pl-3 pb-3 main-heading-36">Blockchain</h2>
                    <div class="mb-3 pl-3">
                        <p>As early adopters of the blockchain revolution, we have extensive knowledge of cryptocurrency creation and established ourselves as a dependable and professional blockchain development firm. Trust us to create safer and trustworthy environment to develop real-time blockchain applications.</p>
                    </div>
                    <div class="pl-2">
                        <Link to={`/blockchain-development-services`}>
                            <button type="button" class="btn_white_border1 btn btn-primary">Learn More</button>
                        </Link>
                    </div>
                </div>
            </div> */}
            {/* <div class="ImageCard-module--card--83478 container">
                <div class="d-none d-md-none d-lg-block true">
                    <div data-gatsby-image-wrapper="" class="gatsby-image-wrapper gatsby-image-wrapper-constrained true">
                        <div style={{ maxWidth: '1343px', display: 'block' }}>
                            <img alt="" role="presentation" aria-hidden="true" src="data:image/svg+xml;charset=utf-8,%3Csvg height='680' width='1343' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E" style={{ maxWidth: '100%', display: 'block', position: 'static' }} />
                        </div>
                        <div aria-hidden="true" data-placeholder-image="" style={{ opacity: '0', transition: 'opacity 500ms linear 0s', backgroundColor: 'rgb(40, 40, 40)', position: 'absolute', inset: '0px', objectFit: 'cover' }}>
                        </div>
                        <img placeholder="blurred" decoding="async" width="1343" height="680" data-main-image="" style={{ objectFit: 'cover', opacity: '1' }} sizes="(min-width: 1343px) 1343px, 100vw" loading="lazy" src={game} alt="Game Development" />
                        <noscript>
                            <img placeholder="blurred" decoding="async" width="1343" height="680" data-main-image="" style={{ objectFit: 'cover', opacity: '0' }} sizes="(min-width: 1343px) 1343px, 100vw" loading="lazy" src="/static/5db10feff66854fdac81f27d44b7b4c1/8096d/Game_Development_2ffcd49345.webp" srcSet="/static/5db10feff66854fdac81f27d44b7b4c1/84a88/Game_Development_2ffcd49345.webp 336w,
/static/5db10feff66854fdac81f27d44b7b4c1/f9619/Game_Development_2ffcd49345.webp 672w,
/static/5db10feff66854fdac81f27d44b7b4c1/8096d/Game_Development_2ffcd49345.webp 1343w" alt="Game Development" />
                        </noscript>
                    </div>
                </div>
                <div class="ImageCard-module--content--7bd66 d-none d-md-none d-lg-block">
                    <h2 class="pl-3 pb-3 main-heading-36">Game Development</h2>
                    <div class="mb-3 pl-3">
                        <p>In today’s digitalized era, games are selling like hotcakes. And we are eager to assist you in expanding your business into this great market. Explore our game development services — from designing amazing characters and incorporating thrilling soundtracks to developing and delivering glitch-free games!</p>
                    </div>
                    <div class="pl-2">
                        <Link to={`/hire-unreal-engine-developers`}>
                            <button type="button" class="btn_white_border1 btn btn-primary">Learn More</button>
                        </Link>
                    </div>
                </div>
            </div> */}
        </>

    )
}
