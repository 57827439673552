import React from 'react'
import Navbar from '../components/Navbar'
import OurPricing from '../components/OurPricing/OurPricing'
import Footer from '../components/Footer/Footer'
import Insights from '../components/Insights/Insights'

export default function Pricing() {
  return (
    <>
    <Navbar/>
    <OurPricing/>
    {/* <Insights/> */}
    <Footer/>
    </>
  )
}
